import React from 'react';

import { Container, ContainerItem } from '../offline-consultations/table-subcomponent/StyledComponents';

import HistoryContainer from './HistoryContainer';
import NewPolicyDetails from '../policy-details/NewPolicyDetails';
import Prescription from '../prescription-details/Prescription';


export default class SubComponent extends React.Component {
    render() {
        const { requestId, userId } = this.props;
        return (
            <Container>
                <ContainerItem style={{ width: "300px" }}>
                    <HistoryContainer requestId={requestId} />
                </ContainerItem>
                <ContainerItem style={{ width: "650px" }}>
                    <NewPolicyDetails userId={userId} />
                </ContainerItem>
                {/* <ContainerItem>
                    <Prescription requestId={requestId} />
                </ContainerItem> */}
            </Container>
        );
    }
}
