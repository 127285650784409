const config = {
  development: {
    // baseUrl: 'https://api.getvisitapp.net/stoker',
    baseUrl: 'http://localhost:5001',
    // baseUrl: 'https://api.samuraijack.xyz/v3',
    pharmacyService: 'http://localhost:2020',
    //baseUrl: 'https://api.samuraijack.xyz/latias',
    networkService: 'http://localhost:9001',  
    //pharmacyService: 'https://retail-stage.getvisitapp.net/ultron',
    //pharmaServer: 'https://api.samuraijack.xyz/pharmacy',
    pubnub: {
      publishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
      subscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
    },
    googleAuthConfig: {
      clientId:
        '1063005261362-ahma70ju5no6aksoc95p9kivj7s3uopp.apps.googleusercontent.com',
      scopes: ['email', 'profile', 'openid'],
    },
    opsDashboardURL : 'http://localhost:9000/',
    maoriDashboardURL : 'https://maori.getvisitapp.net/',
    catalystDashboardURL : 'https://catalyst.getvisitapp.com/welcome',
    doctorDashboardUrl: 'https://treat.samuraijack.xyz/home/',
  },
  staging: {
    baseUrl: 'https://api.getvisitapp.net/vegeta',
    //pharmacyService: 'http://absol.getvisitapp.xyz',
    pharmacyService: 'http://localhost:2020',
    networkService: 'https://network.getvisitapp.net',
    pubnub: {
      publishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
      subscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
    },
    googleAuthConfig: {
      clientId:
        '1063005261362-ahma70ju5no6aksoc95p9kivj7s3uopp.apps.googleusercontent.com',
      scopes: ['email', 'profile', 'openid'],
    },
    opsDashboardURL : 'https://operations.getvisitapp.net/',
    maoriDashboardURL : 'https://maori.getvisitapp.net/',
    catalystDashboardURL : 'https://catalyst.getvisitapp.com/welcome',
    doctorDashboardUrl: 'https://treat.samuraijack.xyz/home/',
  },
  uat: {
    baseUrl: 'https://uat-api.getvisitapp.net/backend',
    pharmacyService: 'https://uat-api.getvisitapp.net/pharmacy',
    networkService: 'https://uat-network.getvisitapp.net',
    pubnub: {
      publishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
      subscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
    },
    googleAuthConfig: {
      clientId:
        '1063005261362-ahma70ju5no6aksoc95p9kivj7s3uopp.apps.googleusercontent.com',
      scopes: ['email', 'profile', 'openid'],
    },
    opsDashboardURL : 'https://uat-operations.getvisitapp.net/',
    maoriDashboardURL : 'https://uat-maori.getvisitapp.net/',
    catalystDashboardURL : 'https://uat-catalyst.getvisitapp.net',
    doctorDashboardUrl: 'https://uat-doctors.getvisitapp.net/home/',
  },
  production: {
    baseUrl: 'https://api.getvisitapp.com/v4',
    pharmacyService: 'https://api.getvisitapp.com/pharmacy',
    networkService: 'https://network.getvisitapp.com',
    pubnub: {
      publishKey: 'pub-c-fb8ac7e3-75bf-4396-bf14-77215f8b9419',
      subscribeKey: 'sub-c-78deef00-680f-11e5-a57f-0619f8945a4f',
    },
    googleAuthConfig: {
      clientId:
        '587654000125-r26coedb1f9siqfgn1arj63k3dslrqqk.apps.googleusercontent.com',
      scopes: ['email', 'profile', 'openid'],
    },
    opsDashboardURL : 'https://operations.getvisitapp.com/',
    maoriDashboardURL : 'https://maori.getvisitapp.com/',
    catalystDashboardURL : 'https://catalyst.getvisitapp.com/welcome',
    doctorDashboardUrl: 'https://doctors.getvisitapp.com/home/',
  },
};

export default config[process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV];
