import React, { Component } from 'react';
import { connect } from 'react-redux';
import Frame from '../../images/Frame.svg';
import { addVisionComment } from '../../services/api/cashless-vision';

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',

      comment: {
        name: '',
        message: '',
      },
    };

    // bind context to methods
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Handle form input field changes & update the state
   */
  handleFieldChange = (event) => {
    const { value } = event.target;

    this.setState({
      comment: {
        name: this.props.user.name,
        message: value,
      },
    });
  };

  /**
   * Form submit handler
   */
  onSubmit(e) {
    // prevent default form submission
    e.preventDefault();

    if (!this.isFormValid()) {
      this.setState({ error: 'No Updates?' });
      return;
    }

    // loading status and clear error
    this.setState({ error: '', loading: true });

    // persist the comments on server
    let { comment } = this.state;
    addVisionComment(
      this.props.requestId,
      comment.message,
    )
      .then((res) => {
        // add time return from api and push comment to parent state
        comment.time = 'Just Now';
        this.props.addComment(comment);

        // clear the message box
        this.setState({
          loading: false,
          comment: {
            name: '',
            message: '',
          },
        });
      })
      .catch((err) => {
        this.setState({
          error: 'Something went wrong.',
          loading: false,
        });
      });
  }

  /**
   * Simple validation
   */
  isFormValid() {
    return this.state.comment.name !== '' && this.state.comment.message !== '';
  }

  renderError() {
    return this.state.error ? (
      <div className="alert alert-danger">{this.state.error}</div>
    ) : null;
  }

  render() {
    return (
      <React.Fragment>
        <form method="post" onSubmit={this.onSubmit}>
          <div
            style={{
              // flexGrow: 2,
              display: 'flex',
              justifyContent: 'space-between',
              border: '1px solid #CFC8FF',
              borderRadius: '8px',
              backgroundColor: '#FFFFFF',
              // flexBasis: '30%',
            }}
            className="mt-1"
          >
            <textarea
              style={{
                width: '90%',
                border: 'none',
                // height: '36px',
                fontFamily: 'Inter, sans-serif',
                height: '43px',
                boxShadow: 'none',
              }}
              onChange={this.handleFieldChange}
              value={this.state.comment.message}
              className="form-control"
              placeholder="Write a Comment..."
              name="message"
              rows="3"
            />
            <button
              style={{
                boxShadow: 'none',
                backgroundColor: 'unset',
                border: 'none',
              }}
              disabled={this.state.loading}
            >
              <img src={Frame} alt="Frame" />
            </button>
          </div>

          {this.renderError()}

          <div className="form-group"></div>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(CommentForm);
