import user from './user';
import app from './app';
import offlineConsult from './offlineConsult';
import paymentDetails from './paymentDetails';
import docCall from './docCall';
import customerSupport from './customerSupport';
import labsDashboard from './lab-order-details';
import createLabRxDetails from './lab-rx-create';
import labPartner from './lab-partner';
import filterHeader from './filterHeader';
import intimation from './intimation';
import cashlessVaccination from './cashlessVaccination';
import cashlessDental from './CashlessDental';
import cashlessVision from './cashlessVision';

export default {
  user,
  app,
  offlineConsult,
  paymentDetails,
  docCall,
  customerSupport,
  labsDashboard,
  createLabRxDetails,
  labPartner,
  filterHeader,
  intimation,
  cashlessVaccination,
  cashlessDental,
  cashlessVision
};
