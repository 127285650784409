import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Select, message, DatePicker } from 'antd';
import {
  Tooltip,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ButtonGroup,
  FormGroup,
  Input as InputBox,
  Col,
  Row,
  ModalFooter,
} from 'reactstrap';
import LoadingComponent from '../common/LoadingComponent';
import PenButton from '../../images/PenButton.svg';
import TickButton from '../../images/TickButton.svg';
import CrossButton from '../../images/CrossButton.svg';
import DocsButton from '../../images/DocsButton.svg';
import TimerButton from '../../images/TimerButton.svg';
import MarkAsStuck from '../../images/markAsStuck.svg';
import SomeButton from '../../images/SomeButton.svg';
import DropDown from '../../images/DropDown.svg';
import ClaimRequest from '../../images/ClaimRequest.svg';
import AddAssignee from '../../images/AddAssignee.svg';
import ApiBooking from '../../images/ApiBooking.svg';
import AlternateSuggestions from '../../images/AlternateSuggestions.svg';
import ShiftToReimbursement from '../../images/ShiftToReimbursement.svg';
import ShiftToPending from '../../images/ShiftToPending.svg';
import RequestReconfirmation from '../../images/RequestReconfirmation.svg';
import GrowthArrow from '../../images/GrowthArrow.svg';
import MarkFollowUp from '../../images/MarkFollowUp.svg';
import Reimbursement from '../../images/Reimbursement.svg';
import Pending from '../../images/Pending.svg';
import Communications from '../../images/Communications.svg';
import PrebookUserConfirm from '../../images/PrebookUserConfirm.svg';
import PrebookCenterConfirm from '../../images/PrebookCenterConfirm.svg';
import OfflineModalConfirm from './OfflineModalConfirm';
import FollowUpModal from './FollowUpModal';
import EditDoctorSlots from './EditDoctorSlots';
import {
  getAllOfflineRequestsCount,
  sendPriceChangeRequestAPI,
  confirmRequest,
  cancelRequest,
  shiftOfflineConsultationToReimbursement,
  requestReconfirmationForOfflineConsult,
  placeRequest,
  fetchCancelledReasons,
  getAllOfflineRequests,
  markFollowUp,
  shiftOfflineConsultationStatusToPending,
  markRequestAsStuck,
  updatePrebookStatus
} from '../../services/api/offline-consult';
import {
  showRequestModal,
  hideRequestModal,
  hideDoctorSlotsEditModal,
  showDoctorSlotsEditModal,
  //   showInternalComments,
  hideInternalComments,
  updateRequestID,
} from '../../actions/index';
import OfflineModalCancel from './OfflineModalCancel';
import RescheduleAppointMentRequest from './RescheduleAppointMentRequest';
import OfflineApiBookingModal from '../offline-consultations/OfflineApiBooking';
import ClaimRequestModal from './ClaimRequestModal';
import AddDoctorDetailModal from './AddDoctorDetailModal';
import PrebookUserConfirmedModal from './PrebookUserConfirmedModal';
import PrebookCenterConfirmedModal from './PrebookCenterConfirmedModal';
import SuggestionsDialog from './SuggestionsDialog';
import MarkEscallationModal from './MarkEscallationModal';
import CommunicationsSetup from './CommunicationSetup';
import StuckReuestModal from './StuckRequestModal';

const Actions = ({
  requestData,
  requestId,
  priceApprovalStatus,
  onAction,
  editDoctorSlots,
  getOfflineRequestData,
  pre,
  requestData1,
  requestDataForConfirmFor,
  featureA,
  featureB,
  statusFilter,
  tagsFilter,
}) => {
  console.log({ requestData1 }, 'Actions-----');
  console.log({ requestId }, 'ActionsHJS');
  const [loading, setLoading] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [requestDataForConfirm, setRequestDataForConfirm] = useState(null);
  const [centerRequestStatus, setCenterRequestStatus] = useState(null);
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
  const [showShiftingModal, setShowShiftingModal] = useState(false);
  const [showApiBookingModal, setShowApiBookingModal] = useState(false);
  const [shiftingDetails, setShiftingDetails] = useState(null);
  const [priceChangeRequestsModal, setPriceChangeRequestsModal] =
    useState(false);
  const [requestIdDataForPriceChange, setRequestIdDataForPriceChange] =
    useState(null);
  const [newPrice, setNewPrice] = useState('');
  const [priceChangeReason, setPriceChangeReason] = useState('');
  const [inputReason, setInputReason] = useState('');
  const [showClaimRequestModal, setShowClaimRequestModal] = useState(false);
  const [showClaimRequestModalRequestId, setShowClaimRequestModalRequestId] =
    useState(null);
  const [openActionDropDown, setOpenActionDropDown] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [addDoctorDetailModalOpen, setAddDoctorDetailModalOpen] =
    useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionsDetails, setSuggestionsDetails] = useState(null);
  const [shiftReimbursementModalOpen, setShiftReimbursementModalOpen] =
    useState(false);
  const [shiftToReimbursementReqId, setShiftToReimbursementReqId] =
    useState(null);
  const [requestReconfirmationModalOpen, setRequestReconfirmationModalOpen] =
    useState(false);
  const [requestReconfirmationRequestId, setRequestReconfirmationRequestId] =
    useState(null);
  const [prebookRequestDetails, setPrebookRequestDetails] =
    useState(null);
  const [showEscallationModal, setShowEscallationModal] = useState(false);
  const [escallationRequestId, setEscallationRequestId] = useState(null);
  const [followUpDetails, setFollowUpDetails] = useState(null);
  const [showFollowUpModal, setShowFollowUpModal] = useState(false);
  const [cancelledReasons, setCancelledReasons] = useState([]);
  const [shiftToPendingModalOpen, setShiftToPendingModalOpen] = useState(false);
  const [markAsStuckModalOpen, setMarkAsStuckModalOpen] = useState(false);
  const [markAsStuckRequestId, setMarkAsStuckRequestId] = useState(null);
  const [shiftToPendingRequestId, setShiftToPendingRequestId] = useState(null);
  const dispatch = useDispatch();
  const [openComms, setOpenComms] = useState(false);
  const [commsData, setCommsData] = useState(null);
  const [sendCommunicationTooltipOpen, setSendCommunicationTooltipOpen] = useState(-1);
  const [prebookUserConfirmModalOpen, setPrebookUserConfirmModalOpen] = useState(false);
  const [prebookCenterConfirmModalOpen, setPrebookCenterConfirmModalOpen] = useState(false);

  const { Option } = Select;

  const {
    showRequestDetails,
    showInternalComments,
    // requestId,
    showEditDoctorSlots,
    doctorId,
    requestData: followUpData,
  } = useSelector((state) => state.offlineConsult);

  console.log(followUpData, 'followUpData');
  const toggleDropdown = (dropdown) => {
    setOpenActionDropDown(dropdown);
    setIsOpen(!isOpen);
  };

  const confirmOpenModal = (requestId, priceApprovalStatus, followUpData) => {
    if (priceApprovalStatus === 'Pending') {
      toast.error(
        'There is an active Price change request for this doctor. Please connect with network team. Until then you cannot confirm this appointment.'
      );
      return;
    }

    setConfirmModalIsOpen(true);

    //setRequestId(requestId);
    dispatch(updateRequestID(requestId));

    setRequestDataForConfirm(followUpData);
  };

  console.log(confirmModalIsOpen, 'ConfirmModalIsOpen');

  const confirmCloseModal = () => {
    setConfirmModalIsOpen(false);
  };

  const cancelOpenModal = (requestId, priceApprovalStatus) => {
    if (priceApprovalStatus === 'Pending') {
      toast.error(
        'There is an active Price change request for this doctor. Please connect with network team. Until then you cannot cancel this appointment.'
      );
      return;
    }fetchCancelledReasons(requestId)
    .then((response) => {
      setCancelledReasons(response.cancelledReasons);
    })
    .catch(() => {
      setCancelledReasons([]);
    });
    setCancelModalIsOpen(true);
    // setRequestId(requestId);
    dispatch(updateRequestID(requestId));
  };

  const cancelCloseModal = () => {
    setCancelModalIsOpen(false);
  };

  const openShiftingModal = (details) => {
    // console.log({ details, requestData }, 'openShiftingModal');
    if (details === undefined || details === null) {
      details = followUpData;
    }
    console.log({ details }, 'openShiftingModal');
    setShowShiftingModal(true);
    setShiftingDetails(details);
  };

  const togglePriceChangeRequest = (requestIdData) => {
    console.log('Price Change Request', requestIdData);
    setPriceChangeRequestsModal(!priceChangeRequestsModal);
    setRequestIdDataForPriceChange(requestIdData);
    setNewPrice('');
    setPriceChangeReason('');
    setInputReason('');
  };

  const toggleShiftToReimbursementModal = (requestId) => {
    console.log('Shift to Reimbursement', requestId);
    setShiftReimbursementModalOpen(!shiftReimbursementModalOpen);
    setShiftToReimbursementReqId(
      typeof requestId === 'number' ? requestId : null
    );
  };

  const togglePrebookUserConfirmModal = (details) => {
    setPrebookUserConfirmModalOpen(!prebookUserConfirmModalOpen);
    setPrebookRequestDetails(details);
  };

  const togglePrebookCenterConfirmModal = (details) => {
    setPrebookCenterConfirmModalOpen(!prebookCenterConfirmModalOpen);
    setPrebookRequestDetails(details);
  };

  const toggleRequestReconfirmationModal = (requestId) => {
    console.log('toggleShiftToPendingModal', requestId);
    setRequestReconfirmationModalOpen(!requestReconfirmationModalOpen);
    setRequestReconfirmationRequestId(requestId);
  };

  const openClaimRequestButton = (requestId) => {
    setShowClaimRequestModal(true);
    setShowClaimRequestModalRequestId(requestId);
  };

  const onClaimRequestModalClose = () => {
    setShowClaimRequestModal(false);
    getAllOfflineRequests();
  };

  const onStuckRequestModalClose = () => {
    setMarkAsStuckModalOpen(false);
    getOfflineRequestData();
  };

  const openAddDoctorDetailModal = (details) => {
    console.log({ details }, 'open add Doctor Details modal');
    setAddDoctorDetailModalOpen(true);
  };

  const openApiBookingModal = (details) => {
    setShowApiBookingModal(true);
    setShiftingDetails(details);
  };

  const closeAddDoctorDetailModal = () => {
    setAddDoctorDetailModalOpen(false);
  };

  const openSuggestions = (details) => {
    console.log({ details }, 'openSuggestions');
    setShowSuggestions(true);
    setSuggestionsDetails(details);
  };

  const closeSuggestions = () => {
    setShowSuggestions(false);
    setSuggestionsDetails(null);
  };

  const shiftAppointmentToReimbursement = (requestId) => {
    setLoading(true);
    console.log(`Shifting to reimbursement ${requestId}`);
    shiftOfflineConsultationToReimbursement(requestId)
      .then((res) => {
        // Fetch consults
        if (res.message === 'success') {
          toast.success('Success');
          getAllOfflineRequests();
        } else {
          toast.error(res.errorMessage || 'Failed to Shift to reimbursement');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.errorMessage || 'Failed to Shift to reimbursement');
      })
      .finally(() => {
        toggleShiftToReimbursementModal();
        setLoading(false);
      });
  };

  const shiftAppointmentToPending = (requestId) => {
    setLoading(true);
    console.log(`Change Status to pending ${requestId}`);
    shiftOfflineConsultationStatusToPending(requestId)
      .then((res) => {
        // Fetch consults
        if (res.message === 'success') {
          toast.success('Success');
          getAllOfflineRequests(); // make sure fetchTableData is defined in this scope
        } else {
          toast.error(res.errorMessage || 'Failed to Shift to Pending');
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.errorMessage) {
          toast.error(err.response.data.errorMessage);
        } else {
          toast.error(err.errorMessage || 'Failed to Shift to Pending');
        }
      })
      .finally(() => {
        toggleShiftToPendingModal(shiftToPendingRequestId); // make sure toggleShiftToPendingModal is defined in this scope
        setLoading(false);
      });
  };

  const updatePrebookStatusRequest = (requestId, status) => {
    setLoading(true);
    updatePrebookStatus(requestId, { status })
      .then((res) => {
        // Fetch consults
        if (res.message === 'success') {
          toast.success('Success');
          getAllOfflineRequests(); // make sure fetchTableData is defined in this scope
        } else {
          toast.error(res.errorMessage || 'Failed to update prebook status');
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.errorMessage) {
          toast.error(err.response.data.errorMessage);
        } else {
          toast.error(err.errorMessage || 'Failed to update prebook status');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const markRequestAsStuckRequest = (requestId) => {
    setLoading(true);
    console.log(`mark request as stuck ${requestId}`);
    markRequestAsStuck(requestId)
      .then((res) => {
        // Fetch consults
        if (res.message === 'success') {
          toast.success('Success');
          getAllOfflineRequests(); // make sure fetchTableData is defined in this scope
        } else {
          toast.error(res.errorMessage || 'Failed to Mark as Stuck Request');
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.errorMessage) {
          toast.error(err.response.data.errorMessage);
        } else {
          toast.error(err.errorMessage || 'Failed to Mark as Stuck Request');
        }
      })
      .finally(() => {
        toggleMarkAsStuckModal(markAsStuckRequestId); // make sure toggleMarkAsStuckModal is defined in this scope
        setLoading(false);
      });
  };

  const requestReconfirmationForConsultation = (requestId) => {
    setLoading(true);
    console.log('requestReconfirmationForConsultation', requestId);
    return requestReconfirmationForOfflineConsult(requestId)
      .then((resp) => {
        if (resp.message === 'success') {
          toast.success('Successfully Requested For Reconfirmation');
          // this.onFetchData();
        } else {
          toast.error(resp.errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong');
      })
      .finally(() => {
        setLoading(false);
        toggleRequestReconfirmationModal();
      });
  };

  const toggleShiftToPendingModal = (requestData) => {
    console.log('Shift tp pending---', requestData);
    setShiftToPendingModalOpen(prevState => !prevState);
    setShiftToPendingRequestId(requestId);
  };

  const toggleMarkAsStuckModal = (requestData) => {
    console.log('mark as stuck---', requestData);
    setMarkAsStuckModalOpen(prevState => !prevState);
    setMarkAsStuckRequestId(requestId);
  };

  const openEscallationModal = (details) => {
    console.log({ details }, 'open escallation modal');
    setShowEscallationModal(true);
    setEscallationRequestId(details.requestId);
  };

  const closeEscallationModal = () => {
    setShowEscallationModal(false);
    setFollowUpDetails(null);
  };

  const closePrebookUserConfirmModal = () => {
    setPrebookRequestDetails(null);
    setPrebookUserConfirmModalOpen(false);
  };

  const closePrebookCenterConfirmModal = () => {
    setPrebookRequestDetails(null);
    setPrebookCenterConfirmModalOpen(false);
  };

  const openFollowUpModal = (details) => {
    console.log({ details }, 'open follow up modal');
    if (details === undefined || details === null) {
      details = followUpData;
    }
    setShowFollowUpModal(true);
    setFollowUpDetails(details);
  };

  const closeFollowUpModal = () => {
    setShowFollowUpModal(false);
    setFollowUpDetails(null);
  };

  // const toggleTooltip = (requestId) => {
  //   setSendCommunicationTooltipOpen(
  //     requestId === sendCommunicationTooltipOpen ? -1 : requestId
  //   );
  // };

  const openCommunications = (followUpData) => {
    console.log(followUpData, 'openCommunications---');
    setOpenComms(true);
    setCommsData(followUpData);
  };

  const closeCommunications = () => {
    setOpenComms(false);
  };

  const sendPriceChangeRequest = () => {
    setLoading(true);
    console.log(requestIdDataForPriceChange, 'requestIdDataForPriceChange');
    const params = {
      requestId: requestIdDataForPriceChange.requestId,
      centerId: requestIdDataForPriceChange.networkCenterId,
      doctorId: requestIdDataForPriceChange.doctorId,
      newPrice: newPrice,
      doctorName: requestIdDataForPriceChange.doctorName,
      centerName: requestIdDataForPriceChange.networkCenterName,
      fullfillmentType:
        requestIdDataForPriceChange.fullfillmentType || 'center',
      onBoardingStatus: requestIdDataForPriceChange.onBoardingStatus,
      priceChangeReason:
        priceChangeReason === 'Other' ? inputReason : priceChangeReason,
    };
    console.log('Price Change Request', params);
    return sendPriceChangeRequestAPI(params)
      .then((resp) => {
        if (resp.message === 'success') {
          toast.success('Successfully Requested For Price Change');
          // onFetchData();
        }
        toast.error(resp.errorMessage);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong');
      })
      .finally(() => {
        setLoading(false);
        togglePriceChangeRequest();
      });
  };

   const submitConfirmModal = (
    dateKey,
    slot,
    slotType = 'fixed',
    startAppointmentTime,
    endAppointmentTime
  ) => {
    const timeRange =
      startAppointmentTime && endAppointmentTime && slotType == 'walk-in'
        ? `${startAppointmentTime}-${endAppointmentTime}`
        : null;
    if (!requestId || !slot || !dateKey) {
      console.error({ requestId, dateKey, slot }, 'Params missing');
      return;
    }
    if (
      slotType == 'walk-in' &&
      !(startAppointmentTime || endAppointmentTime)
    ) {
      console.error(
        { startAppointmentTime, endAppointmentTime },
        'Params missing'
      );
      return;
    }
    return confirmRequest(
      requestId,
      dateKey,
      slot,
      centerRequestStatus,
      slotType,
      timeRange
    )
      .then((response) => {
        // Handle success response

        if (
          response &&
          response.data &&
          response.data.message === 'serverError'
        ) {
          toast.error(response.data.errorMessage);
        } else {
          toast.success('Confirm Request Success');
        }
      })
      .catch((err) => {
        // Error Handle
        if (err.response.data.errorMessage) {
          toast.error(err.response.data.errorMessage);
        } else {
          toast.error('Confirm Request Failed. Please try again.');
        }
      })
      .finally(() => {
        // Handle Final (mainly for spinners)
      });
  };

  // rest of your component

  const submitCancelModal = (
    reason,
    remarks,
    cancellationFee,
    subCancelReason
  ) => {
    console.log({requestData, requestId },"sssss");
    if (!requestId || !reason) {
      console.error({ requestId, reason }, 'No request id present');
      toast.error('Select a reason to continue');
      return;
    }
    if (requestData.cancelledAt) {
      toast.error(`Cancelled Appointment can't be allow to cancel`);
      return Promise.resolve(false);
    }
    return cancelRequest(
      requestId,
      reason,
      remarks,
      cancellationFee,
      subCancelReason,
      false,
      requestData.prebookedAppointment ? 'cancelled' : null
    )
      .then((response) => {
        // Handle success response
        toast.success('Cancel Request Success');
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errorMessage) {
          toast.error(err.response.data.errorMessage);
        } else {
          toast.error('Cancel Request fail Please try again');
        }
      })
      .finally(() => {
        // Handle Final (mainly for spinners)
      });
  };
  return (
    <>
      {featureA && ((statusFilter === 'prebooked_appointment' || tagsFilter === 'prebookedAppointments' || (requestData && requestData.prebookedAppointment)
 ) ? (
        <div className="d-flex flex-col">
          <div className="flex flex-row justify-center">
          { requestData && !requestData.cashlessLetterLink && ((requestData.platform == 'IOS' && requestData.version > 740) || (requestData.platform == 'ANDROID' && requestData.version > 1800009640) || (requestData.platform == 'WEB') || !requestData.version )  && !requestData.cancelledAt && (<> 
          <UncontrolledTooltip target={`userConfirmedButton-${requestId}`}>
              User Confirmed
            </UncontrolledTooltip>
            <img
              id={`userConfirmedButton-${requestId}`}
              onClick={() => togglePrebookUserConfirmModal(requestData)}
              src={PrebookUserConfirm}
              alt="User Confirmed Button"
              className="button-img"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            />
            </>)}
            { requestData && !requestData.cashlessLetterLink && ((requestData.platform == 'IOS' && requestData.version > 740) || (requestData.platform == 'ANDROID' && requestData.version > 1800009640) || (requestData.platform == 'WEB') || !requestData.version ) && requestData.center_type == 'prepaid' && !requestData.cancelledAt && (<> 
            <UncontrolledTooltip target={`userCenterConfirmedButton-${requestId}`}>
              User and Center Confirmed
            </UncontrolledTooltip>
            <img
              id={`userCenterConfirmedButton-${requestId}`}
              onClick={() => togglePrebookCenterConfirmModal(requestData)}
              src={PrebookCenterConfirm}
              alt="User and Center Confirmed Button"
              className="button-img"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            />
             </>)}
             { requestData && (requestData.center_type != 'prepaid'|| (requestData.center_type == 'prepaid' && !requestData.cashlessLetterLink)) && (<> 
            <UncontrolledTooltip target={`cancelButton-${requestId}`}>
              Cancel Appointment
            </UncontrolledTooltip>
            <img
              id={`cancelButton-${requestId}`}
              onClick={() => cancelOpenModal(requestId, priceApprovalStatus)}
              src={CrossButton}
              alt="Cross Button"
              className="button-img"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            />
            </>)}
            </div>
        </div>
      ) : (
        
            <div className="d-flex flex-col">
          <div className="flex flex-row justify-center">
            <UncontrolledTooltip target={`confirmButton-${requestId}`}>
              Confirm Appointment
            </UncontrolledTooltip>
            <img
              src={TickButton}
              alt="Tick Button"
              className="button-img"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
              id={`confirmButton-${requestId}`}
              onClick={() =>
                confirmOpenModal(requestId, priceApprovalStatus, requestData)
              }
            /> 
            <UncontrolledTooltip target={`cancelButton-${requestId}`}>
              Cancel Appointment
            </UncontrolledTooltip>
            <img
              id={`cancelButton-${requestId}`}
              onClick={() => cancelOpenModal(requestId, priceApprovalStatus)}
              src={CrossButton}
              alt="Cross Button"
              className="button-img"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            />         
            <UncontrolledTooltip target={`transferButton-${requestId}`}>
              Reschedule Request
            </UncontrolledTooltip>
            <img
              src={TimerButton}
              alt="Reschedule Button"
              className="button-img"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
              id={`transferButton-${requestId}`}
              onClick={() => openShiftingModal(requestData)}
            />
            <UncontrolledTooltip target={`requestPriceChanges-${requestId}`}>
              Request Price Changes
            </UncontrolledTooltip>
            <img
              src={PenButton}
              alt="Pen Button"
              className="button-img"
              id={`requestPriceChanges-${requestId}`}
              onClick={() => {
                togglePriceChangeRequest(requestData)
              }}
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            />
            <UncontrolledTooltip target={`claimButton-${requestId}`}>
              Claim Request
            </UncontrolledTooltip>
            <img
              id={`claimButton-${requestId}`}
              onClick={() => openClaimRequestButton(requestId)}
              src={ClaimRequest}
              alt="ClaimRequestButton"
              className="button-img"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // overflow: 'auto',
                position: 'relative',
              }}
            >
              <img
                src={DropDown}
                alt="DropDownButton"
                onClick={() => toggleDropdown(requestId)}
                className="dropdown-toggle"
                style={{
                  width: '24px',
                  height: '24px',
                  cursor: 'pointer',
                  margin: '4px',
                }}
              />
            </div>
          </div>
          <div>
            {isOpen && requestId === openActionDropDown && (
              <div
                className="mt-2"
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  width: '220px',
                  border: '1px solid #e9ecef',
                  padding: '10px',
                  borderRadius: '10px',
                  boxShadow: '0px 0px 10px 0px #e9ecef',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                    borderBottom: '1px solid #F2F1F6',
                  }}
                >
                  <img
                    src={AddAssignee}
                    onClick={() => openAddDoctorDetailModal()}
                    alt="AddAssignee"
                    style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                  />
                  <p>Add Doctor</p>
                </div>

                { !requestData.prebookedAppointment && requestData.externalApiLogsId && !requestData.externalBookingId && !requestData.cancelledAt && (<div
                  style={{
                    display: 'flex',
                    gap: '5px',
                    borderBottom: '1px solid #F2F1F6',
                  }}
                >
                  <img
                    src={ApiBooking}
                    onClick={() => openApiBookingModal(requestData)}
                    alt="Book API Appointment"
                    style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                  />
                  <p>Book API Appointment</p>
                </div> )}

                <div
                  className="mt-2"
                  style={{
                    display: 'flex',
                    width: 'max-content',
                    gap: '5px',
                    borderBottom: '1px solid #F2F1F6',
                  }}
                >
                  <img
                    src={AlternateSuggestions}
                    id={`suggestionsButton-${requestId}`}
                    onClick={() => openSuggestions(requestData)}
                    alt="AlternateSuggestions"
                    style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                  />
                  <p>Alternate suggestions</p>
                </div>
                <div
                    className="mt-2"
                    style={{
                      display: 'flex',
                      width: 'max-content',
                      gap: '5px',
                      borderBottom: '1px solid #F2F1F6',
                    }}
                  >
                    <img
                      src={MarkAsStuck}
                      alt="Mark as Stuck"
                      className="button-img"
                      id={`markAsStuck-${requestId}`}
                      onClick={() => toggleMarkAsStuckModal(requestData)}
                      style={{ width: '24px', height: '24px', cursor: 'pointer', border:'2px solid black' }}
                    />
                  <p>Mark as stuck</p>
                </div>
                  <div
                    className="mt-2"
                    style={{
                      display: 'flex',
                      width: 'max-content',
                      gap: '5px',
                      borderBottom: '1px solid #F2F1F6',
                    }}
                  >
                    <img
                      src={Reimbursement}
                      alt="ShiftToReimbursement"
                      id={`shiftToReimbursementButton-${requestId}`}
                      onClick={() => toggleShiftToReimbursementModal(requestId)}
                      style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                    />
                    <p>Shift to reimbursement</p>
                  </div>                  
                {/* <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  <img
                    src={ShiftToPending}
                    alt="ShiftToPending"
                    style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                  />
                  <p>Request reimbursement</p>
                </div> */}
                <div
                  className="mt-2"
                  style={{
                    display: 'flex',
                    width: 'max-content',
                    gap: '5px',
                    borderBottom: '1px solid #F2F1F6',
                  }}
                >
                  <img
                    src={RequestReconfirmation}
                    alt="RequestReconfirmation"
                    id={`requestReconfirmationButton-${requestId}`}
                    onClick={() => toggleRequestReconfirmationModal(requestId)}
                    style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                  />
                  <p>Request reconfirmation</p>
                </div>
                <div
                    className="mt-2"
                    style={{
                      display: 'flex',
                      width: 'max-content',
                      gap: '5px',
                      borderBottom: '1px solid #F2F1F6',
                    }}
                  >
                    <img
                      src={Pending}
                      alt="Pen Button"
                      className="button-img"
                      id={`shiftToPending-${requestId}`}
                      onClick={() => toggleShiftToPendingModal(requestData)}
                      style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                    />
                  <p>Shift to Pending</p>
                </div>
                <div
                    className="mt-2"
                    style={{
                      display: 'flex',
                      width: 'max-content',
                      gap: '5px',
                      borderBottom: '1px solid #F2F1F6',
                    }}
                  >
                    <img
                      src={Communications}
                      alt="Pen Button"
                      className="button-img"
                      id={`sendCommunication-${requestId}`}
                      onClick={() => openCommunications(requestData)}
                      style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                    />
                  <p>Send Communications</p>
                </div>
                <div
                  className="mt-2"
                  style={{
                    display: 'flex',
                    width: 'max-content',
                    gap: '5px',
                    borderBottom: '1px solid #F2F1F6',
                  }}
                >
                  <img
                    src={GrowthArrow}
                    alt="GrowthArrow"
                    className="button-img"
                    id={`growthArrowButton-${requestId}`}
                    onClick={() => openEscallationModal(requestData)}
                    style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                  />
                  <p>Mark Escalation</p>
                </div>
                <div
                  className="mt-2"
                  style={{
                    display: 'flex',
                    width: 'max-content',
                    gap: '5px',
                    borderBottom: '1px solid #F2F1F6',
                  }}
                >
                  <img
                    src={MarkFollowUp}
                    alt="GrowthArrow"
                    className="button-img"
                    id={`markFollowUpButton-${requestId}`}
                    onClick={() => openFollowUpModal(requestData)}
                    style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                  />
                  <p>Mark Follow Up</p>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      {featureB && ( (requestData1 && requestData1.prebookedAppointment) ? (
        <div style={{
              display: 'flex',
            }}>
          { requestData && !requestData.cashlessLetterLink && ((requestData.platform == 'IOS' && requestData.version > 740) || (requestData.platform == 'ANDROID' && requestData.version > 1800009640) || (requestData.platform == 'WEB') || !requestData.version ) && !requestData.cancelledAt && (<> 
          <UncontrolledTooltip target={`userConfirmedButton-${requestId}`}>
              User Confirmed
            </UncontrolledTooltip>
            <img
              id={`userConfirmedButton-${requestId}`}
              onClick={() => togglePrebookUserConfirmModal(requestData)}
              src={PrebookUserConfirm}
              alt="User Confirmed Button"
              className="button-img"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            />
            </>)}
            { requestData && !requestData.cashlessLetterLink && ((requestData.platform == 'IOS' && requestData.version > 740) || (requestData.platform == 'ANDROID' && requestData.version > 1800009640) || (requestData.platform == 'WEB') || !requestData.version ) && requestData.center_type == 'prepaid' && !requestData.cancelledAt && (<> 
            <UncontrolledTooltip target={`userCenterConfirmedButton-${requestId}`}>
              User and Center Confirmed
            </UncontrolledTooltip>
            <img
              id={`userCenterConfirmedButton-${requestId}`}
              onClick={() => togglePrebookCenterConfirmModal(requestData)}
              src={PrebookCenterConfirm}
              alt="User and Center Confirmed Button"
              className="button-img"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            />
             </>)}
            { requestData && (requestData.center_type != 'prepaid' || (requestData.center_type == 'prepaid' && !requestData.cashlessLetterLink)) && (<> 
            <UncontrolledTooltip target={`cancelButton-${requestId}`}>
              Cancel Appointment
            </UncontrolledTooltip>
            <img
              id={`cancelButton-${requestId}`}
              onClick={() => cancelOpenModal(requestId, priceApprovalStatus)}
              src={CrossButton}
              alt="Cross Button"
              className="button-img"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            />
            </>)}
            </div>
      ) : (
        
            <div
          className="flex"
          style={{
            paddingLeft: '12px',
            flexWrap: 'wrap',
          }}
        >
          <UncontrolledTooltip target={`confirmButton-${requestId}`}>
            Confirm Appointment
          </UncontrolledTooltip>
          <img
            src={TickButton}
            alt="Tick Button"
            className="button-img"
            style={{
              width: '24px',
              height: '24px',
              cursor: 'pointer',
            }}
            id={`confirmButton-${requestId}`}
            onClick={() =>
              confirmOpenModal(requestId, priceApprovalStatus, followUpData)
            }
          />
          <UncontrolledTooltip target={`cancelButton-${requestId}`}>
            Cancel Appointment
          </UncontrolledTooltip>
          <img
            id={`cancelButton-${requestId}`}
            onClick={() => cancelOpenModal(requestId, priceApprovalStatus)}
            src={CrossButton}
            alt="Cross Button"
            className="button-img"
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          />
          <UncontrolledTooltip target={`transferButton-${requestId}`}>
            Reschedule Request
          </UncontrolledTooltip>
          <img
            src={TimerButton}
            alt="Reschedule Button"
            className="button-img"
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            id={`transferButton-${requestId}`}
            onClick={() => openShiftingModal(requestData)}
          />
          <UncontrolledTooltip target={`requestPriceChanges-${requestId}`}>
            Request Price Changes
          </UncontrolledTooltip>
          <img
            src={PenButton}
            alt="Pen Button"
            className="button-img"
            id={`requestPriceChanges-${requestId}`}
            onClick={() => {
              togglePriceChangeRequest(requestData1)
            }}
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          />
          <UncontrolledTooltip target={`claimButton-${requestId}`}>
            Claim Request
          </UncontrolledTooltip>
          <img
            id={`claimButton-${requestId}`}
            onClick={() => openClaimRequestButton(requestId)}
            src={ClaimRequest}
            alt="ClaimRequestButton"
            className="button-img"
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          />
          <img
            src={AddAssignee}
            id={`addAssigneeButton-${requestId}`}
            onClick={() => openAddDoctorDetailModal()}
            className="button-img"
            alt="AddAssignee"
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          />
          <UncontrolledTooltip target={`addAssigneeButton-${requestId}`}>
            Add Doctor
          </UncontrolledTooltip>
          <img
            src={AlternateSuggestions}
            id={`suggestionsButton-${requestId}`}
            onClick={() => openSuggestions(requestData)}
            className="button-img"
            alt="AlternateSuggestions"
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          />
          <UncontrolledTooltip target={`suggestionsButton-${requestId}`}>
            Alternate Suggestions
          </UncontrolledTooltip>
          <img
            src={MarkAsStuck}
            id={`markAsStuck-${requestId}`}
            onClick={() => toggleMarkAsStuckModal(requestData)}
            className="button-img"
            alt="MarkAsStuck"
            style={{ width: '24px', height: '24px', cursor: 'pointer', border:'2px solid black' }}
          />
          <UncontrolledTooltip target={`markAsStuck-${requestId}`}>
            Mark as Stuck
          </UncontrolledTooltip>
            <img
              src={Pending}
              alt="Pen Button"
              className="button-img"
              id={`shiftToPending-${requestId}`}
              onClick={() => toggleShiftToPendingModal(requestData)}
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            />
            <UncontrolledTooltip target={`shiftToPending-${requestId}`}>
              Shift to Pending
            </UncontrolledTooltip>
          <img
            src={Reimbursement}
            alt="ShiftToReimbursement"
            id={`shiftToReimbursementButton-${requestId}`}
            className="button-img"
            onClick={() => toggleShiftToReimbursementModal(requestId)}
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          />
          <UncontrolledTooltip
            target={`shiftToReimbursementButton-${requestId}`}
          >
            Shift to Reimbursement
          </UncontrolledTooltip>
            <img
              src={Communications}
              alt="Pen Button"
              className="button-img"
              id={`sendCommunication-${requestId}`}
              onClick={() => openCommunications(followUpData)}
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            />
            <UncontrolledTooltip target={`sendCommunication-${requestId}`}>
              Send Communication
            </UncontrolledTooltip>          
          <img
            src={RequestReconfirmation}
            alt="RequestReconfirmation"
            className="button-img"
            id={`requestReconfirmationButton-${requestId}`}
            onClick={() => toggleRequestReconfirmationModal(requestId)}
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          />
          <UncontrolledTooltip
            target={`requestReconfirmationButton-${requestId}`}
          >
            Request Reconfirmation
          </UncontrolledTooltip>
          <img
            src={GrowthArrow}
            alt="GrowthArrow"
            className="button-img"
            id={`growthArrowButton-${requestId}`}
            onClick={() => openEscallationModal(requestData)}
            style={{
              width: '24px',
              height: '24px',
              cursor: 'pointer',
            }}
          />
          <UncontrolledTooltip target={`growthArrowButton-${requestId}`}>
            Mark Escallation
          </UncontrolledTooltip>
          <img
            src={MarkFollowUp}
            alt="GrowthArrow"
            className="button-img"
            id={`markFollowUpButton-${requestId}`}
            onClick={() => openFollowUpModal(requestData)}
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          />
          <UncontrolledTooltip target={`markFollowUpButton-${requestId}`}>
            Mark FollowUp
          </UncontrolledTooltip>
        </div>
      ))}
      <div>
        <OfflineModalConfirm
          centered
          modalIsOpen={confirmModalIsOpen}
          openModal={confirmOpenModal}
          requestDataForConfirm={requestDataForConfirm}
          closeModal={confirmCloseModal}
          submitModal={submitConfirmModal}
          onComplete={onAction}
          showEditDoctorSlotsModal={editDoctorSlots}
          showEditDoctorSlots={showEditDoctorSlots}
          doctorId={doctorId}
          requestId={requestId}
        />
        <OfflineModalCancel
          centered
          modalIsOpen={cancelModalIsOpen}
          openModal={cancelOpenModal}
          closeModal={cancelCloseModal}
          submitModal={submitCancelModal}
          onComplete={onAction}
          requestId={requestId}
          cancelledReasons={cancelledReasons}
        />
        {shiftingDetails && (
          <RescheduleAppointMentRequest
            isOpen={showShiftingModal}
            closeShiftingModal={() => setShowShiftingModal(false)}
            fetchTableData={getOfflineRequestData}
            shiftingDetails={shiftingDetails}
            showEditDoctorSlotsModal={editDoctorSlots}
            showEditDoctorSlots={showEditDoctorSlots}
          />
        )}
        <div>
          <ClaimRequestModal
            onClose={onClaimRequestModalClose}
            isOpen={showClaimRequestModal}
            requestId={showClaimRequestModalRequestId}
          />
        </div>
        { showApiBookingModal && (
          <OfflineApiBookingModal
            isOpen={showApiBookingModal}
            closeShiftingModal={() => setShowApiBookingModal(false)}
            requestDetails={shiftingDetails}
          />
        )}
        <div>
          <StuckReuestModal
            onClose={onStuckRequestModalClose}
            isOpen={markAsStuckModalOpen}
            requestId={markAsStuckRequestId}
          />
        </div>
        <div>
          {addDoctorDetailModalOpen && (
            <AddDoctorDetailModal
              centered
              isOpen={addDoctorDetailModalOpen}
              closeAddDoctorDetailModal={closeAddDoctorDetailModal}
              getOfflineRequestData={getOfflineRequestData}
              onComplete={onAction}
              followUpDetails={followUpDetails}
            />
          )}
        </div>
        <div>
        <div>
          {openComms && (
            <CommunicationsSetup
              closeModal={closeCommunications}
              isOpen={openComms}
              commsData={commsData}
             />
          )}
        </div>
          {showEscallationModal && (
            <MarkEscallationModal
              isOpen={showEscallationModal}
              closeEscallationModal={closeEscallationModal}
              getAllOfflineRequests={getAllOfflineRequests}
              onComplete={onAction}
              requestId={escallationRequestId}
              //followUpDetails={followUpDetails}
            />
          )}
        </div>
        <div>
          {showFollowUpModal && (
            <FollowUpModal
              isOpen={showFollowUpModal}
              closeFollowUpModal={closeFollowUpModal}
              getAllOfflineRequests={getAllOfflineRequests}
              onComplete={onAction}
              followUpDetails={followUpDetails}
            />
          )}
        </div>
        <div>
          <SuggestionsDialog
            suggestionsDetails={suggestionsDetails}
            isOpen={showSuggestions}
            closeSuggestions={closeSuggestions}
          />
        </div>
        <div>
        {prebookUserConfirmModalOpen && (
            <PrebookUserConfirmedModal
              isOpen={prebookUserConfirmModalOpen}
              closePrebookUserConfirmModal={closePrebookUserConfirmModal}
              prebookRequestDetails={prebookRequestDetails}
              onComplete={onAction}
            />
          )}
        </div>
        <div>
        {prebookCenterConfirmModalOpen && (
            <PrebookCenterConfirmedModal
              isOpen={prebookCenterConfirmModalOpen}
              closePrebookCenterConfirmModal={closePrebookCenterConfirmModal}
              prebookRequestDetails={prebookRequestDetails}
              onComplete={onAction}
            />
          )}
        </div>
        <div>
          <Modal
            centered
            isOpen={shiftReimbursementModalOpen}
            toggle={toggleShiftToReimbursementModal}
          >
            <ModalHeader
              className="revamp-modal-header"
              toggle={toggleShiftToReimbursementModal}
            >
              Shift to Reimbursement
            </ModalHeader>
            <ModalBody
              style={{
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              Are you sure you want to shift this appointment to reimbursement?
              <Row
                style={{
                  padding: '10px',
                }}
              >
                <Col
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >
                  Request Id:{' '}
                </Col>
                <Col
                  style={{
                    border: '1px solid #e9ecef',
                    borderRadius: '10px',
                    marginRight: '260px',
                  }}
                >
                  {shiftToReimbursementReqId}
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {loading ? (
                <LoadingComponent />
              ) : (
                <Button
                  justifyContent="center"
                  type="submit"
                  style={{
                    backgroundColor: 'rgb(113, 79, 255)',
                    width: '40%',
                    borderColor: 'rgb(113, 79, 255)',
                    height: '44px',
                  }}
                  onClick={() =>
                    shiftAppointmentToReimbursement(shiftToReimbursementReqId)
                  }
                >
                  Confirm
                </Button>
              )}
            </ModalFooter>
          </Modal>
        </div>
        <div>
          <Modal
            centered
            isOpen={requestReconfirmationModalOpen}
            toggle={toggleRequestReconfirmationModal}
          >
            <ModalHeader
              className="revamp-modal-header"
              toggle={toggleRequestReconfirmationModal}
            >
              {`Request Reconfirmation (Request Id: ${requestReconfirmationRequestId})`}
            </ModalHeader>
            <ModalBody
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {loading ? (
                <LoadingComponent />
              ) : (
                <Button
                  justifyContent="center"
                  type="submit"
                  style={{
                    backgroundColor: 'rgb(113, 79, 255)',
                    width: '40%',
                    borderColor: 'rgb(113, 79, 255)',
                    height: '44px',
                  }}
                  size="sm"
                  onClick={() =>
                    requestReconfirmationForConsultation(
                      requestReconfirmationRequestId
                    )
                  }
                >
                  Confirm
                </Button>
              )}
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div>
        <Modal
          isOpen={priceChangeRequestsModal}
          toggle={togglePriceChangeRequest}
          centered
        >
          <ModalHeader
            className="revamp-modal-header"
            toggle={togglePriceChangeRequest}
          >
            {`Price Change Request`}
          </ModalHeader>
          <ModalBody>
            {requestIdDataForPriceChange && (
              <>
                <FormGroup>
                  <Row>
                    <Col className="col-3">Doctor Id :</Col>
                    <Col>{requestIdDataForPriceChange?.doctorId}</Col>
                  </Row>
                  <Row>
                    <Col className="col-3">Doctor Name :</Col>
                    <Col>{requestIdDataForPriceChange?.doctorName}</Col>
                  </Row>
                  <Row>
                    <Col className="col-3">Center Id :</Col>
                    <Col>{requestIdDataForPriceChange?.networkCenterId}</Col>
                  </Row>
                  <Row>
                    <Col className="col-3">Center Name :</Col>
                    <Col>{requestIdDataForPriceChange?.networkCenterName}</Col>
                  </Row>
                  <Row>
                    <Col className="col-3">Previous Price :</Col>
                    <Col>
                      {requestIdDataForPriceChange?.lastPrice
                        ? requestIdDataForPriceChange.lastPrice
                        : 'Not Available'}
                    </Col>
                  </Row>
                </FormGroup>
              </>
            )}
            <FormGroup>
              <Row>
                <Col className="col-3">Select Reason :</Col>
                <Col>
                  <Select
                    style={{ width: '70%', height: '44px' }}
                    defaultValue="Select Reason"
                    onChange={(e) => setPriceChangeReason(e)}
                  >
                    <Option value="Fee got revised">Fee got revised</Option>
                    <Option value="Incorrect fee was updated in our system">
                      Incorrect fee was updated in our system
                    </Option>
                    <Option value="Incorrect price was informed by the centre while booking the appointment">
                      Incorrect price was informed by the centre while booking
                      the appointment
                    </Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col className="col-3">New Prices:</Col>
                <Col>
                  <InputBox
                    style={{ width: '70%' }}
                    type={'number'}
                    value={newPrice}
                    placeholder="Please enter new Prices for offline consultations"
                    onChange={(e) => setNewPrice(e.target.value)}
                  />
                </Col>
              </Row>
            </FormGroup>

            {priceChangeReason === 'Other' && (
              <InputBox
                type={'text'}
                value={inputReason}
                placeholder="Please enter reason for Price Change"
                onChange={(e) => setInputReason(e.target.value)}
              />
            )}
            {loading ? (
              <LoadingComponent />
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    paddingTop: '20px',
                  }}
                >
                  <Button
                    type="submit"
                    size="sm"
                    style={{
                      backgroundColor: '#714FFF',
                      width: '40%',
                      borderColor: '#714FFF',
                      height: '44px',
                    }}
                    onClick={() => sendPriceChangeRequest()}
                    disabled={
                      !newPrice ||
                      !priceChangeReason ||
                      (priceChangeReason === 'Other' && !inputReason)
                    }
                  >
                    Send for Approval
                  </Button>

                  <Button
                    type="submit"
                    size="sm"
                    style={{
                      backgroundColor: '#FFFFFF',
                      width: '40%',
                      borderColor: '#714FFF',
                      color: '#714FFF',
                      height: '44px',
                    }}
                    onClick={togglePriceChangeRequest}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </ModalBody>
        </Modal>
      </div>
      <div>
      <Modal
          isOpen={shiftToPendingModalOpen}
          toggle={toggleShiftToPendingModal}
        >
          <ModalHeader
            className="revamp-modal-header"
            toggle={toggleShiftToPendingModal}
          >
            {`Shift to pending (Request Id: ${shiftToPendingRequestId})`}
          </ModalHeader>
          <ModalBody style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
            {loading ? (
              <LoadingComponent />
            ) : (
              <Button
                justifyContent="center"
                  type="submit"
                  style={{
                    backgroundColor: 'rgb(113, 79, 255)',
                    width: '40%',
                    borderColor: 'rgb(113, 79, 255)',
                    height: '44px',
                  }}
                onClick={() =>
                 shiftAppointmentToPending(
                    shiftToPendingRequestId
                  )
                }
              >
                Confirm
              </Button>
            )}
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Actions;
