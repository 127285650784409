import React from 'react';

// import { Container, ContainerItem } from './StyledComponents';
import { Container, ContainerItem } from '../redemptions/StyledComponent';
import HistoryContainer from '../offline-consultations/table-subcomponent/HistoryContainer';
import NewPolicyDetails from '../policy-details/NewPolicyDetails';
import Prescription from '../prescription-details/Prescription';

export default class SubComponent extends React.Component {
  render() {
    const { requestId, userId, tUserId } = this.props;
    return (
      <div style={{
        display: 'flex',
      }}> 
        <div style={{ width: '500px', margin : '7px'}}>
          <HistoryContainer requestId={requestId} />
        </div>
        <div style={{ display : 'flex', margin : '7px' }}>
          <NewPolicyDetails userId={userId} tUserId={tUserId}/>
        </div>
        <div style={{
          margin: '7px',
        }}>
          <Prescription requestId={requestId} />
        </div>
      </div>
    );
  }
}
