import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { changeRequestCenter, getCenters, getVaccinationRequest } from '../../../services/api/cashless-vaccination';

function ChangeCenter({isOpen,
    onClose,
    requestId,
    onComplete,
    className = 'bg-primary',}) {
    
    const [centerOptions, setCenterOptions] = useState([]);
    const [currentCenter, setCurrentCenter] = useState(null);
    const [newCenter, setNewCenter] = useState(null);
    const [isLoadingCenters, setIsLoadingCenters] = useState(false);
    const [vaccinationCategoryId, setVaccinationCategoryId] = useState(null);

    const resetStates = () => {
        setNewCenter(null);
        setCenterOptions([]);
        setIsLoadingCenters(false);
    };

    useEffect(() => {
        if (isOpen) {
            getVaccinationRequest(requestId).then((data) => {
                console.log(data, '[data]');
                setCurrentCenter({
                    value: data.basicInfo.centerId,
                    label: data.basicInfo.networkCenterName,
                });
                setVaccinationCategoryId({
                    value: data.basicInfo.categoryId,
                    label: data.basicInfo.categoryName
                })
            });
        } else {
            resetStates();
        }
    }, [isOpen, requestId])

    const handleCenterChange = (selectedOption) => {
        setNewCenter(selectedOption);
    };

    const handleSubmit = () => {
        if (newCenter) {
            changeRequestCenter(requestId, newCenter);
            onComplete();
            handleClose();
        }
    };

    const handleCenterSearch = (inputValue) => {
        console.log(vaccinationCategoryId, '[vaccinationCategoryId]');
        if (inputValue.length > 2) {
            getCenters(inputValue, null, vaccinationCategoryId.value).then((centers) => {
                setIsLoadingCenters(true);
                setCenterOptions(
                    centers.data.map((center) => ({
                        value: center.centerId,
                        label: center.nameAndId,
                    }))
                );
                setIsLoadingCenters(false);
            });
        }
      };

    const handleClose = () => {
        resetStates();
        onClose();
    };

    return (
        <Modal isOpen={isOpen} toggle={handleClose}>
            <ModalHeader toggle={handleClose}>Change Center Request Id {requestId}</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for='currentCenter'>Current Center</Label>
                        <Input
                            id='currentCenter'
                            value={currentCenter?.label || ''}
                            disabled
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for='newCenter'>Select New Center</Label>
                        <Select
                            id='newCenter'
                            showSearch
                            value={newCenter}
                            placeholder="Search for a center..."
                            onSearch={handleCenterSearch}
                            onChange={handleCenterChange}
                            options={centerOptions}
                            loading={isLoadingCenters}
                            filterOption={false}
                            style={{ width: '100%' }}
                            allowClear={true}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button 
                    color='primary' 
                    onClick={handleSubmit}
                    disabled={!newCenter}
                >
                    Confirm Change
                </Button>
                <Button color='secondary' onClick={handleClose}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ChangeCenter

