import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { FaSpinner, } from 'react-icons/fa';
import Phone from '../../images/Phone.svg';

const VisionCallingButton = ({ onSubmit }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    onSubmit();
    setLoading(false);
  };

  return (
    <div>
      <Button color="link" className="p-0 ml-1">
        {loading ? (
          <FaSpinner className="icon-spin text-success" />
        ) : (
          <img src={Phone} onClick={handleClick} alt='call' />
        )}
      </Button>
    </div>
  );
};

export default VisionCallingButton;
