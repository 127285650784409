
import React, { useEffect, useState, useRef } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Row,
  Col,
  Label,
} from 'reactstrap';
import { TimePicker } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';
import SolidButton from '../common/SolidButton';
import DoctorSlot from './DoctorSlot';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import maleIcon from '../../images/maleIcon.svg';
import femaleIcon from '../../images/femaleIcon.svg';
import plusIcon from '../../images/plusIcon.svg';
import BlankImage from '../../images/blankImage.svg';
import { reactSelectStyleMulti } from '../common/CustomStyles';
import LoadingComponent from '../../components/common/LoadingComponent';
import '../../styling/doctor.css';

import fetchDoctorVerticals, { editDoctorSlots } from '../../services/api/doctor';
import { getVerticals } from '../../services/api/offline-consult';
import {
  fetchLanguages,
  fetchUserCenter,
  createDoctor,
} from '../../services/api/doctor';

const AddDoctorDetailModal = (props) => {
  const {
    isOpen,
    closeAddDoctorDetailModal,
    fetchTableData,
    followUpDetails,
    onComplete,
    doctorData,
    prevSlots,
    modal,
    setModal,
  } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [editDoctorSlot, setEditDoctorSlot] = useState([]);
  const [isSlotTab, setIsSlotTab] = useState(false);
  const [verticals, setVerticals] = useState([]);
  const [fetchingCenters, setFetchingCenters] = useState(false);
  const [centers, setCenters] = useState([]);
  const [languages, setLanguages] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedCenter, setSelectedCenter] = useState({
    value: data.centerId,
    label: data.centerName,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const [verticals, userCenter] = await Promise.all([
          getVerticals(),
          //fetchLanguages(),
        ]);

        
        let verticalsResult = verticals && verticals.data ? verticals.data : [];

        //let allLanguagesResult = allLanguages.data ? allLanguages.data : [];

        let userCenterResult =
          userCenter && userCenter.resultList ? userCenter.resultList : [];

        verticalsResult = verticalsResult.map((res) => ({
          label: res.verticalName,
          value: res.verticalId,
        }));

        // userCenterResult = userCenterResult.map((res) => ({
        //   label: res.centerName,
        //   value: res.centerId,
        // }));

        setData({
          ...data,
          url: '',
          name: '',
          centerName: '',
          description: '',
          speciality: '',
          verticalId: '',
          centerId: '',
          language: [],
          years: '',
          phone: '',
          qualification: '',
          registrationNumber: '',
          fees: '',
          gender: '',
          isMale: false,
          isFemale: false,
          isName: false,
          file: null,
        });
        //setLanguages(allLanguagesResult);
        setVerticals(verticalsResult);
        //setCenters(userCenterResult);
        setLoading(false);
      } catch (err) {
        toast.error(err.response.data.errorDebug);
        setLoading(false);
      }
    }

    fetchData();
  }, []); // Ensure that the useEffect runs only once during component mount

  useEffect(() => {
    
  }, [verticals]);

  const loadOptions = async (inputValue) => {
    if (!inputValue.trim()) {
      return [];
    }

    try {
      const userCenter = await fetchUserCenter(inputValue);
      
      let userCenterResult =
          userCenter && userCenter.resultList ? userCenter.resultList : [];
      userCenterResult = userCenterResult.map((res) => (
          {
            label: res.title + ' ('+res.address+')'+ ' | Id-'+res.centerId,
            value: res.centerId,
            
          }));
     
      setCenters(userCenterResult);
      return userCenterResult;
    } catch (error) {
      console.error('Error fetching centers:', error);
      return [];
    }
  };

// const loadOptions = async (inputValue, callback) => {
//     if (!inputValue.trim()) {
//       callback([]);
//       return;
//     }

//     try {
//       const response = await fetchUserCenter(inputValue);
//       const options = response.result.map((center) => ({
//         value: center.centerId,
//         label: center.title,
//         locality: center.locality,
//       }));
//       callback(options);
//     } catch (error) {
//       console.error('Error fetching centers:', error);
//       callback([]);
//     }
//   };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setData({ ...data, file, url: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const onSearchCenter = async (value) => {
    setFetchingCenters(true);

    if (value.trim() === '') {
      setCenters([]);
      setFetchingCenters(false);
      return;
    }

    try {
      const res = await fetchUserCenter(value);
      setCenters(
        res.result.map((ele) => ({
          key: ele.centerId,
          label: ele.title,
          
        }))
      );
    } finally {
      setFetchingCenters(false);
    }
  };

  const handleSubmit = async () => {
    if (!data.name || !data.verticalId || !data.fees || (editDoctorSlot.length<=0 || !data.centerName)) {
        toast.warning("Please fill in all mandatory fields - Name, Vertical, Fees, Slots, Center");
        return; // Stop the submission process
      }
    
    setLoading(true);

    try {
      let postData = {
        networkCenterId: data.centerId,
        centerName: data.centerName,
        doctorName: data.name,
        doctorDescription: data.description,
        doctorQualifications: data.qualification,
        registrationNumber: data.registrationNumber,
        practiceYears: data.years,
        doctorVerticalId: data.verticalId,
        knownLanguages: data.language.map((ele) => ele.value).join(','),
        doctorGender: null,
        doctorPhone: data.phone,
        isOnlineDoctor: 0,
        isOfflineDoctor: 1,
        doctorSpeciality: data.speciality,
        isNightDoctor: 0,
        networkSource: 6,
        preference: 'voice',
        onlyAvailableForBookForLater: 0,
        onlineSlotDuration: 15,
        scheduleConsultCancelWindowEnds: 2,
        onlineSlotCalcWindow: 2,
        doctorFees: data.fees,
        requestComingFrom : 'ops-dashboard'
      };

      let formData = new FormData();
      formData.append('file', data.file);

      for (let index in postData) {
        formData.append(index, postData[index]);
      }

      const response = await createDoctor(formData);
      if (response && response.message == 'success') {
        
        if (editDoctorSlot.length > 0) {
          const slotData = editDoctorSlot.map((res) => ({
            ...res,
            doctorId: response.doctorId,
            networkCenterId: postData.networkCenterId,
          }));
          
          const slotResponse = await editDoctorSlots(
            slotData,
            response.doctorId
          );
          
        }
        toast.info(
          'Added Doctor Request has been created for network approval. Please wait upto 12 hours.'
        );
        //DISABLE THE MODAL NOW
      } else if (response && response.data.message == 'serverError') {
        toast.error(response.data.errorMessage);
      }
      setLoading(false);
    } catch (err) {
      
      setLoading(false);
      toast.error(err.response.data.errorMessage);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancel={closeAddDoctorDetailModal}
      toggle={closeAddDoctorDetailModal}
      size="lg"
      className="rtsp-modal-big"
    >
      <ModalHeader className="bg-primary" toggle={closeAddDoctorDetailModal}>
        Add Doctor Details
      </ModalHeader>
      <ModalBody>
        <div className="doctorProfile">
          <div className="editDoctorProfile">
            <div className="editDoctorProfile-upper">
              <div style={{ display: 'flex' }}>
                <div>
                  <img
                    className="editDoctorProfile-img"
                    src={data.url ? data.url : BlankImage}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: data.name ? 'column' : 'row',
                    justifyContent: 'center',
                    alignItems: !data.name && 'center',
                    marginTop: !data.name && '16px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '12px',
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      {data.isName ? (
                        <input
                          type="text"
                          name="name"
                          onChange={handleChange}
                          defaultValue={data.name}
                          style={{
                            fontSize: '20px',
                            fontWeight: 700,
                            background: '#FAFAFA',
                            padding: '8px',
                            borderRadius: '16px',
                            margin: '0px 32px 8px 20px',
                          }}
                        />
                      ) : (
                        <h2
                          className="doctorProfile-info-name"
                          style={{
                            fontSize: '24px',
                            fontWeight: 700,
                            margin: '0px 16px 10px 24px',
                          }}
                        >
                          {data.name}
                        </h2>
                      )}
                    </div>
                    <div>
                        {!data.name &&
                            <SolidButton
                            text={data.name ? 'Edit Name' : 'Add Name'}
                            style={{
                              radius: '8px',
                              border: `none`,
                              background: '#E5E1FD',
                              height: '40px',
                              width: '135px',
                              color: '#714FFF',
                            }}
                            onClick={() => {
                              setData({ ...data, isName: !data.isName });
                            }}
                            children={
                              !data.name ? (
                                <img
                                  src={plusIcon}
                                  style={{ marginRight: '8px' }}
                                />
                              ) : (
                                ''
                              )
                            }
                          />
                        }
                      
                    </div>
                  </div>
                  {/* <div style={{ display: 'flex', marginBottom: '12px' }}>
          <SolidButton
            text="Upload Image"
            style={{
              radius: '8px',
              border: `none`,
              background: '#E5E1FD',
              height: '40px',
              width: '135px',
              color: '#714FFF',
            }}
            onClick={() => {
              fileInputRef.current.click();
            }}
            children={<img src={plusIcon} style={{ marginRight: '8px' }} />}
          />
        </div> */}
                  <div style={{ display: 'flex', marginBottom: '12px' }}>
                    {/* <SolidButton
                      text="Upload Image"
                      style={{
                        radius: '8px',
                        border: `none`,
                        background: '#E5E1FD',
                        height: '40px',
                        width: '135px',
                        color: '#714FFF',
                        padding: '2px',
                        margin: '8px',color :'rgb(113, 79, 255)'
                      }}
                      onClick={() => {
                        fileInputRef.current.click();
                      }}
                      children={
                        <img src={plusIcon} style={{ marginRight: '8px' }} />
                      }
                    /> */}
                    <h2
                        className="slotBox-text"
                        style={{
                          color: '#714FFF',
                          marginLeft: '24px',
                          cursor: 'pointer',
                          fontSize: '16px',
                        }}
                        onClick={() => {
                          fileInputRef.current.click();
                        }}
                      >
                        Upload Image
                      </h2>
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleImageUpload}
                    />
                  </div>
                  {/* {!isSlotTab && (
                    <div
                     className="doctorSlot-button"
                      style={{ marginTop: '-10px' }}
                    >
                      <SolidButton
                        text="Add Slots"
                        style={{
                          radius: '8px',
                          border: `none`,
                          background: '#E2DEFF',
                          height: '40px',
                          width: '150px',
                          color: '#714FFF',
                          flexDirection: 'justify-right',
                        }}
                        onClick={() => {
                          setIsSlotTab(true);
                        }}
                        children={
                          <img src={plusIcon} style={{ marginRight: '8px' }} />
                        }
                      />
                    </div>
                  )} */}
                </div>
              </div>
              {/* {!isSlotTab && (
                    <div
                     className="doctorSlot-button"
                      style={{flexDirection:'column', justifyContent:'space-around'}}
                    >
                      <SolidButton
                        text="Add Slots"
                        style={{
                          radius: '34px',
                          border: `none`,
                          background: '#E2DEFF',
                          height: '55px',
                          width: '150px',
                          color: '#714FFF',
                          flexDirection: 'justify-right',
                        }}
                        onClick={() => {
                          setIsSlotTab(true);
                        }}
                        children={
                          <img src={plusIcon} style={{ marginRight: '8px' }} />
                        }
                      />
                    </div>
                  )} */}
            </div>

            <div className="editDoctorProfile-lower">
              <div className="editDoctorProfile-lower-main">
                <Row className="editDoctorProfile-lower-row">
                  <Col>
                    <FormGroup>
                      <Label className="editDoctorProfile-label">
                        Centre Name
                      </Label>
                      {/* <ReactSelect
                        placeholder="Select Centre Name"
                        className="editDoctorProfile-lower-select"
                        defaultValue={
                          //Adjust defaultValue accordingly
                          {
                            value: data.centerId,
                            label: data.centerName,
                          }
                        }
                        options={centers}
                        onChange={(e) => {
                          setData({
                            ...data,
                            centerId: e.value,
                            centerName: e.label,
                          });
                        }}
                      /> */}
                      <AsyncSelect
                        placeholder="Select Centre Name"
                        className="editDoctorProfile-lower-select"
                        defaultValue={selectedCenter}
                        loadOptions={loadOptions}
                        options={centers}
                        onChange={(selectedOption) => {
                          setSelectedCenter(selectedOption);
                          setData({
                            ...data,
                            centerId: selectedOption.value,
                            centerName: selectedOption.label,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label className="editDoctorProfile-label">
                        Doctor Speciality
                      </Label>
                      <ReactSelect
                        placeholder="Type Your Speciality"
                        //className="editDoctorProfile-lower-select"
                        // defaultValue={{
                        //   value: data.verticalId,
                        //   label: data.speciality,
                        // }}
                        // defaultValue={verticals.find(
                        //     (v) => v.value === data.verticalId
                        //   )}
                        options={verticals}
                        onChange={(e) => {
                          setData({
                            ...data,
                            verticalId: e.value,
                            speciality: e.label,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row className="editDoctorProfile-lower-row"> */}
                  {/* <Col className="editDoctorProfile-lower-row-left">
                    <FormGroup>
                      <Label className="editDoctorProfile-label">
                        Practice years
                      </Label>
                      <div
                        style={{
                          display: 'flex',
                          border: '1px solid #e3e3e3',
                          borderRadius: '12px',
                          width: '485px',
                        }}
                      >
                        <input
                          placeholder="Type Years"
                          className="editDoctorProfile-lower-input"
                          style={{ border: 'none', outline: 'none' }}
                          type="number"
                          defaultValue={data.years}
                          name="years"
                          onChange={handleChange}
                        />
                        <p className="inputText">Years</p>
                      </div>
                    </FormGroup>
                  </Col> */}
                  {/* <Col>
                    <FormGroup>
                      <Label className="editDoctorProfile-label">
                        Contact Details
                      </Label>
                      <input
                        placeholder="Enter Your Number"
                        className="editDoctorProfile-lower-input"
                        type="text"
                        defaultValue={data.phone}
                        maxLength="10"
                        name="phone"
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col> */}
                {/* </Row> */}
                <Row className="editDoctorProfile-lower-row">
                  <Col className="editDoctorProfile-lower-row-left">
                    {/* <FormGroup>
                      <Label className="editDoctorProfile-label">
                        Medical Registration Number
                      </Label>
                      <input
                        placeholder="Enter Medical Registration Number"
                        className="editDoctorProfile-lower-input"
                        type="text"
                        defaultValue={data.registrationNumber}
                        name="registrationNumber"
                        onChange={handleChange}
                      />
                    </FormGroup> */}
                    <FormGroup>
                      <Label className="editDoctorProfile-label">
                        Consultation Fees
                      </Label>
                      <br></br>
                      <input
                        placeholder="Enter Consultation Fees"
                        className="editDoctorProfile-lower-input"
                        type="number"
                        defaultValue={data.fees}
                        name="fees"
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col
                  //className="editDoctorProfile-lower-row-main"
                  className="editDoctorProfile-lower-row-left"
                  >
                    <FormGroup>
                      <Label className="editDoctorProfile-label">
                        Consultation Fees
                      </Label>
                      <input
                        placeholder="Enter Consultation Fees"
                        className="editDoctorProfile-lower-input"
                        type="number"
                        defaultValue={data.fees}
                        name="fees"
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col> */}
                </Row>
                {/* <Row className="editDoctorProfile-lower-row">
                  <Col className="editDoctorProfile-lower-row-left">
                    <FormGroup>
                      <Label className="editDoctorProfile-label">
                        Doctor Qualifications
                      </Label>
                      <input
                        placeholder="Enter Your Qualifications"
                        className="editDoctorProfile-lower-input"
                        type="text"
                        defaultValue={data.qualification}
                        name="qualification"
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="editDoctorProfile-lower-row-main">
                    <FormGroup>
                      <Label className="editDoctorProfile-label">
                        Select Gender
                      </Label>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: '420px',
                        }}
                      >
                        <SolidButton
                          text="Male"
                          style={{
                            radius: '12px',
                            border: `1px solid #E3E3E3`,
                            background: `${data.isMale ? '#E2DEFF' : '#fff'}`,
                            height: '40px',
                            width: '200px',
                            color: 'black',
                          }}
                          children={
                            <img
                              src={maleIcon}
                              style={{ marginRight: '8px' }}
                            />
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              isMale: !data.isMale,
                            });
                          }}
                        />
                        <SolidButton
                          text="Female"
                          style={{
                            radius: '12px',
                            border: `1px solid #E3E3E3`,
                            background: `${data.isFemale ? '#E2DEFF' : '#fff'}`,
                            height: '40px',
                            width: '200px',
                            color: 'black',
                          }}
                          children={
                            <img
                              src={femaleIcon}
                              style={{ marginRight: '8px' }}
                            />
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              isFemale: !data.isFemale,
                            });
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row> */}
                {/* <Row className="editDoctorProfile-lower-row">
                  <Col className="editDoctorProfile-lower-row-left">
                    <FormGroup>
                      <Label className="editDoctorProfile-label">
                        Description
                      </Label>
                      <textarea
                        placeholder="Describe Yourself"
                        className="editDoctorProfile-lower-input"
                        style={{ height: '100px' }}
                        name="description"
                        onChange={handleChange}
                      >
                        {data.description}
                      </textarea>
                    </FormGroup>
                  </Col>
                  <Col
                  //className="editDoctorProfile-lower-row-main"
                  >
                    <FormGroup>
                      <Label className="editDoctorProfile-label">
                        Language
                      </Label>
                      <ReactSelect
                        placeholder="Type Languages"
                        className="editDoctorProfile-lower-select-multi"
                        defaultValue={data.language}
                        isMulti
                        options={languages}
                        styles={reactSelectStyleMulti}
                        onChange={(e) => {
                          setData({ ...data, language: e });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row>
                {!isSlotTab && (
                    <div
                     className="doctorSlot-button"
                      style={{flexDirection:'column', justifyContent:'space-around'}}
                    >
                      <SolidButton
                        text="Add Slots"
                        style={{
                          radius: '34px',
                          border: `none`,
                          background: '#E2DEFF',
                          height: '55px',
                          width: '150px',
                          color: '#714FFF',
                          flexDirection: 'justify-right',
                        }}
                        onClick={() => {
                          setIsSlotTab(true);
                        }}
                        children={
                          <img src={plusIcon} style={{ marginRight: '8px' }} />
                        }
                      />
                      
                    </div>
                  )}
                </Row>
              </div>
            </div>
            <div>
              {isSlotTab && (
                <div
                  className="doctorProfile"
                  style={{ marginTop: '18px', padding: '0px 0px 16px 0px' }}
                >
                  <div className="doctorSlot-box" style={{display:'flex'}}>
                    <h2
                      className="doctorProfile-info-name"
                      style={{
                        textAlign: 'unset',
                        margin: '10px 16px',
                        color: '#0F0B28',
                        fontWeight: 600,
                      }}
                    >
                      Add Slot
                    </h2>
                  </div>

                  <div style={{ marginTop: '18px' }}>
                    <DoctorSlot
                      //   doctorSlots={doctorData}
                      //   prevSlots={prevSlots}
                      isSlotTab={isSlotTab}
                      setIsSlotTab={setIsSlotTab}
                      setEditDoctorSlot={setEditDoctorSlot}
                      editDoctorSlot={editDoctorSlot}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Submit Details
        </Button>
        <Button color="secondary" onClick={() => closeAddDoctorDetailModal()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddDoctorDetailModal;
