import { Checkbox, DatePicker, Select } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';
import { showRequestModal } from '../../actions';
import SearchIcon from '../../images/SearchIcon.svg';
import ShowActions from '../../images/ShowActions.svg';
import UnhideActions from '../../images/UnhideActions.svg';
import { getAllOfflineRequests } from '../../services/api/offline-consult';
import LoadingComponent from '../common/LoadingComponent';
import NavBar from '../layout/NavBar';
import ClaimedByFilter from './ClaimedByFilter';
import Pagination from './PaginationComponent';

import moment from 'moment';
import config from '../../config';
import FetchFollowUpModal from './FetchFollowUpModal';
import RidSearch from './RidSearch';
import './revamp-react-table.css';

import {
  fetchAllConceirgeSponsor,
  getAllConceirgeRequests,
  getCountOfConceirgeRequests,
} from '../../services/api/conceirgeService';
import Actions from './Actions';
import AddAssigneModal from './AddAssigneModal';
import DownloadCsv from './DownloadCsv';
import SourceFilter from './SourceFilter.js';
import FollowUpFilter from './FollowUpFilter';

const ConceirgeRequests = (props) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [countLoader, setCountLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [totalHits, setTotalHits] = useState(0);
  const [pages, setPages] = useState(0);
  const [orderBy, setOrderBy] = useState(null);
  const [orderDir, setOrderDir] = useState(null);
  const [claimedByFilter, setClaimedByFilter] = useState('');
  const [serviceTypeFilter, setServiceTypeFilter] = useState('');
  const [followUpFilter, setFollowUpFilter] = useState('');
  const [page, setPage] = useState(0);
  const [filterRequestId, setFilterRequestId] = useState('');
  const [patientName, setPatientName] = useState('');
  const [userName, setUserName] = useState('');
  const [centerName, setCenterName] = useState('');
  const [sponsorList, setSponsorList] = useState([]);
  const [sponsorFilter, setSponsorFilter] = useState(null);
  const [appointmentStartDate, setAppointmentStartDate] = useState(null);
  const [appointmentEndDate, setAppointmentEndDate] = useState(null);
  const [requestStartDate, setRequestStartDate] = useState(
    moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm')
  );
  const [requestEndDate, setRequestEndDate] = useState(
    moment().endOf('day').format('YYYY-MM-DD HH:mm')
  );
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [showFetchFollowUpModal, setShowFetchFollowUpModal] = useState(false);
  const [fetchFollowUpDetails, setFetchFollowUpDetails] = useState(null);
  const [allChecked, setAllChecked] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedRequestDateRange, setSelectedRequestDateRange] =
    useState(null);
  const [addAssigneModalOpen, setAddAssigneModalOpen] = useState(false);
  const [addAssigneFlag, setAddAssigneFlag] = useState(false);
  const [toggleActions, setToggleActions] = useState(true);
  const { RangePicker } = DatePicker;

  const debouncedSetFilterRequestId = debounce(setFilterRequestId, 1500);
  const debouncedSetCenterName = debounce(setCenterName, 900);
  const debouncedSetPatientName = debounce(setPatientName, 900);
  const debouncedSetUserName = debounce(setUserName, 900);
  console.log(filterRequestId, 'filterRequestId');

  const dispatch = useDispatch();
  const filterData = (state, instance) => {
    console.log('filter---', state.filtered);
    const { filtered } = state;
    if (filtered.length == 0) {
      debouncedSetFilterRequestId('');
    }
    if (state.sorted && state.sorted.length > 0) {
      setOrderBy(state.sorted[0].id);
      setOrderDir(state.sorted[0].desc ? 'desc' : 'asc');
    } else {
      setOrderBy(1);
      setOrderDir('desc');
    }
    if (state.filtered && state.filtered.length == 0) {
      setClaimedByFilter('');
      setServiceTypeFilter('');
      setFollowUpFilter('');
    }
    state.filtered.forEach((f) => {
      if (f.id === 'claimedBy') {
        console.log(f.value, 'claimedBy');
        setClaimedByFilter(f.value);
      }
      if (f.id === 'sourceType') {
        console.log(f.value, 'sourceType');
        setServiceTypeFilter(f.value);
      }
      if (f.id === 'followUp') {
        console.log(f.value, 'followUp');
        setFollowUpFilter(f.value);
      }
      if (f.id === 'sourceId') {
        debouncedSetFilterRequestId(f.value);
      }
      if (f.id === 'sponsorName') {
        setSponsorFilter(f.value);
      }
      if (f.id === 'patientName') {
        debouncedSetPatientName(f.value);
      }
      if (f.id === 'userName') {
        debouncedSetUserName(f.value);
      }

      if (f.id === 'networkCenterName') {
        debouncedSetCenterName(f.value);
      }

      // if (f.id === 'preBooked') {
      //   setPreBooked(f.value);
      // }
    });
    setPageSize(state.pageSize);
    setPage(state.page);
  };

  const params = {
    pageSize: pageSize,
    page: page,
    claimedByFilter: claimedByFilter,
    serviceTypeFilter:serviceTypeFilter,
    followUpFilter: followUpFilter,
    orderBy: orderBy,
    orderDir: orderDir,
    sponsorFilter,
    appointmentStartDateFilter: appointmentStartDate
      ? moment(appointmentStartDate).format('YYYY-MM-DD HH:mm')
      : null,
    appointmentEndDateFilter: appointmentEndDate
      ? moment(appointmentEndDate).format('YYYY-MM-DD HH:mm')
      : null,
    requestStartDateFilter: requestStartDate,
    requestEndDateFilter: requestEndDate,
    patientNameFilter: patientName,
    userNameFilter: userName,
    centerNameFilter: centerName,
  };

  const getConceirgeRequestData = () => {
    console.log('calling api getConceirgeRequestData', orderBy, orderDir);
    setLoading(true);
    setCountLoader(true);

    const params = {
      pageSize: pageSize,
      page: page,
      claimedByFilter: claimedByFilter,
      serviceTypeFilter:serviceTypeFilter,
      followUpFilter:followUpFilter,
      orderBy: orderBy,
      orderDir: orderDir,
      sponsorFilter,
      patientNameFilter: patientName,
      centerNameFilter: centerName,
      userNameFilter: userName,
    };
    if (search) {
      params.search = search;
    }
    if (filterRequestId) {
      params.requestIdFilter = filterRequestId;
    }

    if (appointmentStartDate && appointmentEndDate) {
      params.appointmentStartDateFilter = appointmentStartDate
        ? moment(appointmentStartDate).format('YYYY-MM-DD HH:mm')
        : null;
      params.appointmentEndDateFilter = appointmentEndDate
        ? moment(appointmentEndDate).format('YYYY-MM-DD HH:mm')
        : null;
    }
    if (!(filterRequestId || centerName || patientName || search || userName)) {
      params.appointmentStartDateFilter = appointmentStartDate
        ? moment(appointmentStartDate).format('YYYY-MM-DD HH:mm')
        : null;
      params.appointmentEndDateFilter = appointmentEndDate
        ? moment(appointmentEndDate).format('YYYY-MM-DD HH:mm')
        : null;
      params.requestStartDateFilter = requestStartDate;
      params.requestEndDateFilter = requestEndDate;
    }

    getAllConceirgeRequests(params)
      .then((res) => {
        console.log('Offline requests:', res);
        if (!res.result) {
          setDataSource(res);
        } else {
          setDataSource(res);
        }
        setLoading(false);
        return getCountOfConceirgeRequests(params);
      })
      .then((res) => {
        setCountLoader(false);
        console.log('Conceirge requests count:', res);
        setPages(Math.ceil(res.totatCount / pageSize));
        setTotalHits(res.totatCount || 0);
      })      
      .catch((err) => {
        console.error('Error fetching conceirge requests:', err);
        setLoading(false);
        setCountLoader(false);
      });
  };

  const onAction = () => {
    getConceirgeRequestData();
  };

  const closeFetchFollowUpModal = () => {
    setShowFetchFollowUpModal(false);
  };

  const handleChange = (value) => {
    setSearch(value);
  };
  const selectRow = (value) => {
    setPageSize(value);
  };
  const handleSponsor = (value) => {
    setSponsorFilter(value);
  };

  const handlePatientNameChange = (value) => {
    const patientValue = value ? value.trim() : value;
    debouncedSetPatientName(patientValue);
  };

  const handleCenterNameChange = (value) => {
    const centerName = value ? value.trim() : value;
    debouncedSetCenterName(centerName);
  };

  const handleUserNameChange = (value) => {
    const userNameValue = value ? value.trim() : value;
    debouncedSetUserName(userNameValue);
  };

  const onSearchClick = () => {
    let notMobile = 0;
    let notEmail = 0;
    const mobileNumberValidationRegex = /^[6789]\d{9}$/;
    const emailValidationRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!mobileNumberValidationRegex.test(search)) {
      notMobile = 1;
    }
    if (!emailValidationRegex.test(search)) {
      notEmail = 1;
    }
    if (notEmail && notMobile && search) {
      toast.error(`Please enter a valid phone number/email`);
      return;
    }

    if (loading) {
      return;
    }
    getConceirgeRequestData();
  };

  useEffect(() => {
    if (orderBy !== null && orderDir !== null) {
      getConceirgeRequestData();
    }
  }, [
    pageSize,
    page,
    orderBy,
    orderDir,
    claimedByFilter,
    serviceTypeFilter,
    followUpFilter,
    sponsorFilter,
    appointmentEndDate,
    appointmentStartDate,
    requestEndDate,
    requestStartDate,
    patientName,
    centerName,
    userName,
    filterRequestId,
  ]);

  const openFetchFollowUpModal = (details) => {
    console.log({ details }, 'open fetch follow up modal');
    setShowFetchFollowUpModal(true);
    setFetchFollowUpDetails(details);
  };

  useEffect(() => {
    return () => {
      debouncedSetFilterRequestId.cancel();
      debouncedSetPatientName.cancel();
      debouncedSetCenterName.cancel();
      debouncedSetUserName.cancel();
    };
  }, []);

  useEffect(() => {
    const getSponsors = async () => {
      try {
        const response = await fetchAllConceirgeSponsor();
        const sponsorsList = response.data.map((sponsor) => ({
          value: sponsor.sponsorId,
          label: sponsor.sponsorName,
        }));
        console.log(sponsorsList, 'Response from server');
        setSponsorList(sponsorsList);
      } catch (error) {
        console.error('Error fetching sponsors:', error);
        setSponsorList([]);
      }
    };

    getSponsors();
  }, []);

  const toggleActionsColumn = () => {
    // Toggle the state between true and false
    setToggleActions((prevState) => !prevState);
  };

  const handleAppointmentDateChange = (date, dateString) => {
    console.log(date, dateString, 'DateRange----');
    setSelectedDateRange(date);
    setAppointmentStartDate(dateString[0]);
    setAppointmentEndDate(dateString[1]);
  };

  const handleRequestDateChange = (date, dateString) => {
    console.log(date, dateString, 'DateRangefsdvsd');
    setSelectedRequestDateRange(date);
    setRequestStartDate(dateString[0]);
    setRequestEndDate(dateString[1]);
  };

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      setAllChecked([...allChecked, parseInt(e.target.value)]);
    } else {
      setAllChecked(allChecked.filter((item) => item != e.target.value));
      setSelectAllChecked(false);
    }
  };

  const handleAllChangeCheckbox = (e) => {
    if (e.target.checked) {
      setSelectAllChecked(!selectAllChecked);
      setAllChecked(dataSource.map((res) => res.conceirgeOrderId));
    } else {
      setSelectAllChecked(!selectAllChecked);
      setAllChecked([]);
    }
  };

  const bulkAssigneRequest = () => {
    setAddAssigneModalOpen(true);
    setAddAssigneFlag('addBulkAssigne');
  };

  const closeAssigneModal = () => {
    setAddAssigneModalOpen(false);
    setAddAssigneFlag(false);
    setAllChecked([]);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const tableStyle = {
    border: 'none',
    'font-size': '15px',
    // overflow: 'hidden',
    'background-color': '#FFFFFF',
    'font-weight': '500',
    'font-family': 'Montserrat',
    margin: '20px',
    // width: '100vw',
  };

  const redirectToService = (row) => {
    const {sourceType, sourceId, userId, isScheduled} = row.original;
    if(sourceType == 'offline'){
      window.open(`${config.opsDashboardURL}revamp-offline-consults/${sourceId}`, "_blank");
      const requestDetails = { /* Fetch or pass details as needed */ };
      dispatch(showRequestModal(sourceId, requestDetails));
    }else if(sourceType == 'online'){
      window.open(`${config.opsDashboardURL}online-consults?reqId=${sourceId}&u=${userId}&sc=${isScheduled ? true : false}`, "_blank");
    }else if(sourceType == 'dental'){

    }else if(sourceType == 'vaccinations'){

    }else if(sourceType == 'pharmacy'){
      window.open(`${config.opsDashboardURL}new-pharmacy-order-details?orderId=${sourceId}`, "_blank");
    }else if(sourceType == 'reimbursement'){
      window.open(`${config.maoriDashboardURL}reimburse-claim/${sourceId}`, "_blank");
    }else if(sourceType == 'labs'){
      window.open(`${config.catalystDashboardURL}?claimId=l-${sourceId}`, "_blank");
    } else if (sourceType === 'digitisation') {
      window.open(`${config.doctorDashboardUrl}?digitisationId=${sourceId}&skipQC=1`, '_blank');
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div
            className="flex flex-row center-text"
            style={{
              // width: 50,
              justifyContent: 'center',
              textAlign: 'center',
              alignContent: 'center',
            }}
          >
            <Checkbox
              name="All"
              type="checkbox"
              placeholder="All"
              checked={selectAllChecked}
              onChange={handleAllChangeCheckbox}
            ></Checkbox>
          </div>
        ),
        filterable: false,
        width: 50,
        style: { alignContent: 'center', justifyContent: 'center' },
        accessor: 'All',
        Cell: (row) => (
          console.log('row', row.original),
          (
            <div className="flex flex-row justify-center">
              <Checkbox
                type="checkbox"
                value={row.original.conceirgeOrderId}
                checked={allChecked.includes(row.original.conceirgeOrderId)}
                onChange={handleChangeCheckbox}
              ></Checkbox>
            </div>
          )
        ),
      },
      {
        Header: () => (
          <div
            style={{
              justifyContent: 'center',
              textAlign: 'center',
              alignContent: 'center',
            }}
            className="flex flex-row center-text"
          >
            RID
          </div>
        ),
        accessor: 'sourceId',
        filterable: true,
        width: 90,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        Cell: (row) => {
          const { sourceId } = row.original;
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '0.5rem',
              }}
            >
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#714FFF',
                  border: 'none',
                  fontWeight: 'bold',
                }}
                onClick={()=>{
                  redirectToService(row)
                }}
              >
                {sourceId}
              </button>
            </div>
          );
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Claimed By
          </div>
        ),
        accessor: 'claimedBy',
        width: 115,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: true,
        Filter: ({ filter, onChange }) => (
          <ClaimedByFilter
            onChange={onChange}
            // value={filter ? filter.value : ''}
            value={
              claimedByFilter ? claimedByFilter : filter ? filter.value : ''
            }
          />
        ),
        Cell: (row) => {
          const { claimedBy } = row.original;
          if (claimedBy) {
            return claimedBy;
          } else {
            return '';
          }
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Service Type
          </div>
        ),
        accessor: 'sourceType',
        width: 115,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: true,
        Filter: ({ filter, onChange }) => (
          <SourceFilter
            onChange={onChange}
            value={
              serviceTypeFilter ? serviceTypeFilter : filter ? filter.value : ''
            }
          />
        ),
        Cell: (row) => {
          const { sourceType } = row.original;
          if (sourceType) {
            return sourceType.charAt(0).toUpperCase() + sourceType.slice(1);
          } else {
            return '';
          }
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Status
          </div>
        ),
        width: 126,
        accessor: 'status',
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: false,
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Follow Up
          </div>
        ),
        width: 140,
        accessor: 'followUp',
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: true,
        Filter: ({ filter, onChange }) => (
          <FollowUpFilter
            onChange={onChange}
            value={
              followUpFilter ? followUpFilter : filter ? filter.value : ''
            }
          />
        ),
        Cell: (row) => {
          const { followUpPending, sourceId, followUpScheduled } = row.original;
        
          return (
            <div>
              {(followUpPending || followUpScheduled) ? (
                <>
                  <p
                    className=""
                    id={`fetchfollowUpButton-${sourceId}`}
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.textDecoration = 'underline')
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.textDecoration = 'none')
                    }
                    onClick={() => openFetchFollowUpModal(row.original)}
                  >
                    <label
                      style={{
                        borderRadius: '10px',
                        padding: '5px',
                        backgroundColor: followUpPending ? '#FC853B': '#00B479',
                        color: 'white',
                        fontSize: '12px',
                        width: 'fit-content',
                      }}
                    >
                      {followUpPending ? 'Follow Up Pending' : 'Follow Up Scheduled'}
                      
                    </label>
                  </p>
                  <UncontrolledTooltip target={`fetchfollowUpButton-${sourceId}`}>
                    Show Follow Ups
                  </UncontrolledTooltip>
                </>
              ) : null}
            </div>
          );
        },        
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Delayed
          </div>
        ),
        width: 140,
        accessor: 'none',
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: false,
        Cell: (row) => {
          const { delayedBy } = row.original;
          console.log(row.original, 'row.original---');
          return (
            delayedBy ? (
              <div
                style={{
                  backgroundColor: delayedBy <= 2 ? '#FC853B' : '#DC3545',
                  color: 'white',
                  padding: '5px 10px', // Optional for spacing
                  borderRadius: '4px', // Optional for rounded corners
                  display: 'inline-block', // Keeps the styling concise
                }}
              >
                Delayed by {delayedBy} hour{delayedBy > 1 ? 's' : ''}
              </div>
            ) : (
              <div></div>
            )
          );          
       },
      },

      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Req. Date
          </div>
        ),
        width: 180,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'requestDate',
        Filter: () => (
          <RangePicker
            value={selectedRequestDateRange}
            showTime={{ format: 'hh:mm a' }}
            style={{
              borderRadius: '10px',
            }}
            onChange={handleRequestDateChange}
          />
        ),
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            App. Date
          </div>
        ),
        width: 180,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'appointmentDate',
        Filter: () => (
          <RangePicker
            value={selectedDateRange}
            showTime={{ format: 'hh:mm a' }}
            style={{
              borderRadius: '10px',
            }}
            onChange={handleAppointmentDateChange}
          />
        ),
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            User Name
          </div>
        ),
        width: 180,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'userName',
        filterable: true,
        Filter: <RidSearch onChange={handleUserNameChange} />,
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Center
          </div>
        ),
        width: 140,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'networkCenterName',
        filterable: true,
        Filter: <RidSearch onChange={handleCenterNameChange} />,
      },

      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Patient Name
          </div>
        ),
        width: 140,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'patientName',
        filterable: true,
        Filter: <RidSearch onChange={handlePatientNameChange} />,
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Sponsor
          </div>
        ),

        width: 126,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'sponsorName',
        Filter: () => (
          <Select
            allowClear
            showSearch
            options={sponsorList}
            onChange={handleSponsor}
            filterOption={filterOption}
            placeholder="All"
            className="sposor-filter"
            styles={{
              height: '32px',
              // Fixes the overlapping problem of the component
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                width: '170px',
              }),
            }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
          />
        ),
      },
      ...(toggleActions
        ? [
            {
              Header: 'Actions',
              numeric: false,
              disablePadding: false,
              filterable: false,
              accessor: 'actions',
              width: 135,
              style: {
                position: 'sticky',
                right: 0,
                top: 0,
                background: 'white',
                textAlign: 'center',
                zIndex: 2,
                alignContent: 'center',
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
              },
              headerStyle: {
                overflow: 'visible',
                position: 'sticky',
                right: 0,
                background: 'white',
                textAlign: 'center',
                zIndex: 2,
              },
              Cell: (row) => (
                <div
                  style={{
                    position: 'sticky',
                    right: 0,
                    background: 'white',
                    textAlign: 'center',
                    zIndex: 2,
                  }}
                >
                  <div className="flex flex-row justify-center">
                    <Actions
                      requestId={row.original.sourceId}
                      requestData={row.original}
                      onAction={onAction}
                      getConceirgeRequestData={getConceirgeRequestData}
                    />
                  </div>
                </div>
              ),
            },
          ]
        : []),
    ],
    [
      sponsorList,
      allChecked,
      toggleActions,
      selectedDateRange,
      selectedRequestDateRange,
      claimedByFilter,
      serviceTypeFilter,
      followUpFilter,
      filterRequestId,
    ]
  );

  return (
    <>
      <div
        style={{
          backgroundColor: '#F2F1F6',
          width: '100%',
        }}
      >
        <div>
          <NavBar />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            marginTop: '72px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px 20px',
              gap: '30px',
              marginBottom: '20px',
            }}
          >
            <button
              style={{
                background: allChecked && allChecked.length>0 ? '#714FFF' : '#A5A6BB',
                // borderColor: '#714FFF',
                margin: '0px',
                borderRadius: '5px',
                border: '0px',
                color: 'white',
                fontSize: '14px',
                fontWeight: '600',
                fontFamily: 'Inter, sans-serif',
                justifyContent: 'flex-start',
                padding: '12px 12px',
              }}
              onClick={bulkAssigneRequest}
            >
              Assign Request
            </button>

            <div
              style={{
                // flexGrow: 2,
                display: 'flex', // Add this line
                alignItems: 'center',
                flex: '1',
                border: '1px solid #CFC8FF',
                borderRadius: '8px',
                backgroundColor: '#FFFFFF',
                padding: '2px',
                // flexBasis: '30%',
              }}
            >
              <img
                style={{
                  padding: '10px',
                }}
                src={SearchIcon}
                alt="searchicon"
              />
              <input
                style={{
                  flex: '1',
                  border: 'none',
                  // height: '36px',
                  boxShadow: 'none',
                  fontFamily: 'Inter, sans-serif',
                  padding: '11px',
                }}
                type="text"
                value={search}
                onChange={(e) => handleChange(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSearchClick();
                  }
                }}
                placeholder="Search with a Phone Number or Email"
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                backgroundColor: '#FFFFFF',
                borderRadius: '8px',
                border: '1px solid #CFC8FF',
                // width: '30%',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',

                  fontFamily: '"Arial", sans-serif',
                }}
              >
                {loading || countLoader ? (
                  <LoadingComponent />
                ) : (
                  <div
                    style={{
                      width: 'max-content',
                      margin: 'auto',
                      padding: '5px 10px 5px 10px',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '24px',
                        marginRight: '5px',
                        fontWeight: 'bold',
                        color: '#714FFF',
                      }}
                    >
                      {totalHits}
                    </span>
                    <span
                      style={{
                        color: '#A5A6BB',
                        fontSize: '14px',
                      }}
                    >
                      Total Cases
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              <DownloadCsv params={params} />
            </div>

            <img
              onClick={toggleActionsColumn}
              src={toggleActions ? UnhideActions : ShowActions}
              alt={toggleActions ? 'Hide Actions' : 'Show Actions'}
              id="toggleActions"
            />
            <UncontrolledTooltip target={'toggleActions'}>
              {toggleActions ? 'Hide Actions' : 'Show Actions'}
            </UncontrolledTooltip>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          ></div>
          <ReactTable
            columns={columns}
            manual
            data={dataSource}
            loading={loading}
            showPaginationTop={true}
            style={tableStyle}
            showPaginationBottom={false}
            pages={pages}
            page={page}
            filterable
            pageSize={pageSize}
            selectRow={selectRow}
            className="-striped -highlight revamp-table"
            // onFetchData={() => onFetchData}
            onFetchData={(state, instance) => {
              filterData(state);
            }}
            pageSizeOptions={[
              5, 10, 20, 25, 50, 100, 200, 500, 700, 1000, 10000,
            ]}
            PaginationComponent={(props) => (
              <Pagination
                allChecked={allChecked}
                getAllOfflineRequests={getAllOfflineRequests}
                params={params}
                {...props}
                headerComponent={''}
              />
            )}
            defaultSorted={[
              {
                id: 'appointmentDate',
                desc: true,
              },
              {
                id: 'sourceId',
                desc: 'true',
              },
            ]}
          />
        </div>
        {showFetchFollowUpModal && (
        <FetchFollowUpModal
          isOpen={showFetchFollowUpModal}
          closeFetchFollowUpModal={closeFetchFollowUpModal}
          fetchFollowUpDetails={fetchFollowUpDetails}
          onComplete={onAction}
        />
      )}
        {addAssigneModalOpen && (
          <AddAssigneModal
            isModalOpen={addAssigneModalOpen}
            closeAssigneModal={closeAssigneModal}
            // sourceId={addAssigneReqId}
            allCheckedReqIds={allChecked}
            dataSource={dataSource}
            getConceirgeRequestData={getConceirgeRequestData}
            addAssigneFlag={addAssigneFlag}
          />
        )}
      </div>
    </>
  );
};

export default ConceirgeRequests;
