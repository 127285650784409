import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import ClaimRequest from '../../images/ClaimRequest.svg';
import CallIcon from '../../assets/img/brand/call_now.svg';

import MarkFollowUp from '../../images/MarkFollowUp.svg';
import ClaimRequestModal from './ClaimRequestModal';
import { callUserRequestFromConceirge, getAllConceirgeRequests } from '../../services/api/conceirgeService';
import FollowUpModal from './FollowUpModal';
import CallUserModal from './CallUserModal';

const Actions = ({ requestData, requestId, onAction, getConceirgeRequestData }) => {
  const [showClaimRequestModal, setShowClaimRequestModal] = useState(false);
  const [showClaimRequestModalRequestId, setShowClaimRequestModalRequestId] =
    useState(null);
  const [followUpDetails, setFollowUpDetails] = useState(null);
  const [showFollowUpModal, setShowFollowUpModal] = useState(false);
  const [callUserModal, setCallUserModal] = useState({
    isOpen: false,
    header: '',
    body: '',
    requestId: null,
    onSubmit: null,
    onClose: null,
    buttonLoading: false,
  });
  const [isCallModalOpen, setIsCallModalOpen] = useState(false);

  const { requestData: followUpData } = useSelector(
    (state) => state.offlineConsult
  );

  const openClaimRequestButton = (requestId) => {
    setShowClaimRequestModal(true);
    setShowClaimRequestModalRequestId(requestId);
  };

  const setAckButtonConfirmModal = (requestId) => {
    setIsCallModalOpen(true);
    setCallUserModal({
      isOpen: true,
      header: 'Call User?',
      body: 'Are you sure you want to call user?',
      requestId,
      onSubmit: submitUserAcknowledgeCall,
      onClose: () => {
        setCallUserModal({ isOpen: false });
        setIsCallModalOpen(false);
      },
    });
  };


  const submitUserAcknowledgeCall = (requestId) => {
    if (!requestId) {
      return;
    }
    const patientPhone = requestData.patientPhone;
    return callUserRequestFromConceirge(
      requestId,
      'userAcknowledge',
      patientPhone
    ).then(getConceirgeRequestData);
  };

  const submitConfirmModalGen = () => {
    const { onSubmit, requestId } = callUserModal;
    return onSubmit(requestId).then(() => {
      setCallUserModal((prevState) => ({ ...prevState, isOpen: false }));
    });
  };

  const onClaimRequestModalClose = () => {
    setShowClaimRequestModal(false);
    getConceirgeRequestData();
  };

  const openFollowUpModal = (details) => {
    console.log({ details }, 'open follow up modal');
    if (details === undefined || details === null) {
      details = followUpData;
    }
    setShowFollowUpModal(true);
    setFollowUpDetails(details);
  };

  console.log(requestData, 'sssss ');
  return (
    <>
      <div
        className="flex"
        style={{
          paddingLeft: '12px',
          flexWrap: 'wrap',
        }}
      >
        <UncontrolledTooltip target={`callButton-${requestId}`}>
          Call User
        </UncontrolledTooltip>
        <img
          id={`callButton-${requestId}`}
          onClick={() => setAckButtonConfirmModal(requestId)}
          src={CallIcon}
          alt="CallUserButton"
          className="button-img"
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
        />
        <UncontrolledTooltip target={`claimButton-${requestId}`}>
          Claim Request
        </UncontrolledTooltip>
        <img
          id={`claimButton-${requestId}`}
          onClick={() => openClaimRequestButton(requestId)}
          src={ClaimRequest}
          alt="ClaimRequestButton"
          className="button-img"
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
        />

        <img
          src={MarkFollowUp}
          alt="GrowthArrow"
          className="button-img"
          id={`markFollowUpButton-${requestId}`}
          onClick={() => openFollowUpModal(requestData)}
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
        />
        <UncontrolledTooltip target={`markFollowUpButton-${requestId}`}>
          Mark FollowUp
        </UncontrolledTooltip>
      </div>
      <div>
        <ClaimRequestModal
          onClose={onClaimRequestModalClose}
          isOpen={showClaimRequestModal}
          requestId={showClaimRequestModalRequestId}
          requestData = {requestData}
        />
      </div>
      <div>
        {showFollowUpModal && (
          <FollowUpModal
            isOpen={showFollowUpModal}
            closeFollowUpModal={() => setShowFollowUpModal(false)}
            getAllConceirgeRequests={getConceirgeRequestData}
            onComplete={onAction}
            followUpDetails={followUpDetails}
          />
        )}
      </div>
      <div>
          <CallUserModal
            modalHeader={callUserModal.header}
            onClose={callUserModal.onClose}
            isOpen={callUserModal.isOpen}
            onSubmit={submitConfirmModalGen}
            loading={callUserModal.buttonLoading}
            isCallModalOpen={isCallModalOpen}
          >
            {callUserModal.body}
          </CallUserModal>
        </div>
    </>
  );
};

export default Actions;
