import React from 'react';

const VisionAckFilter = ({ onChange, currentFilter }) => {
  const handleChange = (event) => {
    const value = event.target.value;
    const filterValue = value === '' ? null : value;
    onChange(filterValue);
  };

  return (
    <select
      onChange={handleChange}
      style={{
        width: '100%',
        height: '40px',
        fontSize: '12px',
        borderRadius: '10px',
      }}
      value={currentFilter || ''}
    >
      <option value="">All</option>
      <option value="pending">Pending</option>
      <option value="noAnswer">No Answer</option>
      <option value="completed">Yes</option>
    </select>
  );
};

export default VisionAckFilter;
