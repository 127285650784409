import baseRequest from "./baseRequest"

export const getAllCashlessVaccinationRequests = (params = {}) => {
    const url = '/cashless-vaccination/new-ops/allRequests'
    const method = 'GET'
    return baseRequest(url, method, params, null)
}

export const confirmRequest = (requestId, dateKey, slotId) => {
    const url = `/cashless-vaccination/new-ops/request/${requestId}/confirm`
    const method = 'POST'
    const data = {
        dateKey,
        slotId
    }

    return baseRequest(url, method, null, data)
}

export const cancelRequest = (requestId, reason, remarks) => {
    const url = `/cashless-vaccination/new-ops/request/${requestId}/cancel`
    const method = 'POST'
    const data = {
        cancelReason: reason,
        remarks
    }

    return baseRequest(url, method, null, data)
}

export const getPricingForRequest = (requestId) => {
    const url = `/cashless-vaccination/new-ops/pricingInfo/${requestId}`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const changePriceForRequest = (requestId, pricingData) => {
    const url = '/cashless-vaccination/new-ops/change-price'
    const method = 'POST'
    const data = {
        requestId,
        ...pricingData
    };

    return baseRequest(url, method, null, data)
}

export const callUserVaccinationRequest = (requestId, regarding) => {
    const url = `/cashless-vaccination/new-ops/request/${requestId}/call/user`;
    const method = 'POST';
    const data = {
      regarding,
    };
    return baseRequest(url, method, null, data);
};

export const getVaccinationRequest = (requestId) => {
    const url = `/cashless-vaccination/new-ops/${requestId}/details`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const requestVaccinationPayment = (requestId) => {
    const url = `/cashless-vaccination/new-ops/request-payout/${requestId}`
    const method = 'GET'
    const data = {
        requestId
    }
    return baseRequest(url, method, null, data)
}

export const claimVaccinationRequest = (requestId) => {
    if (!requestId) {
      throw new Error('No Request Id Found');
    }
    const url = `/cashless-vaccination/new-ops/request/${requestId}/claim`;
    const method = 'POST';
    return baseRequest(url, method, null, null);
};

export const shiftVaccinationRequest = (requestId) => {
    if (!requestId) {
        throw new Error('No Request Id Found');
      }
      const url = `/cashless-vaccination/new-ops/request/${requestId}/shift`;
      const method = 'POST';
      return baseRequest(url, method, null, null);
}

export const getVaccinationRequestStatus = (requestId) => {
    const url = `/cashless-vaccination/new-ops/request-status?requestId=${requestId}`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const getVaccinationRequestComments = (requestId) => {
    const url = `/cashless-vaccination/new-ops/comments/${requestId}`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const storeVaccinationComment = (requestId, comment, type = 'internal') => {
    const url = `/cashless-vaccination/new-ops/comments/${requestId}`
    const method = 'POST'
    const data = { comment, type }
    return baseRequest(url, method, null, data)
}

export const getVaccinationRequestHistory = (requestId) => {
    const url = `/cashless-vaccination/new-ops/request-history/${requestId}`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const getVaccinationTransactionHistory = (requestId) => {
    const url = `/cashless-vaccination/new-ops/transaction-history/${requestId}`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const resendCashlessLetter = (requestId) => {
    const url = `/cashless-vaccination/new-ops/resend-cashless-letter/${requestId}`
    const method = 'POST'
    return baseRequest(url, method, null, null)
}

export const getPartner = () => {
    const url = '/cashless-vaccination/new-ops/partners';
    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const getCenters = (searchValue, userId, vaccineCategoryId) => {
    console.log(searchValue, userId, vaccineCategoryId, '[getCenters]');
    const baseUrl = '/cashless-vaccination/new-ops/network-centers';
    const params = new URLSearchParams();

    if (searchValue) {
        params.append('searchKey', searchValue);
    }
    
    params.append('userId', userId);
    params.append('vaccineCategoryId', vaccineCategoryId);

    const url = `${baseUrl}?${params.toString()}`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};


export const getCategory = (searchValue, userId) => {
    const baseUrl = '/cashless-vaccination/new-ops/vaccination-category';
    const queryParams = searchValue 
        ? `?search=${encodeURIComponent(searchValue)}&userId=${encodeURIComponent(userId)}`
        : `?userId=${encodeURIComponent(userId)}`;
    
    const url = `${baseUrl}${queryParams}`;
    const method = 'GET';
    
    return baseRequest(url, method, null, null);
};


export const getSubCategory = (categoryId, userId, relativeId) => {
    const baseUrl = '/cashless-vaccination/new-ops/sub-vaccination-category';
    let url = `${baseUrl}?`;

    if (categoryId) {
        url += `categoryId=${encodeURIComponent(categoryId)}&`;
    }
    if (userId) {
        url += `userId=${encodeURIComponent(userId)}&`;
    }
    if (relativeId) {
        url += `relativeId=${encodeURIComponent(relativeId)}`;
    }

    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const getVaccinationCost = (bodyData) => {
    const url = `/cashless-vaccination/new-ops/vaccination-cost?subVaccineId=${bodyData.subVaccineId}&categoryId=${bodyData.categoryId}`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const bookNewVaccinationRequest = (bodyData) => {
    const url = `/cashless-vaccination/new-ops/book-vaccination`;
    const method = 'POST';
    return baseRequest(url, method, null, bodyData);
};

export const getVaccinationRequestSlots = () => {
    const url = `/cashless-vaccination/new-ops/get-slots`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const getWalletDeductionDetails = (networkCenterId, categoryId, subVaccineId, relativeId, userId) => {
    console.log({ categoryId, networkCenterId, relativeId, subVaccineId, userId }, '[getWalletDeductionDetails]');
    
    let url = `/cashless-vaccination/new-ops/get-wallet-deduction?networkCenterId=${networkCenterId}&categoryId=${categoryId}&subVaccineId=${subVaccineId}&userId=${userId}`;

    if (relativeId) {
        url += `&relativeId=${relativeId}`;
    }

    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const changeRequestCenter = (requestId, centerId) => {
    console.log({ requestId, centerId }, '[changeRequestCenter]');
    const url = `/cashless-vaccination/new-ops/change-center`;
    const method = 'POST';
    const data = {
        networkCenterId: centerId,
        requestId
    };

    return baseRequest(url, method, null, data);
}; 
