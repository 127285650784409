import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import axios from 'axios';
import config from '../../config.js';
import { store } from '../../store/index.js';

function OrderTrackDetailModal({ onClose, orderId }) {
    const [responseData, setResponseData] = useState(null);
    const standardizeJSON = (data, indent = 2) => {
        const isURL = (value) => {
            const urlPattern = new RegExp(
                '^(https?:\\/\\/)?' + // Protocol
                '(([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})' + // Domain name
                '(\\/[a-zA-Z0-9\\-._~:/?#[\\]@!$&\'()*+,;%=]*)?' + // Path
                '(\\?[a-zA-Z0-9\\-._~:/?#[\\]@!$&\'()*+,;%=]*)?' + // Query string
                '(#[a-zA-Z0-9\\-._~:/?#[\\]@!$&\'()*+,;%=]*)?$' // Fragment
            );
            return urlPattern.test(value);
        };

        const formatKey = (key) => {
            return key
                .replace(/_/g, ' ') // Replace underscores with spaces
                .replace(/(?:^|\s)([a-z])/g, (match, p1) => p1.toUpperCase()) // Capitalize first letter of each word
                .replace(/([a-z])([A-Z])/g, '$1 $2'); // Add space before uppercase letters
        };

        const calculatePadding = (entries) => {
            const maxKeyLength = Math.max(...entries.map(([key]) => formatKey(key).length));
            return maxKeyLength + 2; // Add 2 spaces for alignment
        };

        const format = (obj, level) => {
            if (typeof obj !== 'object' || obj === null) {
                const value = String(obj);
                if (isURL(value)) {
                    // If it's a URL, return as a clickable link
                    return `<a href="${value}" target="_blank" style="color: #0f4a11; font-weight: bold; text-decoration: underline;">${value}</a>`;
                }
                // Otherwise, format as green bold text
                return `<span style="color: #0f4a11; font-weight: bold;">${value}</span>`;
            }

            const spacing = ' '.repeat(level * indent);
            const entries = Object.entries(obj);
            const padding = calculatePadding(entries);

            const formattedEntries = entries.map(([key, value]) => {
                const formattedKey = formatKey(String(key)).padEnd(padding);
                if (typeof value === 'object' && value !== null) {
                    return `${spacing}<span style="color: #3a5071; font-weight: bold;">${formattedKey}</span>\n${format(value, level + 1)}`;
                }
                return `${spacing}<span style="color: #3a5071; font-weight: bold;">${formattedKey}</span>${format(value, level + 1)}`;
            });

            return formattedEntries.join('\n');
        };

        return format(data, 1);
    };








    useEffect(() => {
        const getOrderStatusDetails = async () => {
            try {
                const state = store.getState();
                const headers = state.user.token ? { authorization: state.user.token } : {};
                const { data } = await axios.get(
                    `${config.pharmacyService}/ops/fetch-partner-order-track-detail?orderId=${orderId}`,
                    { headers }
                );

                setResponseData(data);
            } catch (err) {
                onClose();
                let msg = 'Failed to fetch order status details.';
                if (err.response && err.response.data && err.response.data.message) {
                    msg = err.response.data.message;
                }
                alert(msg);
            }
        };

        if (orderId) {
            getOrderStatusDetails();
        } else {
            alert("Please provide both Order ID and Partner Sub Order ID.");
        }
    }, [orderId, onClose]);

    return (
        <Modal
            isOpen={true}
            style={{
                maxWidth: '1400px', // Increase width
                width: '100%',
                borderRadius: '8px',
                backgroundColor: '#f4f7fc',
                padding: '20px',
            }}
        >
            <ModalHeader
                toggle={onClose}
                style={{
                    backgroundColor: '#4B6A9B',
                    borderBottom: '2px solid #3a5071',
                }}
            >
                <h4
                    className="text-center"
                    style={{
                        color: '#fff',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                    }}
                >
                    Order Track Detail
                </h4>
            </ModalHeader>

            <ModalBody
                style={{
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                <div className="response-container" style={{ whiteSpace: 'pre-line' }}>
                    {responseData ? (
                        <pre
                            style={{
                                backgroundColor: '#f7f9fc',
                                padding: '20px',
                                borderRadius: '8px',
                                fontFamily: 'monospace',
                                fontSize: '14px',
                                color: '#333',
                                overflowX: 'auto',
                            }}
                            dangerouslySetInnerHTML={{
                                __html: standardizeJSON(responseData),
                            }}
                        />
                    ) : (
                        <div style={{ color: '#888' }}>Loading...</div>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );

}

const mapStateToProps = (state) => ({
    authToken: state.user.authToken,
    user: state.user,
});

export default connect(mapStateToProps)(OrderTrackDetailModal);
