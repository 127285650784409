import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import config from '../../config.js';
import { store } from '../../store/index.js';

const apiRequest = async (url, method = 'GET', data = null) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    const state = store.getState();
    if (state.user.token) {
        headers.Authorization = state.user.token;
    }

    try {
        const response = await axios({
            url,
            method,
            data,
            headers,
        });
        return response.data;
    } catch (err) {
        const errorMessage = err.response && err.response.data && err.response.data.message || err.message;
        throw new Error(errorMessage);
    }
};

// API calls using the helper function
const getScanAndPayCartDetailByCartId = async (cartId) => {
    return await apiRequest(`${config.pharmacyService}/scan-and-pay/fetch-cart-detail?cartId=${cartId}`);
};

const getCaseList = async (offset = 0, isVipUser = null, isCallRequested = null, isClosed = null) => {
    let url = `${config.pharmacyService}/scan-and-pay/fetch-case-list?offset=${offset}`;

    // Append query parameters only if they have values
    if (isVipUser !== -1) {
        url += `&isVipUser=${isVipUser}`;
    }
    if (isCallRequested !== -1) {
        url += `&isCallRequested=${isCallRequested}`;
    }
    if (isClosed !== -1) {
        url += `&isClosed=${isClosed}`;
    }

    return await apiRequest(url);
};

const getCallList = async (cartId) => {
    const url = `${config.pharmacyService}/scan-and-pay/fetch-call-list?cartId=${cartId}`;
    try {
        const response = await apiRequest(url);
        return response;
    } catch (err) {
        throw new Error(err.message);
    }
};


const updateCartApprovedAmount = async (cartId, approvedAmount) => {
    return await apiRequest(`${config.pharmacyService}/scan-and-pay/update-cart-approved-amount`, 'POST', { cartId, approvedAmount });
};


const enableScanAndPay = async (digitisationRequestId) => {
    return await apiRequest(`${config.pharmacyService}/scan-and-pay/enable-scan-and-pay`, 'POST', { digitisationRequestId });
};

class PharmacyScanAndPayHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartId: '',
            cartDetails: {
                items: { covered: [], uncovered: [] },
                totalAmount: null,
                requestedAmount: null,
                approvedAmount: null,
                prescriptionUrl: '',
                cartId: null,
            },
            approvedAmount: null,
            error: '',
            loading: false,
            updating: false,
            caseList: [],
            caseListLoading: true,
            callListLoading: false,
            offset: 0, // Initial offset for pagination
            limit: 10, // Number of items per page,
            isVipUser: -1,
            isCallRequested: -1,
            isClosed: -1,
            callList:[]
        };
    }

    fetchCartDetails = async (cartId) => {
        try {
            const data = await getScanAndPayCartDetailByCartId(cartId);
            if (data.success) {
                this.setState({
                    cartDetails: data.cart,
                    loading: false
                });
            } else {
                this.setState({
                    error: data.message,
                    loading: false
                });
            }
        } catch (err) {
            this.setState({ error: err.message, loading: false });
        }
        this.setState({ cartId: cartId, approvedAmount: null, callList: [] });
    };

    getCaseList = async () => {
        this.setState({ caseListLoading: true });
        const { isCallRequested, isClosed, isVipUser, offset } = this.state;
        try {
            const data = await getCaseList(offset, isVipUser, isCallRequested, isClosed); 
            this.setState({ caseList: data.caseList });
        } catch (err) {
            this.setState({ error: err.message });
        } finally {
            this.setState({ caseListLoading: false });
        }
    };


    getCallList = async () => {
        this.setState({ callListLoading: true });
        try {
            const { cartId } = this.state;
            if (Number(cartId)) {
                const data = await getCallList(cartId);
                this.setState({ callList: data.callList });
            } else { 
                this.setState({ error: 'Choose Cart Id First' });
            }
        } catch (err) {
            this.setState({ error: err.message });
        } finally {
            this.setState({ callListLoading: false });
        }
    };
    

    handlePrevPage = () => {
        this.setState((prevState) => {
            const newOffset = Math.max(prevState.offset - 1, 0); // Ensure offset doesn't go below 0
            return { offset: newOffset };
        }, () => {
            this.getCaseList(this.state.offset);
        });
    };

    handleNextPage = () => {
        this.setState((prevState) => {
            const newOffset = prevState.offset + 1;
            return { offset: newOffset };
        }, () => {
            this.getCaseList(this.state.offset);
        });
    };

    // Handle input change for filters
    handleFilterChange = (filterType, value) => {
        this.setState({ [filterType]: value });
    };

    // Handle input change for cartId
    handleCartIdChange = (event) => {
        this.setState({ cartId: event.target.value });
    };

    // Handle input change for digitisationRequestId
    handleDigitisationRequestIdChange = (event) => {
        this.setState({ digitisationRequestId: event.target.value });
    };

    // Handle submit for fetching cart details
    handleCartIdSubmit = () => {
        const { cartId } = this.state;
        if (cartId) {
            this.fetchCartDetails(cartId);
        }
    };

    handleEnableScanAndPay = async () => { 
        const { digitisationRequestId } = this.state;

        if (!digitisationRequestId) {
            this.setState({ error: "Please enter a valid digitisationRequestId." });
            return;
        }

        this.setState({ updating: true, error: '' });

        try {
            const response = await enableScanAndPay(this.state.digitisationRequestId);
            if (response.success) {
                this.setState({ updating: false });
            } else {
                this.setState({ error: response.message, updating: false });
            }
            this.setState({ scanAndPayEnabledUpdatedMessage: `Attempt ${response.isUpdated ? 'Success' : 'Failed'} For Digitisation Request Id: ${this.state.digitisationRequestId}` });
        } catch (err) {
            this.setState({ error: err.message, updating: false });
        }
    }

    handleUpdateApprovedAmount = async () => {
        const { approvedAmount } = this.state;

        if (!approvedAmount || approvedAmount <= 0) {
            this.setState({ error: "Please enter a valid approved amount." });
            return;
        }

        this.setState({ updating: true, error: '' });

        try {
            const response = await updateCartApprovedAmount(this.state.cartId, approvedAmount);
            if (response.success) {
                // Fetch the updated cart details
                await this.fetchCartDetails(this.state.cartId);
                this.setState({ updating: false });
            } else {
                this.setState({ error: response.message, updating: false });
            }
        } catch (err) {
            this.setState({ error: err.message, updating: false });
        }
        this.setState({ approvedAmount: null });
    };


    componentDidMount() {
        this.getCaseList(this.state.offset);
    }

    render() {
        const {
            cartId,
            cartDetails,
            approvedAmount,
            error,
            loading,
            updating,
            caseList,
            caseListLoading,
            callListLoading,
            offset,
            isVipUser,
            isCallRequested,
            isClosed,
            callList,
            digitisationRequestId,
            scanAndPayEnabledUpdatedMessage
        } = this.state;

        return (
            <section style={{ width: '100%', padding: '20px 40px' }}>
                {<h3 className="text-center"> Scan And Pay </h3>}
                {error && <p className="text-danger text-center">{error}</p>}
                <hr />

                {/* Number input with button */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '20px'
                    }}
                >
                    <div
                        style={{
                            width: '500px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '20px'
                        }}
                    >
                        <input
                            type="number"
                            value={digitisationRequestId || ''}
                            onChange={this.handleDigitisationRequestIdChange}
                            placeholder="Digitisation Id"
                            className="form-control"
                            style={{
                                width: '300px',
                                flex: 1,
                                marginRight: '10px'
                            }}
                        />
                        <button
                            onClick={this.handleEnableScanAndPay}
                            className="btn btn-primary"
                            style={{
                                flexShrink: 0
                            }}
                        >
                            Enable
                        </button>
                    </div>
                    {scanAndPayEnabledUpdatedMessage && (
                        <p
                            className="text-su text-center"
                            style={{ marginLeft: '10px' }}
                        >
                            {scanAndPayEnabledUpdatedMessage}
                        </p>
                    )}
                </div>
                
                {/* Filters for fetching case list */}
                <div className="filters text-center">
                    <h4>Filters</h4>
                    <div className="filter-row">
                        <label>
                            VIP User:
                            <select
                                value={isVipUser}
                                onChange={(e) => this.handleFilterChange('isVipUser', e.target.value)}
                                className="form-control"
                            >
                                <option value={-1}>All</option>
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                            </select>
                        </label>
                        <label>
                            Call Requested:
                            <select
                                value={isCallRequested}
                                onChange={(e) => this.handleFilterChange('isCallRequested', e.target.value)}
                                className="form-control"
                            >
                                <option value={-1} >All</option>
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                            </select>
                        </label>
                        <label>
                            Closed:
                            <select
                                value={isClosed}
                                onChange={(e) => this.handleFilterChange('isClosed', e.target.value)}
                                className="form-control"
                            >
                                <option value={-1}>All</option>
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                            </select>
                        </label>
                    </div>
                    <button
                        className="btn btn-warning"
                        onClick={(e) => { 
                            this.setState({offset: 0});
                            return this.getCaseList();
                        }}
                    >
                        Apply Filter
                    </button>
                </div>

                {/* Case List Section */}
                <div className="call-list mt-4">
                    {caseListLoading ? (
                        <div className="text-center">
                            <Spinner />
                        </div>
                    ) : (
                        <div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Cart ID</th>
                                        <th>User Name</th>
                                        <th>User Phone</th>
                                        <th>VIP User</th>
                                        <th>Call Requested</th>
                                        <th>Payment Made</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {caseList.map((call, index) => (
                                        <tr key={index}>
                                            <td>
                                                <button
                                                    className="btn btn-link p-0"
                                                    onClick={() => this.fetchCartDetails(call.cartId)}
                                                    title="Fetch Cart Details"
                                                >
                                                    {call.cartId}
                                                </button>
                                            </td>
                                            <td>{call.userName}</td>
                                            <td>{call.userPhone}</td>
                                            <td>{call.isVipUser ? 'Yes' : 'No'}</td>
                                            <td>{call.isCallRequested ? 'Yes' : 'No'}</td>
                                            <td>{call.isClosed ? 'Yes' : 'No'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {/* Pagination buttons */}
                            <div className="pagination-controls text-center">
                                <button
                                    onClick={this.handlePrevPage}
                                    disabled={offset <= 0}
                                    className="btn btn-secondary"
                                >
                                    Previous
                                </button>

                                {/* Display current page number */}
                                <span className="mx-2">
                                    Page {Math.floor(offset) + 1}
                                </span>

                                <button
                                    onClick={this.handleNextPage}
                                    className="btn btn-secondary"
                                >
                                    Next
                                </button>
                            </div>

                        </div>
                    )}
                </div>

                <button
                    className="btn btn-primary"
                    onClick={this.getCallList}
                >
                    Fetch Call List
                </button>

                {/* Call List Section */}
                <div className="call-list mt-4">
                    {callListLoading ? (
                        <div className="text-center">
                            <Spinner />
                        </div>
                    ) : (
                        <div>
                            {/* Scrollable table body */}
                            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Ops User Name</th>
                                                <th>Ops User Phone</th>
                                                <th>Call Status</th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                                <th>Duration</th>
                                            </tr>
                                        </thead>
                                    <tbody>
                                        {callList.map((call, index) => (
                                            <tr key={index}>
                                                <td>{call.opsUserName}</td>
                                                <td>{call.opsUserPhone}</td>
                                                <td>{call.callStatus}</td>
                                                <td>{call.startTime}</td>
                                                <td>{call.endTime}</td>
                                                <td>{call.duration}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>

                <hr />
                <div className="cart-details-container" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    {/* Cart ID Input Section */}
                    <div
                        className="cart-id-input"
                        style={{
                            width: '48%',
                            border: '1px solid #ddd',
                            padding: '15px',
                            borderRadius: '5px',
                            backgroundColor: '#f9f9f9',
                        }}
                    >
                        <h3>Enter Cart ID</h3>
                        <input
                            type="number"
                            value={cartId}
                            onChange={this.handleCartIdChange}
                            placeholder="Enter Cart ID"
                            className="form-control"
                            style={{ marginBottom: '10px' }}
                        />
                        <button
                            onClick={this.handleCartIdSubmit}
                            className="btn btn-primary"
                            style={{ width: '100%' }}
                        >
                            Fetch Cart Details
                        </button>
                    </div>

                    {/* Update Approved Amount Section */}
                    <div
                        className="update-amount-section"
                        style={{
                            width: '48%',
                            border: '1px solid #ddd',
                            padding: '15px',
                            borderRadius: '5px',
                            backgroundColor: '#f9f9f9',
                        }}
                    >
                        <h4>Update Approved Amount</h4>
                        <div className="form-group">
                            <input
                                type="number"
                                value={approvedAmount}
                                onChange={(e) => this.setState({ approvedAmount: e.target.value })}
                                placeholder="Enter new approved amount"
                                className="form-control"
                                style={{ marginBottom: '10px' }}
                            />
                        </div>
                        <button
                            onClick={this.handleUpdateApprovedAmount}
                            className="btn btn-primary"
                            style={{ width: '100%' }}
                            disabled={updating}
                        >
                            {updating ? <Spinner size="sm" /> : "Update Amount"}
                        </button>
                    </div>
                </div>

                <hr />

                {/* Cart Details Section */}
                <div className="cart-details mt-4">
                    <h3>Cart Details</h3>
                    {loading ? (
                        <div className="text-center">
                            <Spinner />
                        </div>
                    ) : (
                        <div className="cart-info">
                            <p><strong>Cart ID:</strong> {cartDetails.cartId || cartId}</p>
                            <p><strong>Total Amount:</strong> ₹{cartDetails.totalAmount}</p>
                            <p><strong>Requested Amount:</strong> ₹{cartDetails.requestedAmount}</p>
                            <p><strong>Approved Amount:</strong> ₹{cartDetails.approvedAmount}</p>
                            <p><strong>Prescription URL:</strong> <a href={cartDetails.prescriptionUrl} target="_blank" rel="noopener noreferrer">View Prescription</a></p>
                            <hr />

                                <div className="cart-items" style={{ marginTop: '20px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {/* Covered Items */}
                                        <div
                                            style={{
                                                width: '48%',
                                                border: '1px solid #ddd',
                                                padding: '15px',
                                                borderRadius: '5px',
                                                backgroundColor: '#f9f9f9',
                                            }}
                                        >
                                            <h4>Covered Items</h4>
                                            {cartDetails.items.covered && cartDetails.items.covered.length > 0 ? (
                                                <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                    {cartDetails.items.covered.map((item, index) => (
                                                        <li key={index} style={{ marginBottom: '5px' }}>
                                                            {item.name} (₹{item.mrp} x {item.quantity} pcs)
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No covered items.</p>
                                            )}
                                        </div>

                                        {/* Uncovered Items */}
                                        <div
                                            style={{
                                                width: '48%',
                                                border: '1px solid #ddd',
                                                padding: '15px',
                                                borderRadius: '5px',
                                                backgroundColor: '#f9f9f9',
                                            }}
                                        >
                                            <h4>Uncovered Items</h4>
                                            {cartDetails.items.uncovered && cartDetails.items.uncovered.length > 0 ? (
                                                <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                    {cartDetails.items.uncovered.map((item, index) => (
                                                        <li key={index} style={{ marginBottom: '5px' }}>
                                                            {item.name} (₹{item.mrp} x {item.quantity} pcs)
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No uncovered items.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <hr />
                        </div>
                    )}
                </div>

                <hr />
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    authToken: state.user.authToken,
    user: state.user,
});

export default connect(mapStateToProps)(PharmacyScanAndPayHome);
