import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { TimePicker } from 'antd';
import moment from 'moment';
import { toast } from 'react-toastify';
import LoadingComponent from '../../components/common/LoadingComponent';
import { markFollowUpForConceirge } from '../../services/api/conceirgeService';

const FollowUpModal = (props) => {
  const {
    isOpen,
    closeFollowUpModal,
    fetchTableData,
    followUpDetails,
    onComplete,
  } = props;
  const [sourceId, setRequestId] = useState('');
  const [appDate, setAppDate] = useState('');
  const [followUpTime, setFollowUpTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);

  // Options for the dropdown
  const reasonOptions = [
    { value: 'Scheduling Request', label: 'Scheduling Request' },
    { value: 'Appointment Rescheduling', label: 'Appointment Rescheduling' },
    { value: 'Appointment Cancellation', label: 'Appointment Cancellation' },
    { value: 'Appointment Confirmation', label: 'Appointment Confirmation' },
    { value: 'Request for Additional Information', label: 'Request for Additional Information' },
    { value: 'Process Clarification', label: 'Process Clarification' },
    { value: 'To inform alternative Options Available', label: 'To inform alternative Options Available' },
    { value: 'User Advised to Call Back', label: 'User Advised to Call Back' },
    { value: 'An update', label: 'An update' },
  ];

  const modalStyles = {
    header: {
      color: '#6c5ce7',
      fontWeight: 'bold',
      fontSize: '1.5rem',
      // borderRadius:'10px',
      borderBottom: '1px solid black',
    },
    body: {
      padding: '1.5rem',
      fontFamily: 'Arial, sans-serif',
      paddingBottom:'none'
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem 1.5rem',
      borderTop: 'none',
    },
    label: {
      fontWeight: 550,
      color: '#2d3436',
      fontSize: '1rem',
    },
    input: {
      border: '1px solid #dfe6e9',
      borderRadius: '6px',
      padding: '0.5rem',
      fontSize: '1rem',
    },
    buttonConfirm: {
      backgroundColor: 'rgb(113, 79, 255)',
      width: '40%',
      borderColor: 'rgb(113, 79, 255)',
      height: '44px',
      color: '#fff',
    },
    buttonClose: {
      backgroundColor: '#fff',
      width: '40%',
      borderColor: 'rgb(113, 79, 255)',
      color: 'rgb(113, 79, 255)',
      height: '44px',
    },
  };

  const handleReasonChange = (selectedOption) => {
    setSelectedReason(selectedOption);
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    if (!selectedDate) {
      return;
    }
    const currentDate = moment().format('YYYY-MM-DD');

    if (selectedDate >= currentDate) {
      setAppDate(selectedDate);
    } else {
      toast.warning('Please select a date equal to or after the current date.');
    }
  };

  const disabledTime = (current, type) => {
    const currentMoment = moment();
    const isToday = current && current.isSame(appDate, 'day');
    if (type === 'hour' && currentMoment && isToday && appDate) {
      // Disable hours before the current hour
      return {
        disabledHours: () => [...Array(currentMoment.hours()).keys()],
      };
    }

    if (type === 'minute' && currentMoment && isToday && appDate) {
      // Disable minutes before the current minute
      return {
        disabledMinutes: () => [...Array(currentMoment.minutes()).keys()],
      };
    }

    return {};
  };
  console.log(followUpDetails,'followowowowowo')

  const handleFollowUp = () => {
    setLoading(true);
    if(!selectedReason){
      toast.error('Please Select Follow Up Reason');
      return ;
    }
    if(!appDate){
      toast.error('Please Select Follow Up Date');
      return ;
    }
    if(!followUpTime){
      toast.error('Please Select Follow Up Time');
      return ;
    }
    const { conceirgeOrderId } = followUpDetails;
    try {
      return markFollowUpForConceirge(conceirgeOrderId, selectedReason.value, appDate, followUpTime)
        .then((res) => {
          if (res.message === 'success') {
            toast.success(`Success`);
            setLoading(false);
            closeFollowUpModal();
            onComplete();
          } else {
            const err = new Error('Invalid response');
            err.data = res;
            throw err;
          }
        })
        .catch((err) => {
          console.log({ err, response: err.data });
          setLoading(false);
          toast.error(`Failure! ${err.data.errorMessage}`);
        });
    } catch (e) {
      toast.error(`Failure!`);
      console.log({ e });
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('Follow Up Details are... before setting' + followUpDetails);
    if (followUpDetails) {
      console.log(followUpDetails);
      setRequestId(followUpDetails.sourceId);
    }
  }, [followUpDetails]);
  return (
    <Modal
      centered
      isOpen={isOpen}
      onCancel={closeFollowUpModal}
      toggle={closeFollowUpModal}
    >
          <ModalHeader className="revamp-modal-header"  toggle={closeFollowUpModal}>
          Schedule Follow-up with the user ({followUpDetails?.sourceId || ''})
   </ModalHeader>
      <hr/>
      <ModalBody style={modalStyles.body}>
        <form>
          <FormGroup>
            <Row>
              <Col className="col-4" style={{ fontWeight: 550 }}>
                <Label>Reason</Label>
              </Col>
              <Col>
                <Select
                  options={reasonOptions}
                  value={selectedReason}
                  onChange={handleReasonChange}
                  placeholder="Select a reason"
                  isClearable
                  classNamePrefix="react-select"
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-4" style={modalStyles.label}>
                <Label>Date</Label>
              </Col>
              <Col>
                <input
                  className="form-control"
                  style={modalStyles.input}
                  type="date"
                  value={appDate}
                  onChange={handleDateChange}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-4" style={modalStyles.label}>
                <Label>Follow Up Timings</Label>
              </Col>
              <Col>
                <TimePicker
                  className="w-full"
                  disabledTime={(current) => disabledTime(current, 'hour')}
                  onChange={(_, date) => {
                    setFollowUpTime(date);
                  }}
                  format="hh:mm:00 A"
                  defaultValue={
                    followUpTime
                      ? moment(followUpTime, 'hh:mm:00 A')
                      : undefined
                  }
                />
              </Col>
            </Row>
          </FormGroup>
        </form>
      </ModalBody>
      <ModalFooter style={modalStyles.footer}>
        {loading ? <LoadingComponent color={'black'} /> : <></>}

        <Button
          style={modalStyles.buttonConfirm}
          disabled={loading || !followUpTime || !appDate}
          onClick={() => handleFollowUp()}
        >
          Confirm
        </Button>
        <Button style={modalStyles.buttonClose} onClick={closeFollowUpModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FollowUpModal;
