export const defaultState = {
    requestId: null,
    requestDetails: null,
    showRequestDetails: false,
    showUploadModal: false,
    showOrderModal: false,
    orderData: null,
    visionRequestId: null,
    orderDetailsData: {
        basicDetails: {},
        visionCardFiles: [],
        invoiceFiles: []
    }
}

export default function CashlessVision(state = defaultState, action) {
    switch (action.type) {
        case "SHOW_VISION_REQUEST_MODAL": {
            const showRequestDetails = action.payload.showRequestDetails;
            const requestId = action.payload.requestId;
            return Object.assign({}, state, {
                requestId,
                showRequestDetails
            })
        }
        case "HIDE_VISION_REQUEST_MODAL": {
            const showRequestDetails = action.payload.showRequestDetails;
            const requestId = action.payload.requestId;
            return Object.assign({}, state, {
                requestId,
                showRequestDetails
            })
        }
        case "SHOW_VISION_UPLOAD_MODAL": {
            const requestId = action.payload.requestId || localStorage.getItem('visionRequestId');
            return {
                ...state,
                showUploadModal: true,
                requestId
            }
        }
        case "HIDE_VISION_UPLOAD_MODAL": {
            return {
                ...state,
                showUploadModal: false,
                requestId: state.requestId
            }
        }
        case "SHOW_VISION_ORDER_MODAL": {
            const requestId = action.payload.requestId;
            return {
                ...state,
                showOrderModal: true,
                requestId,
                orderData: action.payload.orderData
            }
        }
        case "HIDE_VISION_ORDER_MODAL": {
            return {
                ...state,
                showOrderModal: false,
                requestId: state.requestId,
                orderData: null
            }
        }
        case 'SET_VISION_REQUEST_ID':
            return {
                ...state,
                visionRequestId: action.payload
            };
        case 'SET_ORDER_DETAILS_DATA':
            return {
                ...state,
                orderDetailsData: {
                    ...state.orderDetailsData,
                    ...action.payload,
                    visionCardFiles: action.payload.visionCardFiles || state.orderDetailsData.visionCardFiles,
                    invoiceFiles: action.payload.invoiceFiles || state.orderDetailsData.invoiceFiles
                }
            };
        case 'CLEAR_ORDER_DETAILS_DATA':
            return {
                ...state,
                orderDetailsData: defaultState.orderDetailsData,
                visionRequestId: null
            };
        default:
            return state
    }
}