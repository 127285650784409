import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const EyeDetails = ({ eye, formData, handleEyeDataChange, isEditingDisabled }) => {
  const defaultEyeData = {
    spherical: { d: '', n: '' },
    cylindrical: { d: '', n: '' },
    va: { d: '', n: '' },
    prism: { d: '', n: '' },
    base: { d: '', n: '' },
    pd: '',
    axis: '',
  };
  const getEyeType = (eye) => eye.toLowerCase() === 'left' ? 'leftEye' : 'rightEye';

  const eyeData = eye === 'Left' ? (formData.leftEye || defaultEyeData) : (formData.rightEye || defaultEyeData);

  return (
    <div>
      <h6 style={{ 
        fontSize: '14px',
        fontWeight: '600',
        marginBottom: '15px'
      }}>{eye} Eye</h6>
      <div style={{
        backgroundColor: '#F0F3F6',
        borderRadius: '8px',
        padding: '20px'
      }}>
        <div style={{ marginBottom: '10px', display: 'flex' }}>
          <div style={{ width: '50px' }}></div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '20%' }}>Spherical</div>
            <div style={{ width: '20%' }}>Cylindrical</div>
            <div style={{ width: '20%' }}>V/A</div>
            <div style={{ width: '20%' }}>Prism</div>
            <div style={{ width: '20%' }}>Base</div>
          </div>
        </div>

        {['d', 'n'].map((row) => (
          <div key={row} style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
            <div style={{ width: '50px', fontWeight: '500' }}>{row.toUpperCase()}</div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
              <Input 
                value={eyeData.spherical?.[row] || ''}
                onChange={(e) => handleEyeDataChange(getEyeType(eye), 'spherical', row, e.target.value)}
                style={{
                  width: '20%',
                  backgroundColor: isEditingDisabled() ? '#F5F5F5' : '#FFFFFF',
                  borderRadius: '8px',
                  textAlign: 'center',
                  border: '1px solid #E0E0E0'
                }}
                disabled={isEditingDisabled()}
              />
              <Input 
                value={eyeData.cylindrical?.[row] || ''}
                onChange={(e) => handleEyeDataChange(getEyeType(eye), 'cylindrical', row, e.target.value)}
                style={{
                  width: '20%',
                  backgroundColor: isEditingDisabled() ? '#F5F5F5' : '#FFFFFF',
                  borderRadius: '8px',
                  textAlign: 'center',
                  border: '1px solid #E0E0E0'
                }}
                disabled={isEditingDisabled()}
              />
              <Input 
                value={eyeData.va?.[row] || ''}
                onChange={(e) => handleEyeDataChange(getEyeType(eye), 'va', row, e.target.value)}
                style={{
                  width: '20%',
                  backgroundColor: isEditingDisabled() ? '#F5F5F5' : '#FFFFFF',
                  borderRadius: '8px',
                  textAlign: 'center',
                  border: '1px solid #E0E0E0'
                }}
                disabled={isEditingDisabled()}
              />
              <Input 
                value={eyeData.prism?.[row] || ''}
                onChange={(e) => handleEyeDataChange(getEyeType(eye), 'prism', row, e.target.value)}
                style={{
                  width: '20%',
                  backgroundColor: isEditingDisabled() ? '#F5F5F5' : '#FFFFFF',
                  borderRadius: '8px',
                  textAlign: 'center',
                  border: '1px solid #E0E0E0'
                }}
                disabled={isEditingDisabled()}
              />
              <Input 
                value={eyeData.base?.[row] || ''}
                onChange={(e) => handleEyeDataChange(getEyeType(eye), 'base', row, e.target.value)}
                style={{
                  width: '20%',
                  backgroundColor: isEditingDisabled() ? '#F5F5F5' : '#FFFFFF',
                  borderRadius: '8px',
                  textAlign: 'center',
                  border: '1px solid #E0E0E0'
                }}
                disabled={isEditingDisabled()}
              />
            </div>
          </div>
        ))}


        <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
          <div style={{ width: '50px', fontWeight: '500' }}>PD</div>
          <div style={{ flex: 1 }}>
            <Input 
              value={eyeData?.pd || ''}
              onChange={(e) => handleEyeDataChange(getEyeType(eye), 'pd', '', e.target.value)}
              style={{
                backgroundColor: isEditingDisabled() ? '#F5F5F5' : '#FFFFFF',
                borderRadius: '8px',
                textAlign: 'center',
                border: '1px solid #E0E0E0'
              }}
              disabled={isEditingDisabled()}
            />
          </div>
        </div>
    
        <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
          <div style={{ width: '50px', fontWeight: '500' }}>Axis</div>
          <div style={{ flex: 1 }}>
            <Input 
              value={eyeData?.axis || ''}
              onChange={(e) => handleEyeDataChange(getEyeType(eye), 'axis', '', e.target.value)}
              style={{
                backgroundColor: isEditingDisabled() ? '#F5F5F5' : '#FFFFFF',
                borderRadius: '8px',
                textAlign: 'center',
                border: '1px solid #E0E0E0'
              }}
              disabled={isEditingDisabled()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EyeDetails.propTypes = {
  eye: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  handleEyeDataChange: PropTypes.func.isRequired,
  isEditingDisabled: PropTypes.func.isRequired,
};

export default EyeDetails;