import React, { Component } from 'react';
import {
  Container,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { FaPhone, FaSpinner } from 'react-icons/fa';
import LoadingComponent from '../common/LoadingComponent';
import ResultToast from '../revamp-offline-ops/ResultToast';
import { BoldText } from '../common/Text';
import { callUserVisionRequest, fetchVisionRequestDetails, getVisionRequestComments } from '../../services/api/cashless-vision';
import CommentList from './visionCommentList';
import CommentForm from './visionCommentForm';

const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
`;

const SectionHeader = styled(BoldText)`
  font-size: 1.2rem;
  color: #4b4f5b;
`;

const SectionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0.25rem;
`;

const DetailName = styled.div`
  font-size: 1rem;
  width: ${(props) => (props.fullWidth ? '20rem' : '8rem')};
  color: #4c4c4c;
`;

const DetailText = styled.div`
  flex: 1;
  font-size: 1rem;
  color: ${(props) => props.color || '#787778'};
  margin-left: 0.25rem;
`;

const DetailTextDanger = styled.div`
  font-size: 1rem;
  color: #ff4d48;
`;

const CallUserText = styled.div`
  font-size: 1rem;
  margin-left: 0.4rem;
  color: #56c02c;
  cursor: pointer;
`;

class RequestDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callUserLoading: false,
      showToast: false,
      callRequest: false,
      loading: false,
      details: {},
      requestDetails: {},
      preAuthItemDetails: [],
      comments: [],
      showConfirmationModal: false,
      activeInfo: 'basic',
      selectedCallReason: null,
      isCallModalOpen: false,
      callReasonOptions: [
        'Reminder for copayment',
        'Reminder to upload vision card',
        'Issues in ordered items/ prices',
        'Issues in invoice details',
        'An update',
      ],
    };

    this.addComment = this.addComment.bind(this);
  }

  componentDidMount() {
    this.getCashlessVisionRequest();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.requestDetails !== this.state.requestDetails) {
      this.props.sendDataToParent(this.state.requestDetails);
    }
  };
  
  getCashlessVisionRequest = () => {
    const { requestId } = this.props.cashlessVision;
    let resBody = {};
    fetchVisionRequestDetails(requestId)
      .then((responseBody) => {
        resBody.details = responseBody;
        const {
          requestDetails,
          preAuthItemDetails,
        } = responseBody;
        console.log(
          {
            requestDetails,
            preAuthItemDetails,
          },
          'get offline request response'
        );
        this.setState({
          requestDetails,
          preAuthItemDetails,
        });
      })
      .then(() => getVisionRequestComments(requestId))
      .then(this.handleComments)
      .then((comments) => {
        console.log(comments);
        this.setState({
          details: resBody.details,
          comments,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleComments = (response) => {
    const comments = [];
    if (response.message === 'success' && response.data.length) {
      response.data.forEach((c) => {
        comments.push({
          name: c.userName,
          message: c.comment,
          time: c.createdAt,
        });
      });
    }
    return comments;
  };

  addComment(comment) {
    this.setState({
      loading: false,
      comments: [...this.state.comments, comment],
    });
  }

  initiateCall = () => {
    this.setState({
      callUserLoading: true,
      showConfirmationModal: true,
      isCallModalOpen: true,
    });
  };

  handleCallReasonChange = (event) => {
    const { value } = event.target;
    this.setState({ 
      selectedCallReason: value,
      callUserLoading: false // Reset loading state when reason changes
    });
  };

  submitCall = async () => {
    const { requestId } = this.props.cashlessVision;
    const { selectedCallReason } = this.state;

    if (!selectedCallReason) {
      toast.error('Please select a reason for calling');
      return;
    }

    try {
      this.setState({ callUserLoading: true });
      await callUserVisionRequest(requestId, selectedCallReason);
      toast.success('Call initiated successfully');
      this.setState({
        showConfirmationModal: false,
        isCallModalOpen: false,
        selectedCallReason: null,
        callUserLoading: false
      });
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to initiate call');
      this.setState({ callUserLoading: false });
    }
  };

  showSuccessToast(successToastMessage) {
    this.setState(
      {
        successToastMessage: successToastMessage,
        callRequest: true,
        showToast: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showToast: false,
            callRequest: false,
          });
        }, 3000);
      }
    );
  }

  showFailureToast(errorDesc) {
    this.setState(
      {
        failRequest: true,
        showToast: true,
        errorDesc,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showToast: false,
            failRequest: true,
            errorDesc: null,
          });
        }, 3000);
      }
    );
  }

  toggleToast = () => {
    this.setState({
      showToast: false,
    });
  };

  render() {
    const {
      callUserLoading,
      activeInfo,
      requestDetails,
      comments,
      loading,
      showConfirmationModal,
      isCallModalOpen,
      callReasonOptions,
      selectedCallReason
    } = this.state;

    const { requestId } = this.props.cashlessVision;

    const renderBasicInfo = () => (
      <DetailsContainer
        style={{
          gap: '60px',
        }}
      >
        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader
              style={{
                borderBottom: '1px solid #F2F1F6',
                padding: '8px',
              }}
            >
              User Booking Details
            </SectionHeader>
            <SectionBody
              style={{
                paddingTop: '8px',
              }}
            >
              <DetailRow>
                <DetailName>User Id:</DetailName>
                <DetailText>
                  {requestDetails.userId || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Request Id:</DetailName>
                <DetailText>
                  {requestDetails.requestId || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Lenskart Order Id:</DetailName>
                <DetailText>
                  {requestDetails.lenkskartOrderId || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Order Date:</DetailName>
                <DetailText>
                  {requestDetails.orderDate || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Vision Card Date:</DetailName>
                <DetailText>
                  {requestDetails.visionCardDate || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Patient Name:</DetailName>
                <DetailText>
                  {requestDetails.patientName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Name:</DetailName>
                <DetailText>
                  {requestDetails.userName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>User Email:</DetailName>
                <DetailText>
                  {requestDetails.userEmail || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>User Phone:</DetailName>
                {requestDetails.userPhone ? (
                  <CallUserText onClick={this.initiateCall}>
                    {callUserLoading ? (
                      <FaSpinner
                        size={15}
                        style={{ paddingBottom: 2 }}
                        className="icon-spin text-success"
                      />
                    ) : (
                      <FaPhone
                        size={15}
                        style={{ paddingBottom: 2 }}
                        className="text-success"
                      />
                    )}
                    {'  '}
                    {requestDetails.userPhone}
                  </CallUserText>
                ) : (
                  <DetailTextDanger>Unavailable</DetailTextDanger>
                )}
              </DetailRow>
            </SectionBody>
          </SectionInnerContainer>
        </SectionContainer>
      </DetailsContainer>
    );

    if (!requestDetails || !requestId) {
      return <LoadingComponent color="black" />;
    }
    if (requestDetails || requestId) {
      return (
        <Container fluid={true}>
          <div className="row">
            <div className="col-12 text-center justify-content-center">
              <ResultToast
                callRequest={this.state.callRequest}
                failRequests={this.state.failRequest}
                successToastMessage={this.state.successToastMessage}
                errorDesc={this.state.errorDesc}
                show={this.state.showToast}
                onToggle={this.toggleToast}
              />
            </div>
          </div>
          <div className="row">
            <ButtonGroup>
              <Button
                style={{
                  width: '144px',
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  backgroundColor: '#FFFFFF',
                  color: activeInfo === 'basic' ? '#714FFF' : '#A5A6BB',
                  boxShadow: 'none',
                  fontFamily: 'Inter, sans-serif',
                  fontWeight: '700',
                  margin: '0px',
                  borderBottom:
                    activeInfo === 'basic'
                      ? '2px solid #714FFF'
                      : '2px solid #EBEAF5',
                }}
                onClick={() => this.setState({ activeInfo: 'basic' })}
                active={activeInfo === 'basic'}
              >
                Basic Info
              </Button>
            </ButtonGroup>
          </div>
          <div className="row">
            <div className="col-md-8">
              {activeInfo === 'basic' && renderBasicInfo()}
            </div>
            <div
                className={'mt-2'}
                style={{
                  backgroundColor: '#F4F1FF',
                  padding: '10px',
                  borderRadius: '12px',
                  height: '70%',
                }}
              >
                <CommentList loading={loading} comments={comments} />
                <div className="">
                  <CommentForm
                    addComment={this.addComment}
                    requestId={requestId}
                  />
                </div>
              </div>
            
          </div>
          <Modal 
            isOpen={showConfirmationModal} 
            toggle={() => this.setState({ showConfirmationModal: false, isCallModalOpen: false })}
            centered
          >
            <ModalHeader 
              toggle={() => this.setState({ showConfirmationModal: false, isCallModalOpen: false })}
              style={{
                borderBottom: '1px solid #E0E0E0',
                padding: '15px 20px'
              }}
            >
              <span style={{ 
                color: '#714FFF', 
                fontSize: '16px', 
                fontWeight: '600',
                fontFamily: 'Inter, sans-serif'
              }}>
                Call User
              </span>
            </ModalHeader>
            <ModalBody style={{ padding: '20px' }}>
              {isCallModalOpen && (
                <FormGroup>
                  <Label 
                    style={{ 
                      color: '#0F0B28', 
                      fontSize: '14px',
                      fontWeight: '500',
                      fontFamily: 'Inter, sans-serif',
                      marginBottom: '8px'
                    }}
                  >
                    Select Reason for Call
                  </Label>
                  <Input
                    type="select"
                    value={selectedCallReason || ''}
                    onChange={this.handleCallReasonChange}
                    style={{
                      borderRadius: '8px',
                      borderColor: selectedCallReason ? '#714FFF' : '#E0E0E0',
                      color: selectedCallReason ? '#714FFF' : '#495057',
                      padding: '8px 12px',
                      fontSize: '14px',
                      fontFamily: 'Inter, sans-serif'
                    }}
                  >
                    <option value="">Select a reason</option>
                    {callReasonOptions.map((reason, index) => (
                      <option 
                        key={index} 
                        value={reason}
                        style={{
                          color: '#495057',
                          padding: '8px'
                        }}
                      >
                        {reason}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              )}
              <div 
                style={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  gap: '10px', 
                  marginTop: '20px' 
                }}
              >
                <Button
                  onClick={this.submitCall}
                  disabled={callUserLoading || !selectedCallReason}
                  style={{
                    backgroundColor: '#714FFF',
                    border: 'none',
                    borderRadius: '8px',
                    padding: '8px 16px',
                    fontSize: '14px',
                    fontWeight: '600',
                    fontFamily: 'Inter, sans-serif',
                    width: '100px',
                    height: '40px',
                    opacity: selectedCallReason ? '1' : '0.6',
                    cursor: selectedCallReason ? 'pointer' : 'not-allowed'
                  }}
                >
                 Confirm
                </Button>
                <Button
                  onClick={() => this.setState({ 
                    showConfirmationModal: false, 
                    isCallModalOpen: false,
                    selectedCallReason: null,
                    callUserLoading: false
                  })}
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #714FFF',
                    color: '#714FFF',
                    borderRadius: '8px',
                    padding: '8px 16px',
                    fontSize: '14px',
                    fontWeight: '600',
                    fontFamily: 'Inter, sans-serif',
                    width: '100px',
                    height: '40px'
                  }}
                >
                  Cancel
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  cashlessVision: state.cashlessVision,
});

export default connect(mapStateToProps)(RequestDetails);
