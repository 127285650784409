import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from 'reactstrap';
import { toast } from 'react-toastify';
import {
  updatePrebookStatus,
  cancelRequest,
} from '../../services/api/offline-consult';
import { useHistory } from 'react-router-dom';
import LoadingComponent from '../../components/common/LoadingComponent';

const PrebookCenterConfirmed = ({
  isOpen,
  closePrebookCenterConfirmModal,
  prebookRequestDetails,
  onComplete
}) => {
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(false);

  const handleDropDownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePrebookCenterConfirmed = () => {
    setLoading(true);
    const { requestId } = prebookRequestDetails;
    let body = {};
    if (selectedOption == '1') {
      body.status = 'confirmed';
    } else if (selectedOption == '2' || selectedOption == '3') {
      body.status = 'cancelled';
    }
    try {
      const prebookUpdateStatusCall =
        body.status == 'cancelled'
          ? cancelRequest(
            requestId,
            'centre declined the appointment.',
            null,
            0,
            null,
            'centre declined the appointment.'
          )
          : updatePrebookStatus(requestId, body);
      return prebookUpdateStatusCall
        .then((res) => {
          if (res.message === 'success') {
            toast.success(`Success`);
            setLoading(false);
            closePrebookCenterConfirmModal();
            onComplete();
          } else {
            const err = new Error('Invalid response');
            err.data = res;
            throw err;
          }
        })
        .catch((err) => {
          console.log({ err, response: err.data });
          setLoading(false);
          toast.error(`Failure! ${err.data.errorMessage}`);
        });
    } catch (e) {
      toast.error(`Failure!`);
      console.log({ e });
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={closePrebookCenterConfirmModal} centered>
      <ModalHeader
        className="revamp-modal-header"
        toggle={closePrebookCenterConfirmModal}
      >
        Center Confirmation
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <label style={{ fontFamily: 'Inter, sans-serif', width: '100%', fontSize : '14px', fontWeight : '500' }}>
                Did the centre acknowledge the appointment booking?
              </label>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10px',
                justifyContent: 'space-between',
                width: '100%',
              }}>
                <span style={{ fontFamily: 'Inter, sans-serif', fontSize : '14px', fontWeight : '500' }}>
                  Choose an option:
                </span>
                <select
                  value={selectedOption}
                  onChange={handleDropDownChange}
                  style={{ marginLeft: '24px', borderRadius: '12px', borderColor: '#EBEAF5', height: '30px', width: '70%' }}
                >
                  <option value="">--Select--</option>
                  <option value="1">
                    Centre is aware of the appointment
                  </option>
                  <option value="2">
                    Centre is unaware of the appointment
                  </option>
                  <option value="3">
                    Centre no longer wants to work with Visit
                  </option>
                </select>
              </div>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {loading ? <LoadingComponent color={'black'} /> : <></>}
        <Button
          style={{
            backgroundColor: 'rgb(113, 79, 255)',
            width: '45%',
            borderColor: 'rgb(113, 79, 255)',
            height: '44px',
          }}
          disabled={loading || !selectedOption}
          onClick={() => handlePrebookCenterConfirmed()}
        >
          Confirm
        </Button>
        {selectedOption != '2' && (
          <Button
            style={{
              backgroundColor: 'rgb(255, 255, 255)',
              width: '45%',
              borderColor: 'rgb(113, 79, 255)',
              color: 'rgb(113, 79, 255)',
              height: '44px',
            }}
            disabled={loading}
            onClick={() => closePrebookCenterConfirmModal()}
          >
            Close
          </Button>
        )}
        {selectedOption == '2' && (
          <Button
            style={{
              backgroundColor: 'rgb(255, 255, 255)',
              width: '45%',
              borderColor: 'rgb(113, 79, 255)',
              color: 'rgb(113, 79, 255)',
              height: '44px',
            }}
            onClick={() => {
              history.push(
                `/revamp-offline-consults/${prebookRequestDetails.requestId}`
              );
            }}
          >
            Call User
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default PrebookCenterConfirmed;
