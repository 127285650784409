import React, { useRef, useState } from 'react'
import FoldableTableHOC from 'react-table/lib/hoc/foldableTable';
import ReactTable from 'react-table';
import { cancelRequest, confirmRequest, getAllCashlessVaccinationRequests } from '../../services/api/cashless-vaccination'
import { OuterComponent, PaddedContainer } from '../customer-support/StyledComponent'
import NavBar from '../layout/NavBar'
import { Button, ButtonGroup, Row } from 'reactstrap';
import { SearchBox, SearchBoxContent, SearchBoxInput } from './styled-elements';
import { FaSearch } from 'react-icons/fa'
import useAfterInitialEffect from './hooks/useAfterInitialEffect';
import LoadingComponent from '../common/LoadingComponent';
import VaccinationModalConfirm from './modals/VaccinationModalConfirm';
import { toast } from 'react-toastify';
import VaccinationModalCancel from './modals/VaccinationModalCancel';
import VaccinationCallModal from './modals/VaccinationCallModal';
import { connect } from 'react-redux';
import { hideVaccinationRequestModal, showVaccinationRequestModal } from '../../actions';
import VaccinationRequestDetails from './modals/VaccinationRequestDetails';
import DownloadCsv from '../common/DownloadCsv';
import useTableColumns from './hooks/useTableColumns';
import VaccinationClaimModal from './modals/VaccinationClaimModal';
import EditUserPaymentModal from './modals/EditUserPaymentModal';
import VaccinationCommentModal from './modals/VaccinationCommentModal';
import moment from 'moment';
import SubContainer from './components/SubContainer';
import VaccinationShiftRequestModal from './modals/VaccinationShiftRequestModal';
import BookNewVaccination from './modals/BookNewVaccination';
import RescheduleRequests from './modals/ReshceduleRequests';
import ChangeCenter from './modals/ChangeCenter';

const FoldableTable = FoldableTableHOC(ReactTable);
const initialDates = {
    startDate: null,
    endDate: null,
}
const defaultCallAlertData = { isOpen: false, header: '', body: '', onSubmit: null, requestId: null, onClose: null }

const CashlessVaccination = ({
    dispatch,
    cashlessVaccination: stateDetails
}) => {

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    const [makeSearch, setMakeSearch] = useState('')
    const [totalRecords, setTotalRecords] = useState(0)
    const [requestIdFilter, setRequestIdFilter] = useState('')
    const [parentIdFilter, setParentIdFilter] = useState('')
    const [centerFilter,setRequestCenterFilter] = useState('')
    const [sponsorFilter,setRequestSponsorFilter] = useState('')
    const [vaccinationFilter, setRequestVaccinationFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('')
    const [appointmentDateFilter, setAppointmentDateFilter] = useState(initialDates)
    const [requestDateFilter, setRequestDateFilter] = useState(initialDates)
    const [appTimeFilter, setAppTimeFilter] = useState(['', ''])
    const [pages, setPages] = useState(0)
    const [pageSize, setPageSize] = useState(20)
    const [currentPage, setCurrentPage] = useState(0)
    const [requestId, setRequestId] = useState(null)
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false)
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)
    const [callAlertData, setCallAlertData] = useState(defaultCallAlertData)
    const [ackFilter, setAckFilter] = useState('')
    const [showClaimModal, setShowClaimModal] = useState(false)
    const [claimRequestId, setClaimRequestId] = useState(null)
    const [showEditPayment, setShowEditPayment] = useState(false)
    const [editPaymentRequestId, setEditPaymentRequestId] = useState(null)
    const [showShiftRequest, setShowShiftRequest] = useState(false)
    const [showRescheduleModal, setRescheduleModal] = useState(false)
    const [rescheduleRequestId, setRescheduleRequestId] = useState(null)
    const [shiftRequestId, setShiftRequestId] = useState(null)
    const [showCommentModal, setShowCommentModal] = useState(false)
    const [commentModalRequestId, setCommentModalRequestId] = useState(null)
    const [isBookVaccinationModalOpen, setBookVaccinationModalOpen] = useState(false);
    const [changeCenterRequestId, setCenterRequestId] = useState(null);
    const [changeCenterModalIsOpen, setChangeCenterModalIsOpen] = useState(false);


    const searchInputRef = useRef(null)

    const confirmOpenModal = (requestId) => {
        setConfirmModalIsOpen(true)
        setRequestId(requestId)
    }

    const confirmModalClose = () => {
        setConfirmModalIsOpen(false)
        setRequestId(null)
    }

    const submitConfirmModal = (dateKey, slotId) => {
        if (!requestId || !slotId || !dateKey) {
            return
        }

        return confirmRequest(requestId, dateKey, slotId)
        .then(() => toast.success('Confirm Request Successful'))
        .catch(() => toast.error('Confirm Request Failed, please try again'))
    }

    const cancelOpenModal = (requestId) => {
        setCancelModalIsOpen(true)
        setRequestId(requestId)
    }

    const cancelCloseModal = () => {
        setCancelModalIsOpen(false)
    }

    const submitCancelModal = (reason, remarks) => {
        if (!requestId || !reason) {
            return toast.error('Please select a reason to continue')
        }
        return cancelRequest(requestId, reason, remarks)
        .then(() => toast.success('Cancel request successful'))
        .catch(() => toast.error('Cancel request failed, please try again'))
    }

    const openClaimRequestButton = (requestId) => {
        setShowClaimModal(true)
        setClaimRequestId(requestId)
    }

    const closeClaimRequestButton = () => {
        setShowClaimModal(false)
        setClaimRequestId(null)
    }

    const openEditPaymentButton = (requestId) => {
        setShowEditPayment(true)
        setEditPaymentRequestId(requestId)
    }

    const closeEditPaymentButton = () => {
        setShowEditPayment(false)
        setEditPaymentRequestId(null)
    }

    const openShiftRequestButton = (requestId) => {
        setShowShiftRequest(true)
        setShiftRequestId(requestId)
    }

    const closeShiftRequestButton = () => {
        setShowShiftRequest(false)
        setShiftRequestId(null)
    }

    const setAckButtonConfirmModal = (requestId) => {
        setCallAlertData({
            isOpen: true,
            header: 'Call User?',
            body: 'Are you sure you want to call user?',
            requestId,
            onSubmit: () => {},
            onClose: () => {
                setCallAlertData(defaultCallAlertData)
            }
        })
    }

    const openRequestCommentModal = (requestId) => {
        setShowCommentModal(true)
        setCommentModalRequestId(requestId)
    }

    const closeRequestCommentModal = () => {
        setShowCommentModal(false)
        setCommentModalRequestId(null)
    }

    const rescheduleAppointment = (requestId) => {
        setRescheduleModal(true)
        setRescheduleRequestId(requestId)
    };

    const changeCenter = (requestId) => {
        setChangeCenterModalIsOpen(true)
        setCenterRequestId(requestId)
    }

    const closeChangeCenterModal = () => {
        setChangeCenterModalIsOpen(false)
        setCenterRequestId(null)
    }

    const closeRescheduleModal = () => {
        setRescheduleModal(false)
        setRescheduleRequestId(null)
    }

    const fetchTableData = (currPage, currPageSize, orderBy, orderDir, claimFilter) => {
        const valid = value => typeof value === 'number'
        const dateCheck = (date) => {
            return !date ? date : moment(date).format('YYYY-MM-DD')
        }
        setIsLoading(true)
        const params = {
            draw: valid(currPageSize) ? currPageSize : pageSize,
            page: valid(currPage) ? currPage : currentPage,
            requestId: requestIdFilter,
            parentBookingID: parentIdFilter,
            statusFilter,
            search: makeSearch,
            appointmentDateFilterEndDate: dateCheck(appointmentDateFilter.endDate),
            appointmentDateFilterStartDate: dateCheck(appointmentDateFilter.startDate),
            requestDateFilterStartDate: dateCheck(requestDateFilter.startDate),
            requestDateFilterEndDate: dateCheck(requestDateFilter.endDate),
            acknowledgementCallFilter: ackFilter,
            appointmentTimeFilter: appTimeFilter,
            centerFilter:centerFilter,
            vaccinationFilter:vaccinationFilter,
            sponsorFilter:sponsorFilter
        }
        if (orderBy && orderDir) {
            params.orderBy = orderBy
            params.orderDir = orderDir
        }
        if (claimFilter) {
            params.claimedByFilter = claimFilter
        }
        return getAllCashlessVaccinationRequests(params)
        .then((responseBody) => {
            setIsLoading(false)
            setData(responseBody.result)
            setTotalRecords(responseBody.totalHits || 0)
            setPages(Math.ceil(
                responseBody.totalHits / (valid(currPageSize) ? currPageSize : pageSize)
            ))
        })
    }

    const onFetchData = (state) => {
        let orderBy, orderDir, claimFilter
        if (state.sorted.length) {
            orderBy = state.sorted[0].id
            orderDir = state.sorted[0].desc ? 'DESC' : 'ASC'
        }
        state.filtered.forEach((f) => {
            if (f.id === 'opsUserName') {
                claimFilter = f.value
            }
        })
        fetchTableData(state.page, state.pageSize, orderBy, orderDir, claimFilter)
        setCurrentPage(state.page)
        setPageSize(state.pageSize)
    }

    useAfterInitialEffect(() => {
        fetchTableData()
    }, [
        requestIdFilter,
        parentIdFilter,
        statusFilter,
        appointmentDateFilter,
        requestDateFilter,
        ackFilter,
        makeSearch,
        appTimeFilter,
        centerFilter,
        vaccinationFilter,
        sponsorFilter
    ])

    const handleSearchBar = (event) => {
        const { value } = event.target
        console.log({value, key: event.key})
        if (event.key === 'Enter') {
            setMakeSearch(value)
        }
    }

    const openBookVaccinationModal = () => {
        setBookVaccinationModalOpen(true);
    };

    const closeBookVaccinationModal = () => {
        setBookVaccinationModalOpen(false);
    };
    

    const cashlessVaccinationTableColumns = useTableColumns({
        dispatch, showVaccinationRequestModal, setRequestIdFilter, setParentIdFilter, setStatusFilter,
        confirmOpenModal, cancelOpenModal, setRequestDateFilter, setAppTimeFilter,
        setAppointmentDateFilter, setAckButtonConfirmModal, setAckFilter, openClaimRequestButton,
        openEditPaymentButton, openRequestCommentModal, openShiftRequestButton,setRequestCenterFilter,setRequestVaccinationFilter,setRequestSponsorFilter, rescheduleAppointment, changeCenter,
    }, {
        statusFilter
    }, [statusFilter])

    return <OuterComponent>
        <NavBar />
        <PaddedContainer className='pt-4 mt-5'>
            <Row>
                <div className='col'>
                    <div className='row'>
                        <div className='col-8'>
                            <SearchBox>
                                <SearchBoxInput
                                    ref={searchInputRef} 
                                    placeholder='Search...'
                                    type='text'
                                    onKeyDown={handleSearchBar}
                                />
                                <SearchBoxContent>{totalRecords} records</SearchBoxContent>
                            </SearchBox>
                        </div>
                        <div className="col-1">
                            {isLoading ? (
                                <LoadingComponent />
                            ) : (
                                <button
                                onClick={() => setMakeSearch(searchInputRef.current.value)}
                                className="btn btn-primary"
                                >
                                <FaSearch />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <Button onClick={openBookVaccinationModal}>Book New Vaccination</Button>
                </div>
                <div className='col-2'>
                    <ButtonGroup size='sm'>
                        <DownloadCsv 
                            isCashless
                            callback={() => getAllCashlessVaccinationRequests({
                                draw: totalRecords,
                                search: makeSearch
                            })}
                        />
                    </ButtonGroup>
                </div>
            </Row>
        </PaddedContainer>
        <FoldableTable 
            data={data}
            columns={cashlessVaccinationTableColumns}
            loading={isLoading}
            filterable
            manual
            pages={pages}
            showPaginationTop
            className='-striped -highlight'
            onFetchData={onFetchData}
            pageSizeOptions={[2, 5, 20, 50, 100, 200, 500]}
            defaultSorted={[
                {
                    id: 'requestId',
                    desc: true
                }
            ]}
            SubComponent={(row) => (
                <SubContainer 
                    requestId={row.original.requestId}
                    userId={row.original.userId}
                />
            )}
        />

        {/* for confirming vaccination appointment */}
        <VaccinationModalConfirm 
            modalIsOpen={confirmModalIsOpen}
            openModal={confirmOpenModal}
            closeModal={confirmModalClose}
            submitModal={submitConfirmModal}
            onComplete={fetchTableData}
            requestId={requestId}
        />
        
        <VaccinationModalCancel 
            modalIsOpen={cancelModalIsOpen}
            openModal={cancelOpenModal}
            closeModal={cancelCloseModal}
            submitModal={submitCancelModal}
            onComplete={fetchTableData}
            requestId={requestId}
        />

        {/* for calling the patient */}
        <VaccinationCallModal 
            {...callAlertData}
            onComplete={fetchTableData}
        />

        <VaccinationRequestDetails 
            details={stateDetails}
            onClose={() => dispatch(hideVaccinationRequestModal())}
            isOpen={stateDetails && stateDetails.requestId}
        />

        <VaccinationClaimModal 
            requestId={claimRequestId}
            isOpen={showClaimModal}
            onClose={closeClaimRequestButton}
            onComplete={fetchTableData}
        />

        <EditUserPaymentModal 
            requestId={editPaymentRequestId}
            modalIsOpen={showEditPayment}
            onComplete={fetchTableData}
            closeModal={closeEditPaymentButton}
        />

        <VaccinationCommentModal 
            requestId={commentModalRequestId}
            isOpen={showCommentModal}
            onComplete={fetchTableData}
            onClose={closeRequestCommentModal}
        />
        
        <VaccinationShiftRequestModal 
            requestId={shiftRequestId}
            isOpen={showShiftRequest}
            onComplete={fetchTableData}
            onClose={closeShiftRequestButton}
        />

        {isBookVaccinationModalOpen && <BookNewVaccination 
            isOpen={isBookVaccinationModalOpen} 
            onClose={closeBookVaccinationModal}
            onComplete={fetchTableData}
        />}
            
        <RescheduleRequests  
            requestId={rescheduleRequestId}
            isOpen={showRescheduleModal}
            onComplete={fetchTableData}
            onClose={closeRescheduleModal}
        />

        <ChangeCenter 
            requestId={changeCenterRequestId}
            isOpen={changeCenterModalIsOpen}
            onComplete={fetchTableData}
            onClose={closeChangeCenterModal}
        />
        
    </OuterComponent>
}

const matchStateToProps = (state) => ({
    cashlessVaccination: state.cashlessVaccination
})

export default connect(matchStateToProps)(CashlessVaccination)