import baseRequest, { baseRequestMultiPart } from "./baseRequest"

export const getCashlessVisionRequests = (params = {}) => {
    const url = '/vision/ops/fetch-vision-requests'
    const method = 'GET'
    return baseRequest(url, method, params, null)
}

export const createNewVisionOrder = (userId, relativeId=null, voucherCode, status, rejectionReason=null) => {
    const url = `/vision/ops/create-vision-order`;
    const method = 'POST';
    const data = {userId, relativeId, voucherCode, status, rejectionReason};
    return baseRequest(url, method, null, data);
};

export const fetchUserDetailsByPhone = (phone, relativeId=null) => {
    const url = `/vision/ops/fetch-user-details`;
    const method = 'POST';
    const data = {phone, relativeId}
    return baseRequest(url, method, null, data);
};

export const uploadVisionDocuments = (requestId, formData) => {
    const url = `/vision/ops/upload-documents/${requestId}`;
    const method = 'POST';
    return baseRequestMultiPart(url, method, null, formData);
};

export const fetchVisionRequestDetails = (requestId) => {
    const url = `/vision/ops/fetch-request-details/${requestId}`;
    const method = 'GET'
    return baseRequest(url, method, {}, null)
}

export const fetchVisionProcedures = (requestId) => {
    const url = `/vision/ops/fetch-cashless-vision-procedures/${requestId}`;
    const method = 'GET'
    return baseRequest(url, method, {}, null)
}

export const getVisionRequestHistory = (requestId) => {
    const url = `/vision/ops/request-history/${requestId}`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const approveVisionOrder = (requestId) => {
    const url = `/vision/ops/approve/${requestId}`;
    const method = 'POST';
    return baseRequest(url, method, null, {});
};

export const cancelVisionOrder = (requestId, cancelReason) => {
    const url = `/vision/ops/reject/${requestId}`;
    const method = 'POST';
    return baseRequest(url, method, null, {cancelReason});
};

export const markVisionOrderCompleted = (requestId) => {
    const url = `/vision/ops/mark-completed/${requestId}`;
    const method = 'POST';
    return baseRequest(url, method, null, {});
};

export const requestPaymentFromUser = (requestId) => {
    const url = `/vision/ops/request-payment/${requestId}`;
    const method = 'POST';
    return baseRequest(url, method, null, {});
};

export const updateVisionRequest = (requestId, payload) => {
    const url = `/vision/ops/edit-order-details/${requestId}`;
    const method = 'POST';
    return baseRequest(url, method, null, payload);
};

export const callUserVisionRequest = (requestId, callReason) => {
    const url = `/vision/ops/${requestId}/call/user`;
    const method = 'POST';
    const data = {
      callReason,
    };
    return baseRequest(url, method, null, data);
};

export const getVisionRequestComments = (requestId) => {
    const url = `/vision/ops/get-comments/${requestId}`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const addVisionComment = (requestId, comment) => {
    const url = `/vision/ops/comments/${requestId}`;
    const method = 'POST';
    const data = {comment}
    return baseRequest(url, method, null, data);
};

export const addItemToInvoice = (requestId, item) => {
    const url = `/vision/ops/add-invoice-item/${requestId}`;
    const method = 'POST';
    return baseRequest(url, method, null, item);
};

export const removeItemFromInvoice = (requestId, procedureId) => {
    const url = `/vision/ops/remove-invoice-item/${requestId}`;
    const method = 'POST';
    const data = { procedureId };
    return baseRequest(url, method, null, data);
};

export const fetchInvoiceSummary = (requestId) => {
    const url = `/vision/ops/invoice-item-summary/${requestId}`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const removeUploadedDocument = (requestId, documentId) => {
    const url = `/vision/ops/remove-uploaded-documents/${requestId}`;
    const method = 'POST';
    const data = { documentId };
    return baseRequest(url, method, null, data);
};

