import React from 'react';

const FollowUpFilter = ({ value, onChange }) => {
  // Static services array
  const followUpFilter = [
    { label: "Follow Up Pending", value: "pending" },
    { label: "Follow Up Scheduled", value: "scheduled" },
  ];

  return (
    <select
      style={{
        width: '100%',
        fontSize: '12px',
        borderRadius: '10px',
        height: '32px',
      }}
      onChange={(e) => onChange(e.target.value)}
      value={value}
    >
      <option value="">All</option>
      {followUpFilter.map((service) => (
        <option key={service.value} value={service.value}>
          {service.label}
        </option>
      ))}
    </select>
  );
};


export default FollowUpFilter;
