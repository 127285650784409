import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
  Label,
  FormGroup
} from 'reactstrap';
import { toast } from 'react-toastify';
import { TimePicker, Checkbox } from 'antd';
import moment from 'moment';
import { updatePrebookStatus, cancelRequest } from '../../services/api/offline-consult';
import LoadingComponent from '../../components/common/LoadingComponent';


const PrebookUserConfirmed = ({
  isOpen,
  closePrebookUserConfirmModal,
  prebookRequestDetails,
  onComplete
}) => {
  const [selectedBox, setSelectedBox] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [cancelReason, setCancelReason] = useState('');
  const [appDate, setAppDate] = useState('');
  const [followUpTime, setFollowUpTime] = useState('');
  const [followUpRequiredFrom, setFollowUpRequiredFrom] = useState('User');

  const FOLLOWUP_DROPDOWN_OPTIONS = ['User', 'Centre', 'Network Team'];

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setSelectedBox(value);
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;

    if (!selectedDate) {
      return;
    }

    const currentDate = moment().format('YYYY-MM-DD');

    if (selectedDate >= currentDate) {
      setAppDate(selectedDate);
    } else {
      toast.warning('Please select a date equal to or after the current date.');
    }
  };

  const disabledTime = (current, type) => {
    const currentMoment = moment();
    if (type === 'hour' && currentMoment.isSame(appDate, 'day')) {
      // Disable hours before the current hour
      return {
        disabledHours: () => [...Array(currentMoment.hours()).keys()],
      };
    }

    if (type === 'minute' && currentMoment) {
      // Disable minutes before the current minute
      return {
        disabledMinutes: () => [...Array(currentMoment.minutes()).keys()],
      };
    }

    return {};
  };

  const handleDropDownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCancelReasonChange = (event) => {
    setCancelReason(event.target.value);
  };

  const handlePrebookUserConfirmed = () => {
    setLoading(true);
    const { requestId } = prebookRequestDetails;
    let body = {};
    if(selectedBox == 'Yes' && prebookRequestDetails.center_type == 'prepaid') {
        body.status = 'user-confirmed';
    } else if(selectedBox == 'Yes' && prebookRequestDetails.center_type != 'prepaid') {
        body.status = 'confirmed';
    } else if(selectedBox == 'No' && selectedOption == '1') {
        body.status = 'on-hold';
        if (!appDate || !followUpTime || !followUpRequiredFrom) {
            toast.error('Select follow Date, time and required type');
            return;
        } 
        body.followUpDate = appDate;
        body.followUpTime = followUpTime;
        body.followUpRequiredFrom = followUpRequiredFrom;
    } else if(selectedBox == 'No' && selectedOption == '2') {
        body.status = 'cancelled';
        if(cancelReason == 'Others' && !remarks) {
            toast.error('Please enter a remarks');
            return;
        }
    }
    try {
      const prebookUpdateStatusCall = body.status == 'cancelled' ? cancelRequest(requestId, cancelReason, remarks, 0, null, remarks || cancelReason) : updatePrebookStatus(requestId, body);
      return prebookUpdateStatusCall
        .then((res) => {
          if (res.message === 'success') {
            toast.success(`Success`);
            setLoading(false);
            closePrebookUserConfirmModal();
            onComplete();
          } else {
            const err = new Error('Invalid response');
            err.data = res;
            throw err;
          }
        })
        .catch((err) => {
          console.log({ err, response: err.data });
          setLoading(false);
          toast.error(`Failure! ${err.data.errorMessage}`);
        });
    } catch (e) {
      toast.error(`Failure!`);
      console.log({ e });
      setLoading(false);
    }
  };

return (
    <Modal
      isOpen={isOpen}
      toggle={closePrebookUserConfirmModal}
      centered
    >
      <ModalHeader className="revamp-modal-header" toggle={closePrebookUserConfirmModal}>
        Prebook User Confirmation
      </ModalHeader>
    <ModalBody>
      <Container>
        <Row style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: '8px',
          backgroundColor: '#714FFF1A',
          borderRadius: '16px',
          width: '52%',
          // marginLeft: '2%',
        }}>
          <span style={{ fontFamily: 'Inter, sans-serif', fontWeight: '500', fontSize: '12px', color: '#0F0B28' }}>
            {`This is a Prebook Appointment for ${prebookRequestDetails && prebookRequestDetails.center_type == 'prepaid' ? 'Prepaid' : 'Credit'} Center.`}
          </span>
        </Row>
        <Row>
          {selectedBox != 'No' && (
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '10px',
              justifyContent: 'space-between',
              width: '90%',
              // marginLeft: '5%',
            }}>
              <label
                style={{ fontFamily: 'Inter, sans-serif', fontWeight: '500', fontSize: '14px', color: '#0F0B28' }}
              >
                Was the appointment self-booked by the user?
              </label>
              <div style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
            }}>
              <div>
                <Checkbox
                  type="checkbox"
                  value="Yes"
                  checked={selectedBox === 'Yes'}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: '10px' }}
                >
                </Checkbox>
                <span style={{ fontFamily: 'Inter, sans-serif', fontWeight: '500', fontSize: '14px' }}>Yes</span>
              </div>

              <div>
                <Checkbox
                  type="checkbox"
                  value="No"
                  checked={selectedBox === 'No'}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: '10px' }}
                >
                </Checkbox>
                <span style={{ fontFamily: 'Inter, sans-serif', fontWeight: '500', fontSize: '14px' }}>No</span>
              </div>
              </div>
            </div>
          )}
        </Row>
        <Row>
          {selectedBox == 'No' && (
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '10px',
              justifyContent: 'space-between',
              width: '100%',
            }}>
                <span style={{
                  fontFamily: 'Inter, sans-serif', fontWeight: '500', fontSize: '14px', color: '#0F0B28'
                }}>
                Choose an option:
                </span>
                <select
                  value={selectedOption}
                  onChange={handleDropDownChange}
                  placeholder='Select Reason'
                  style={{ marginLeft: '24px', borderRadius: '12px', borderColor: '#EBEAF5', height: '30px', width : '65%', marginRight: '30px' }}
                >
                  <option value="">--Select--</option>
                  <option value="1">
                    User is seeking a booking from the centre
                  </option>
                  <option value="2">Cancel the appointment</option>
                </select>
            </div>
          )}
        </Row>
        <Row>
          {selectedOption == '1' && (
            <div style={{
              width: '100%',
            }}>
                <Row style={{
                  width: '100%',
                  marginTop: '10px',
                  display: 'flex',
                  gap: '28px',
                }}>
                  <Col className="col-3">
                    <label style={{
                      fontFamily: 'Inter, sans-serif', fontWeight: '500', fontSize: '14px', color: '#0F0B28'
                    }}>FollowUp Date:</label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="date"
                      value={appDate}
                      onChange={handleDateChange}
                    />
                  </Col>
                </Row>
                <Row style={{
                  width: '100%',
                  marginTop: '10px',
                  display: 'flex',
                  gap: '28px',
                }}>
                  <Col className="col-3">
                    <label style={{
                      fontFamily: 'Inter, sans-serif', fontWeight: '500', fontSize: '14px', color: '#0F0B28'
                    }}>FollowUp Time:</label>
                    </Col>
                    <Col>
                      <TimePicker
                        className="w-full"
                        //   disabledTime=(current) =>
                        //     disabledTime(current, 'hour')
                        disabledTime={(current) =>
                          disabledTime(current, 'hour')
                        }
                        onChange={(_, date) => {
                          setFollowUpTime(date);
                        }}
                        format="hh:mm:00 A"
                        defaultValue={
                          followUpTime
                            ? moment(followUpTime, 'hh:mm:00 A')
                            : undefined
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{
                  width: '100%',
                  marginTop: '10px',
                  display: 'flex',
                  gap: '28px',
                }}>
                    <Col className="col-3">
                      <Label style={{
                      fontFamily: 'Inter, sans-serif', fontWeight: '500', fontSize: '14px', color: '#0F0B28'
                    }}>Follow Up required from:</Label>
                    </Col>
                    <Col>
                      <select
                        className="form-control"
                        value={followUpRequiredFrom}
                        onChange={(e) => {
                          setFollowUpRequiredFrom(e.target.value);
                        }}
                      >
                        {FOLLOWUP_DROPDOWN_OPTIONS.map((dropdownOption) => (
                          <option>{dropdownOption}</option>
                        ))}
                      </select>{' '}
                    </Col>
                  </Row>
              </div>
            )}
            {selectedOption == '2' && (
              <div style={{
                width: '100%',
              }}>
              <div style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '10px',
              justifyContent: 'space-between',
              width: '100%',
            }}>
                <span style={{
                      fontFamily: 'Inter, sans-serif', fontWeight: '500', fontSize: '14px', color: '#0F0B28'
                    }}>
                  Cancel Reason:
                  </span>
                  <select
                    value={cancelReason}
                    onChange={handleCancelReasonChange}
                    style={{ marginLeft: '24px', borderRadius: '12px', borderColor: '#EBEAF5', height: '30px', width : '70%' }}
                  >
                    <option value="">--Select--</option>
                    <option value="User will book a new appointment via Visit">
                      User will book a new appointment via Visit
                    </option>
                    <option value="User doesn’t want an appointment">
                      User doesn’t want an appointment
                    </option>
                    <option value="User created booking for demo">
                      User created booking for demo
                    </option>
                    <option value="Others">Others</option>
                  </select>
                
              </div>
              {cancelReason == 'Others' && (
                  <div  style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '10px',
              justifyContent: 'space-between',
              width: '100%',
            }}>
                    <span>
                      Others:{' '}
                      </span>
                      <input
                        type="text"
                        value={remarks}
                        placeholder='Enter Remarks'
                        onChange={(event) => setRemarks(event.target.value)}
                        style={{ marginLeft: '24px', borderRadius: '12px', border:'1px solid #EBEAF5', height: '30px', width : '70%', padding : '5px' }}
                      />
                  </div>
                )}
              </div>
            )}
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {/* {loading ? <LoadingComponent color={'black'} /> : <></>} */}
        <Button
          style={{
            backgroundColor: 'rgb(113, 79, 255)',
            width: '45%',
            borderColor: 'rgb(113, 79, 255)',
            height: '44px',
          }}
          disabled={loading || !selectedBox}
          onClick={() => handlePrebookUserConfirmed()}
        >
         {loading ? <LoadingComponent color={'black'} /> : <> Confirm</>}
        </Button>
        <Button
          style={{
            backgroundColor: 'rgb(255, 255, 255)',
            width: '45%',
            borderColor: 'rgb(113, 79, 255)',
            color: 'rgb(113, 79, 255)',
            height: '44px',
          }}
          disabled={loading}
          onClick={() => closePrebookUserConfirmModal()}
        >
          Close
        </Button>
      </ModalFooter>

    </Modal>
  );
};

export default PrebookUserConfirmed;
