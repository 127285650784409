import baseRequest from './baseRequest';
import { store } from '../../store';
import config from '../../config';
import axios from 'axios';

const baseUrl = config.baseUrl;

let activeRequestForConceirge = null

export const getAllConceirgeRequests = (params) => {
  console.log({ params }, 'Params');
  const url = '/new-ops/consult/request/conceirge-requests';
  const method = 'POST';

  // Cancel the previous request if it exists
  if (activeRequestForConceirge) {
    activeRequestForConceirge.cancel();
  }

  // Make a new request and store it in activeRequest
  activeRequestForConceirge = baseRequest(url, method, null, params);

  return activeRequestForConceirge
    .then((data) => {
      console.log('Received data:', data);
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
      throw error;
    });
};

export const getCountOfConceirgeRequests = (params) => {
  console.log({ params }, 'Params');
  const url = '/new-ops/consult/request/conceirge-requests/count';
  const method = 'POST';

  // Cancel the previous request if it exists
  if (activeRequestForConceirge) {
    activeRequestForConceirge.cancel();
  }

  // Make a new request and store it in activeRequest
  activeRequestForConceirge = baseRequest(url, method, null, params);

  return activeRequestForConceirge
    .then((data) => {
      console.log('Received data:', data);
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
      throw error;
    });
};

export const fetchConceirgeAgents = () => {
  const url = '/new-ops/conceirge/ops-users';
  const method = 'GET';
  return baseRequest(url, method, null, null).then((response) => response.data);
};

export const markFollowUpForConceirge = (
  orderId,
  followUpReason,
  followUpDate,
  followUpTime
) => {
  const url = `/new-ops/conceirge/followUp`;
  const method = 'POST';
  const data = {
    orderId,
    followUpReason,
    followUpDate,
    followUpTime,
  };
  return baseRequest(url, method, null, data);
};

export const fetchAllConceirgeSponsor = () => {
  const url = '/new-ops/conceirge/sponsors';
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const addAssigneeForConceirge = (params) => {
  const url = '/new-ops/conceirge/add-assigne';
  const method = 'POST';
  return baseRequest(url, method, null, params);
};

export const claimRequestForConceirge = (requestId, sourceType) => {
  if (!requestId) {
    throw new Error('No Request Id Found');
  }
  const url = `/new-ops/conceirge/${requestId}/claim/${sourceType}`;
  const method = 'POST';
  return baseRequest(url, method, null, null);
};

export const fetchFollowUpForConceirge = (requestId) => {
  const url = `/new-ops/conceirge/${requestId}/fetch-follow-up`;
  const method = 'GET';
  const data = {
    requestId,
  };
  return baseRequest(url, method, null, data);
};

export const markFollowUpCompletedForConceirge = (requestId, followUpId) => {
  const url = `/new-ops/conceirge/${requestId}/fetch-follow-up`;
  const method = 'PUT';
  const data = {
    followUpId,
  };
  return baseRequest(url, method, null, data);
};

export const callUserRequestFromConceirge = (requestId, regarding, patientPhone) => {
  const url = `/new-ops/conceirge/${requestId}/call/user`;
  const method = 'POST';
  const data = {
    regarding,
    patientPhone
  };
  return baseRequest(url, method, null, data);
};