import React, { useEffect, useState, useRef } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
} from 'reactstrap';
import { TimePicker } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';
import SolidButton from '../common/SolidButton';
import DoctorSlot from './DoctorSlot';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import maleIcon from '../../images/maleIcon.svg';
import femaleIcon from '../../images/femaleIcon.svg';
import plusIcon from '../../images/plusIcon.svg';
import BlankImage from '../../images/blankImage.svg';
import { reactSelectStyleMulti } from '../common/CustomStyles';
import LoadingComponent from '../../components/common/LoadingComponent';
import '../../styling/doctor.css';

import fetchDoctorVerticals, {
  editDoctorSlots,
} from '../../services/api/doctor';
import { getVerticals } from '../../services/api/offline-consult';
import {
  fetchLanguages,
  fetchUserCenter,
  createDoctor,
} from '../../services/api/doctor';

const AddDoctorDetailModal = (props) => {
  const {
    isOpen,
    closeAddDoctorDetailModal,
    fetchTableData,
    followUpDetails,
    onComplete,
    doctorData,
    prevSlots,
    modal,
    setModal,
  } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [editDoctorSlot, setEditDoctorSlot] = useState([]);
  const [isSlotTab, setIsSlotTab] = useState(false);
  const [verticals, setVerticals] = useState([]);
  const [fetchingCenters, setFetchingCenters] = useState(false);
  const [centers, setCenters] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const [selectedCenter, setSelectedCenter] = useState({
    value: data.centerId,
    label: data.centerName,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const [verticals, userCenter] = await Promise.all([
          getVerticals(),
          //fetchLanguages(),
        ]);

        let verticalsResult = verticals && verticals.data ? verticals.data : [];

        //let allLanguagesResult = allLanguages.data ? allLanguages.data : [];

        let userCenterResult =
          userCenter && userCenter.resultList ? userCenter.resultList : [];

        verticalsResult = verticalsResult.map((res) => ({
          label: res.verticalName,
          value: res.verticalId,
        }));

        // userCenterResult = userCenterResult.map((res) => ({
        //   label: res.centerName,
        //   value: res.centerId,
        // }));

        setData({
          ...data,
          url: '',
          name: '',
          centerName: '',
          description: '',
          speciality: '',
          verticalId: '',
          centerId: '',
          language: [],
          years: '',
          phone: '',
          qualification: '',
          registrationNumber: '',
          fees: '',
          gender: '',
          isMale: false,
          isFemale: false,
          isName: false,
          file: null,
        });
        //setLanguages(allLanguagesResult);
        setVerticals(verticalsResult);
        //setCenters(userCenterResult);
        setLoading(false);
      } catch (err) {
        toast.error(err.response.data.errorDebug);
        setLoading(false);
      }
    }

    fetchData();
  }, []); // Ensure that the useEffect runs only once during component mount

  useEffect(() => {}, [verticals]);

  const fileInputRef = useRef(null);

  const handleClick = () => {
    console.log('Button Clicked', fileInputRef.current);
    const file = fileInputRef.current.click();
  };

  const loadOptions = async (inputValue) => {
    if (!inputValue.trim()) {
      return [];
    }

    try {
      const userCenter = await fetchUserCenter(inputValue);
      
      let userCenterResult =
          userCenter && userCenter.resultList ? userCenter.resultList : [];
      userCenterResult = userCenterResult.map((res) => (
          {
            label: res.title + ' ('+res.address+')'+ ' | Id-'+res.centerId,
            value: res.centerId,
            
          }));
     
      setCenters(userCenterResult);
      return userCenterResult;
    } catch (error) {
      console.error('Error fetching centers:', error);
      return [];
    }
  };

  // const loadOptions = async (inputValue, callback) => {
  //     if (!inputValue.trim()) {
  //       callback([]);
  //       return;
  //     }

  //     try {
  //       const response = await fetchUserCenter(inputValue);
  //       const options = response.result.map((center) => ({
  //         value: center.centerId,
  //         label: center.title,
  //         locality: center.locality,
  //       }));
  //       callback(options);
  //     } catch (error) {
  //       console.error('Error fetching centers:', error);
  //       callback([]);
  //     }
  //   };

  const handleImageUpload = (e) => {
    console.log('file', e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setData({ ...data, file, url: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const onSearchCenter = async (value) => {
    setFetchingCenters(true);

    if (value.trim() === '') {
      setCenters([]);
      setFetchingCenters(false);
      return;
    }

    try {
      const res = await fetchUserCenter(value);
      setCenters(
        res.result.map((ele) => ({
          key: ele.centerId,
          label: ele.title,
        }))
      );
    } finally {
      setFetchingCenters(false);
    }
  };

  const handleSubmit = async () => {
    if (
      !inputValue ||
      !data.verticalId ||
      !data.fees ||
      editDoctorSlot.length <= 0 ||
      !data.centerName
    ) {
      toast.warning(
        'Please fill in all mandatory fields - Name, Vertical, Fees, Slots, Center'
      );
      return; // Stop the submission process
    }
    setLoading(true);

    try {
      let postData = {
        networkCenterId: data.centerId,
        centerName: data.centerName,
        doctorName: inputValue,
        doctorDescription: data.description,
        doctorQualifications: data.qualification,
        registrationNumber: data.registrationNumber,
        practiceYears: data.years,
        doctorVerticalId: data.verticalId,
        knownLanguages: data.language.map((ele) => ele.value).join(','),
        doctorGender: null,
        doctorPhone: data.phone,
        isOnlineDoctor: 0,
        isOfflineDoctor: 1,
        doctorSpeciality: data.speciality,
        isNightDoctor: 0,
        networkSource: 6,
        preference: 'voice',
        onlyAvailableForBookForLater: 0,
        onlineSlotDuration: 15,
        scheduleConsultCancelWindowEnds: 2,
        onlineSlotCalcWindow: 2,
        doctorFees: data.fees,
        requestComingFrom : 'ops-dashboard'
      };

      let formData = new FormData();
      formData.append('file', data.file);

      for (let index in postData) {
        formData.append(index, postData[index]);
      }

      const response = await createDoctor(formData);
      if (response && response.message == 'success') {
        if (editDoctorSlot.length > 0) {
          const slotData = editDoctorSlot.map((res) => ({
            ...res,
            doctorId: response.doctorId,
            networkCenterId: postData.networkCenterId,
          }));

          const slotResponse = await editDoctorSlots(
            slotData,
            response.doctorId
          );
        }
        toast.info(
          'Added Doctor Request has been created for network approval. Please wait upto 12 hours.'
        );
        //DISABLE THE MODAL NOW
      } else if (response && response.data.message == 'serverError') {
        toast.error(response.data.errorMessage);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.errorMessage);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancel={closeAddDoctorDetailModal}
      toggle={closeAddDoctorDetailModal}
    >
      <ModalHeader
        className="revamp-modal-header"
        toggle={closeAddDoctorDetailModal}
      >
        Add Doctor Details
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img src={data.url ? data.url : BlankImage} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: data.name ? 'column' : 'row',
                    justifyContent: 'center',
                    alignItems: !data.name && 'center',
                    marginTop: !data.name && '16px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '12px',
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      {data.isName ? (
                        <input
                          type="text"
                          name="name"
                          onChange={handleChange}
                          defaultValue={data.name}
                          style={{
                            fontSize: '20px',
                            fontWeight: 700,
                            background: '#FAFAFA',
                            padding: '8px',
                            borderRadius: '16px',
                            margin: '0px 32px 8px 20px',
                          }}
                        />
                      ) : (
                        <h2
                          className="doctorProfile-info-name"
                          style={{
                            fontSize: '24px',
                            fontWeight: 700,
                            margin: '0px 16px 10px 24px',
                          }}
                        >
                          {data.name}
                        </h2>
                      )}
                    </div>
                    {/* <div>
                      {!data.name && (
                        <SolidButton
                          text={data.name ? 'Edit Name' : 'Add Name'}
                          style={{
                            radius: '8px',
                            border: `none`,
                            background: '#E5E1FD',
                            height: '40px',
                            width: '135px',
                            color: '#714FFF',
                          }}
                          onClick={() => {
                            setData({ ...data, isName: !data.isName });
                          }}
                          children={
                            !data.name ? (
                              <img
                                src={plusIcon}
                                style={{ marginRight: '8px' }}
                              />
                            ) : (
                              ''
                            )
                          }
                        />
                      )}
                    </div> */}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingRight: '20px',
                      paddingBottom: '20px',
                    }}
                  >
                    <Button
                      style={{
                        color: '#714FFF',
                        cursor: 'pointer',
                        fontSize: '16px',
                        backgroundColor: '#FFFFFF',
                        borderColor: '#714FFF',
                      }}
                      onClick={handleClick}
                    >
                      Upload Image
                    </Button>
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleImageUpload}
                    />
                  </div>
                  {/* {!isSlotTab && (
                    <div
                     className="doctorSlot-button"
                      style={{ marginTop: '-10px' }}
                    >
                      <SolidButton
                        text="Add Slots"
                        style={{
                          radius: '8px',
                          border: `none`,
                          background: '#E2DEFF',
                          height: '40px',
                          width: '150px',
                          color: '#714FFF',
                          flexDirection: 'justify-right',
                        }}
                        onClick={() => {
                          setIsSlotTab(true);
                        }}
                        children={
                          <img src={plusIcon} style={{ marginRight: '8px' }} />
                        }
                      />
                    </div>
                  )} */}
                </div>
              </div>
              {/* {!isSlotTab && (
                    <div
                     className="doctorSlot-button"
                      style={{flexDirection:'column', justifyContent:'space-around'}}
                    >
                      <SolidButton
                        text="Add Slots"
                        style={{
                          radius: '34px',
                          border: `none`,
                          background: '#E2DEFF',
                          height: '55px',
                          width: '150px',
                          color: '#714FFF',
                          flexDirection: 'justify-right',
                        }}
                        onClick={() => {
                          setIsSlotTab(true);
                        }}
                        children={
                          <img src={plusIcon} style={{ marginRight: '8px' }} />
                        }
                      />
                    </div>
                  )} */}
            </div>

            <div>
              <Row>
                <Col>
                  <FormGroup>
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Doctor Name :
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Input
                      style={{
                        width: '400px',
                      }}
                      type="text"
                      value={inputValue}
                      onChange={handleInputChange}
                      placeholder="Enter Doctor Name"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Centre Name :
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    {/* <AsyncSelect
                      placeholder="Select Centre Name"
                      defaultValue={selectedCenter}
                      loadOptions={loadOptions}
                      options={centers}
                      onChange={(selectedOption) => {
                        setSelectedCenter(selectedOption);
                        setData({
                          ...data,
                          centerId: selectedOption.value,
                          centerName: selectedOption.label,
                        });
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '400px',
                          borderRadius: '15px',
                          border: '1px solid #F2F1F6',
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: '400px',
                        }),
                      }}
                    /> */}
                    <AsyncSelect
                        placeholder="Select Centre Name"
                        defaultValue={selectedCenter}
                        loadOptions={loadOptions}
                        options={centers}
                        onChange={(selectedOption) => {
                          setSelectedCenter(selectedOption);
                          setData({
                            ...data,
                            centerId: selectedOption.value,
                            centerName: selectedOption.label,
                          });
                        }}
                        styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '400px',
                          borderRadius: '15px',
                          border: '1px solid #F2F1F6',
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: '400px',
                        }),
                      }}
                      />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Doctor Speciality
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <ReactSelect
                    placeholder="Type Your Speciality"
                    //className="editDoctorProfile-lower-select"
                    // defaultValue={{
                    //   value: data.verticalId,
                    //   label: data.speciality,
                    // }}
                    // defaultValue={verticals.find(
                    //     (v) => v.value === data.verticalId
                    //   )}

                    options={verticals}
                    onChange={(e) => {
                      setData({
                        ...data,
                        verticalId: e.value,
                        speciality: e.label,
                      });
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '400px',
                        borderRadius: '15px',
                        border: '1px solid #F2F1F6',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: '400px',
                      }),
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Consultation Fees :
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Input
                      style={{ width: '400px' }}
                      placeholder="Enter Consultation Fees"
                      type="number"
                      defaultValue={data.fees}
                      name="fees"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div
                style={{
                  borderRadius: '8px',
                  backgroundColor: '#F2F1F6',
                }}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label
                        style={{
                          fontFamily: 'Inter, sans-serif',
                          marginTop: '20px',
                          paddingLeft: '20px',
                        }}
                      >
                        Doctor Slots :
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    {!isSlotTab && (
                      <FormGroup>
                        <Button
                          style={{
                            backgroundColor: '#FFFFFF',
                            color: '#714FFF',
                            border: '1px solid #714FFF',
                            borderRadius: '8px',
                            marginLeft: '156px',
                            marginTop: '20px',
                          }}
                          onClick={() => {
                            setIsSlotTab(true);
                          }}
                        >
                          Add Slots
                        </Button>
                      </FormGroup>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
            <div>
              {isSlotTab && (
                <div
                  className="doctorProfile"
                  style={{ marginTop: '18px', padding: '0px 0px 16px 0px' }}
                >
                  <div className="doctorSlot-box" style={{ display: 'flex' }}>
                    <h5
                      style={{
                        textAlign: 'unset',
                        margin: '10px 16px',
                        color: '#0F0B28',
                        fontWeight: 600,
                      }}
                    >
                      Add Slot
                    </h5>
                  </div>

                  <div style={{ marginTop: '18px' }}>
                    <DoctorSlot
                      //   doctorSlots={doctorData}
                      //   prevSlots={prevSlots}
                      isSlotTab={isSlotTab}
                      setIsSlotTab={setIsSlotTab}
                      setEditDoctorSlot={setEditDoctorSlot}
                      editDoctorSlot={editDoctorSlot}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter
        style={{
          justifyContent: 'space-around',
        }}
      >
        <Button
          style={{ backgroundColor: '#714FFF', width: '40%', border: 'none' }}
          onClick={handleSubmit}
        >
          Submit Details
        </Button>
        <Button
          style={{
            width: '40%',
            backgroundColor: '#FFFFFF',
            color: '#714FFF',
            border: '1px solid #714FFF',
            boxShadow: 'none',
          }}
          onClick={() => closeAddDoctorDetailModal()}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddDoctorDetailModal;
