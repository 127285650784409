/* eslint-disable */

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../layout/NavBar';
import SearchIcon from '../../images/SearchIcon.svg';
import Messages from '../../images/Messages.svg';
import LoadingComponent from '../common/LoadingComponent';
import { toast } from 'react-toastify';
import {
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input as InputBox,
} from 'reactstrap';
import {
  getOPDAgentStatus
} from '../../services/api/offline-consult';
import { Select, DatePicker, Checkbox } from 'antd';
import ShowActions from '../../images/ShowActions.svg';
import UnhideActions from '../../images/UnhideActions.svg';
import { debounce } from 'lodash';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {
  StatusPendingContainer,
  StatusCompleteContainer,
  StatusCancelledContainer,
} from './visionStatusContainer';

import moment, { max } from 'moment';
import RidSearch from '../revamp-offline-ops/RidSearch';
import { fetchAllSponsors } from '../../services/api/sponsors';
import RequestDetails from './visionRequestDetails'
import VisionSubComponent from './VisionSubComponent';
import '../revamp-offline-ops/revamp-react-table.css';

import {
  showVisionRequestModal,
  hideVisionRequestModal,
  updateRequestID,
  showVisionUploadModal,
  hideVisionUploadModal,
  showVisionOrderModal,
  hideVisionOrderModal,
  setVisionRequestId,
} from '../../actions/index';
import VisionActions from './visionActions'
import DownloadCsv from '../revamp-offline-ops/DownloadCsv';
import CreateOrder from './createOrder';
import VisionPagination from './VisionPagination';
import ConfirmModal from '../revamp-offline-ops/ConfirmModal';
import { callUserVisionRequest, createNewVisionOrder, getCashlessVisionRequests, updateVisionRequest } from '../../services/api/cashless-vision';
import VisionCallingButton from './visionCallingButton';
import UploadDocumentsModal from './uploadModal';
import OrderDetailsModal from './orderDetailsModal';
import VisionCompletedModal from './visionCompletedModal';
import VisionAckFilter from './VisionAckFilter';

const CashlessVision = (props) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [countLoader, setCountLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [noData, setNoData] = useState(false);
  const [segregation, setSegregation] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const [totalHits, setTotalHits] = useState(0);
  const [pages, setPages] = useState(0);
  const [statusFilter, setStatusFilter] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [orderDir, setOrderDir] = useState(null);
  const [page, setPage] = useState(0);
  const [filterRequestId, setFilterRequestId] = useState('');
  const [lenskartOrderId, setLenskartOrderId] = useState('');
  const [sponsorList, setSponsorList] = useState([]);
  const [sponsorFilter, setSponsorFilter] = useState(null);
  const [orderDateFilterStartDate, setOrderDateFilterStartDate] = useState(null);
  const [orderDateFilterEndDate, setOrderDateFilterEndDate] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [allChecked, setAllChecked] = useState([]);
  const [ackFilter, setAckFilter] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [openActionDropDown, setOpenActionDropDown] = useState([]);
  const [checkInModalOpen, setCheckInModalOpen] = useState(false);
  const [currentRequestDetails, setCurrentRequestDetails] = useState({});
  const [isCallModalOpen, setIsCallModalOpen] = useState(false);
  const [toggleActions , setToggleActions] = useState(true);
  const [newRequestId, setNewRequestId] = useState(null);
  const [callReasonOptions, setCallReasonOptions] = useState([
    'Reminder for copayment',
    'Reminder to upload vision card',
    'Issues in ordered items/ prices',
    'Issues in invoice details',
    'An update',
  ]);
  const [selectedCallReason, setSelectedCallReason] = useState('');
  const [colorGreading, setColorGreading] = useState(false);
  const [opsUserStatus, setOpsUserStatus] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    header: '',
    body: '',
    requestId: null,
    onSubmit: null,
    onClose: null,
    buttonLoading: false,
  });

  const [basicInfoFromChild, setBasicInfoFromChild] = useState({});
  const [buttonActive, setButtonActive] = useState(null);
  const [showCompletedModal, setShowCompletedModal] = useState(false);

  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const { Option } = Select;

  const {
    showRequestDetails,
    requestId,
    showUploadModal,
    showOrderModal,
    visionRequestId,
  } = useSelector((state) => state.cashlessVision);

  const debouncedSetFilterRequestId = debounce(setFilterRequestId, 1500);
  const debouncedSetLenskartOrderId = debounce(setLenskartOrderId, 900);
  
  const filterData = (state, instance) => {
    const { filtered } = state;
    if (filtered.length == 0) {
      debouncedSetFilterRequestId('');
    }
    if (state.sorted && state.sorted.length > 0) {
      setOrderBy(state.sorted[0].id);
      setOrderDir(state.sorted[0].desc ? 'desc' : 'asc');
    } else {
      setOrderBy(1);
      setOrderDir('desc');
    }
    state.filtered.forEach((f) => {
      if (f.id === 'requestId') {
        debouncedSetFilterRequestId(f.value);
      }
      if (f.id === 'sponsorName') {
        setSponsorFilter(f.value);
      }
      if (f.id === 'lenskartOrderId') {
        debouncedSetLenskartOrderId(f.value);
      }
    });
    setPageSize(state.pageSize);
    setPage(state.page);
  };

  const params = {
    draw: pageSize,
    page: page,
    segregation: segregation,
    orderBy: orderBy,
    orderDir: orderDir,
    sponsorFilter,
    lenskartOrderId,
    csv: true,
  };

  const getVisionRequestData = () => {
    setLoading(true);
    setCountLoader(true);

    const params = {
      draw: pageSize,
      page: page,
      orderBy: orderBy,
      orderDir: orderDir,
      sponsorFilter,
      lenskartOrderId,
    };

    if (ackFilter) {
      params.ackFilter = ackFilter;
    }

    if (statusFilter) {
      params.statusFilter = statusFilter;
    }

    if (search) {
      params.search = search;
    }
    if (filterRequestId) {
      params.requestId = filterRequestId;
    }

    if (orderDateFilterStartDate && orderDateFilterEndDate) {
      params.orderDateFilterStartDate = orderDateFilterStartDate ? moment(orderDateFilterStartDate).format('YYYY-MM-DD') : null;
      params.orderDateFilterEndDate = orderDateFilterEndDate ? moment(orderDateFilterEndDate).format('YYYY-MM-DD') : null;  
    }

    getCashlessVisionRequests(params)
      .then((res) => {
        if (!res.data.visionRequests) {
          setNoData(true);
          setDataSource(res.data.visionRequests);
        } else {
          setNoData(false);
          setDataSource(res.data.visionRequests);
        }
        setLoading(false);
        setCountLoader(false);
        setPages(Math.ceil(res.data.totalCount / pageSize));
        setTotalHits(res.data.totalCount || 0);
      })
      .catch((err) => {
        console.error('Error fetching vision requests:', err);
        setLoading(false);
        setCountLoader(false);
      });
  };

  const onAction = () => {
    getVisionRequestData();
  };

  const handleChange = (value) => {
    setSearch(value);
  };
  const selectRow = (value) => {
    setPageSize(value);
  };
  const handleSponsor = (value) => {
    setSponsorFilter(value);
  };

  const handleLenskartOrderIdChange = (value) => {
    debouncedSetLenskartOrderId(value);
  };

  const clearFilter = () => {
    setSegregation(null);
    setStatusFilter(null);
    setSponsorFilter(null);
    setLenskartOrderId('');
    setButtonActive(null);
    setOrderDateFilterStartDate(null);
    setOrderDateFilterEndDate(null);
    setSelectedDateRange(null);
    setAckFilter(null);
  };

  const isFilterApplied = [
    segregation,
    sponsorFilter,
    lenskartOrderId,
    statusFilter,
    ackFilter
    
  ].some((filter) => filter !== null && filter !== undefined && filter !== '');

  const handleDataFormChild = (basicInfo) => {
    setBasicInfoFromChild(basicInfo);
  };

  const onSearchClick = () => {
    let notMobile = 0;
    let notEmail = 0;
    let notName = 0;
    const mobileNumberValidationRegex = /^[6789]\d{9}$/;
    const emailValidationRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const nameValidationRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ'`-]{2,50}(?: [a-zA-ZÀ-ÖØ-öø-ÿ'`-]{2,50})*$/;
    if (!mobileNumberValidationRegex.test(search)) {
      notMobile = 1;
    }
    if (!emailValidationRegex.test(search)) {
      notEmail = 1;
    }
    if (!nameValidationRegex.test(search)) {
      notName = 1;
    }
    if (notEmail && notMobile && notName && search) {
      toast.error(`Please enter a valid phone number, name or email`);
      return;
    }

    if (loading) {
      return;
    }
    getVisionRequestData();
  };

  const setSegregationFilter = (sg) => {
    if (segregation === sg) {
      return;
    } else {
      setSegregation(sg);
    }
  };

  useEffect(() => {
    if (orderBy !== null && orderDir !== null) {
      getVisionRequestData();
    }
    if (!showRequestDetails) {
      dispatch(updateRequestID(null));
    }
  }, [
    selectedDateRange,
    segregation,
    pageSize,
    page,
    orderBy,
    orderDir,
    sponsorFilter,
    lenskartOrderId,
    filterRequestId,
    statusFilter,
    ackFilter,
  ]);

  useEffect(() => {
    return () => {
      debouncedSetFilterRequestId.cancel();
    };
  }, []);

  useEffect(() => {
    const getSponsors = async () => {
      try {
        const response = await fetchAllSponsors();
        const sponsorsList = response.data.map((sponsor) => ({
          value: sponsor.sponsorId,
          label: sponsor.sponsorName,
        }));
        setSponsorList(sponsorsList);
      } catch (error) {
        console.error('Error fetching sponsors:', error);
        setSponsorList([]);
      }
    };

    const getAgentStatus = async () => {
      try {
        const response = await getOPDAgentStatus();
        setOpsUserStatus(response.result);
        setGroupId(response.result.groupId);
      } catch (error) {
        console.error('Error fetching agent status:', error);
      }
    };

    getSponsors();
    getAgentStatus();
  }, []);

  const toggleActionsColumn = () => {
    setToggleActions((prevState) => !prevState);
  };
  
  const handleOrderDateChange = (dates, dateStrings) => {
    setSelectedDateRange(dates);
    setOrderDateFilterStartDate(dateStrings[0]);
    setOrderDateFilterEndDate(dateStrings[1]);
  };

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      setAllChecked([...allChecked, parseInt(e.target.value)]);
    } else {
      setAllChecked(allChecked.filter((item) => item != e.target.value));
      setSelectAllChecked(false);
    }
  };

  const handleAllChangeCheckbox = (e) => {
    if (e.target.checked) {
      setSelectAllChecked(!selectAllChecked);
      setAllChecked(dataSource.map((res) => res.requestId));
    } else {
      setSelectAllChecked(!selectAllChecked);
      setAllChecked([]);
    }
  };

  const handleChangeForCallReason = (event) => {
    const { value } = event.target;
    setSelectedCallReason(value);
    setColorGreading(true);
  };

  const submitUserAcknowledgeCall = (requestId, selectedCallReason) => {
    if (!requestId) {
      return;
    }
    return callUserVisionRequest(
      requestId,
      selectedCallReason
    ).then(getVisionRequestData);
  };


  const copyDetails = () => {
    navigator.clipboard.writeText(
      `
      Request Id : ${basicInfoFromChild.requestId}
      Lenskart Order Id : ${basicInfoFromChild.lenkskartOrderId}
      Patient name : ${basicInfoFromChild.patientName}
      User phone : ${basicInfoFromChild.userPhone}
      Email : ${basicInfoFromChild.userEmail}
      Vision card date : ${basicInfoFromChild.visionCardDate}
      Order date : ${basicInfoFromChild.orderDate}
      `
    );
  };

  const setAckButtonConfirmModal = (requestId) => {
    setIsCallModalOpen(true);
    setConfirmModal({
      isOpen: true,
      header: 'Call User?',
      body: 'Are you sure you want to call user?',
      requestId,
      onSubmit: submitUserAcknowledgeCall,
      onClose: () => {
        setConfirmModal({ isOpen: false });
        setIsCallModalOpen(false);
        setSelectedCallReason(null);
      },
    });
  };

  const submitConfirmModalGen = () => {
    const { onSubmit, requestId } = confirmModal;
    return onSubmit(requestId, selectedCallReason).then(() => {
      setConfirmModal((prevState) => ({ ...prevState, isOpen: false }));
    });
  };

  const showDetails = (requestId, requestDetails = {}) => {
    if (!requestId) {
      return;
    }
    setCurrentRequestDetails(requestDetails);
    dispatch(showVisionRequestModal(requestId, requestDetails));
  };
  const closeDetailModal = () => {
    setCurrentRequestDetails({});
    dispatch(hideVisionRequestModal());
  };

  const changeAckFitler = (value) => {
    setAckFilter(value);
  };

  const handlePlaceRequestSubmit = (userId, relativeId, voucherCode, status, rejectionReason) => {
    return createNewVisionOrder(userId, relativeId, voucherCode, status, rejectionReason)
      .then((response) => {
        if (response.message === 'success') {
          const newId = response.data.visionRequestId;
          setNewRequestId(newId);
          dispatch(setVisionRequestId(newId));
          dispatch(showVisionUploadModal(newId));
          toast.success('Request placed successfully!');
          return true;
        } else {
          toast.error(response.errorMessage);
          return false;
        }
      })
  };

  const handleUploadComplete = (visionCardFiles) => {
    // Convert files to base64 strings
    const processFiles = async (files) => {
      return Promise.all(files.map(async (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({
              name: file.name,
              type: file.type,
              size: file.size,
              data: reader.result  // base64 string
            });
          };
          reader.readAsDataURL(file);
        });
      }));
    };

    processFiles(visionCardFiles).then((visionCardMetadata) => {
      dispatch(hideVisionUploadModal());
      dispatch(showVisionOrderModal(newRequestId));
    });
  };

  const toggleUploadModal = () => {
    try {
      
        dispatch(hideVisionUploadModal());
        dispatch(setVisionRequestId(null));

    } catch (error) {
      throw error;
    }
  };

  const toggleOrderDetailsModal = () => {
    try {
        dispatch(hideVisionOrderModal());
        dispatch(setVisionRequestId(null));

    } catch (error) {
      throw error;
    }
  };

  const handleOrderDetailsSubmit = async (requestId, orderDetails) => {
    try {
      const response = await updateVisionRequest(requestId, orderDetails);

      if (response.message === 'success') {
        dispatch(hideVisionOrderModal());
        dispatch(setVisionRequestId(null));
        setShowCompletedModal(true);
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleCompletedModalClose = () => {
    setShowCompletedModal(false);
    dispatch(setVisionRequestId(null));
    onAction();
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const tableStyle = {
    'border': 'none',
    'fontSize': '15px',
    'backgroundColor': '#FFFFFF',
    'fontWeight': '500',
    'fontFamily': 'Montserrat',
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div
            className="flex flex-row center-text"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
              alignContent: 'center',
            }}
          >
            <Checkbox
              name="All"
              type="checkbox"
              placeholder="All"
              checked={selectAllChecked}
              onChange={handleAllChangeCheckbox}
            >
            </Checkbox>
          </div>
        ),
        filterable: false,
        width : 50,
        style: { alignContent: 'center', justifyContent: 'center' },
        accessor: 'All',
        Cell: (row) => (
          (
            <div className="flex flex-row justify-center">
              <Checkbox
                type="checkbox"
                value={row.original.requestId}
                checked={allChecked.includes(row.original.requestId)}
                onChange={handleChangeCheckbox}
              ></Checkbox>
            </div>
          )
        ),
      },
      {
        Header: () => (
          <div
            style={{
              justifyContent: 'center',
              textAlign: 'center',
              alignContent: 'center',
            }}
            className="flex flex-row center-text"
          >
            RID
          </div>
        ),
        accessor: 'requestId',
        filterable: true,
        width: 90,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        Cell: (row) => {
          const {
            requestId,
          } = row.original;
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '0.5rem',
              }}
            >
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#714FFF',
                  border: 'none',
                  fontWeight: 'bold',
                }}
                onClick={() => showDetails(requestId, row.original)}
              >
                {requestId}
              </button>
            </div>
          );
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Lenskart Order Number
          </div>
        ),
        width: 140,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'lenskartOrderId',
        filterable: true,
        Filter: <RidSearch onChange={handleLenskartOrderIdChange} />,
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Patient Name
          </div>
        ),
        width: 140,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'patientName',
        filterable: false,
        // Filter: <RidSearch onChange={handlePatientNameChange} />,
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Mobile Number
          </div>
        ),
        width: 140,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'phone',
        filterable: false,
        // Filter: <RidSearch onChange={handlePhoneNumberChange} />,
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Email ID
          </div>
        ),
        width: 140,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'email',
        filterable: false,
        // Filter: <RidSearch onChange={handleEmailIdChange} />,
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Status
          </div>
        ),
        width: 140,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'requestStatus',
        filterable: false,
        Cell: (row) => {
          const {
            requestStatus,
            rejectionReason
          } = row.original;
              if (requestStatus === 'document-uploaded' || requestStatus === 'payment-complete') {
                return (
                  <StatusPendingContainer>
                    {requestStatus}
                  </StatusPendingContainer>
                );
              } else if (requestStatus === 'order-approved' || requestStatus === 'completed') {
                return (
                  <StatusCompleteContainer>{requestStatus}</StatusCompleteContainer>
                );
              } else if (requestStatus === 'order-rejected') {
                return (
                  <div>
                    <StatusCancelledContainer>
                      {requestStatus}
                    </StatusCancelledContainer>
                    Reason: {rejectionReason}
                  </div>
                )
              }
              return (
                <div> {requestStatus}</div>
              )
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Sponsor
          </div>
        ),

        width: 126,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'sponsorName',
        Filter: () => (
          <Select
          allowClear
            showSearch
            options={sponsorList}
            onChange={handleSponsor}
            filterOption={filterOption}
            placeholder="All"
            className="sposor-filter"
            styles={{
              height: '42px',
              // Fixes the overlapping problem of the component
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                width: '170px',
              }),
            }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
          />
        ),
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Order Date
          </div>
        ),
        width: 90,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'orderDate',
        Filter: () => (
          <RangePicker
            value={selectedDateRange}
            format="YYYY-MM-DD"
            style={{
              borderRadius: '10px',
            }}
            onChange={handleOrderDateChange}
          />
        ),
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Ack. Call
          </div>
        ),
        width: 200,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: true,
        accessor: 'acknowledgementCall',
        Cell: (row) => {
          const {
            requestId,
            phone,
            acknowledgementCallCount,
            acknowledgementCallDuration,
          } = row.original;
          let status = 'Pending';
          if (
            acknowledgementCallDuration &&
            acknowledgementCallDuration !== '0s'
          ) {
            status = `Yes (${acknowledgementCallDuration}, ${acknowledgementCallCount})`;
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <StatusCompleteContainer>{status}</StatusCompleteContainer>
                <div>
                  {phone && (
                    <VisionCallingButton
                      onSubmit={() => setAckButtonConfirmModal(requestId)}
                    />
                  )}
                </div>
              </div>
            );
          } else if (parseInt(acknowledgementCallCount) !== 0) {
            status = `No Answer (${acknowledgementCallCount})`;
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <StatusCancelledContainer>{status}</StatusCancelledContainer>
                <div>
                  {phone && (
                    <VisionCallingButton
                      onSubmit={() => setAckButtonConfirmModal(requestId)}
                    />
                  )}
                </div>
              </div>
            );
          }
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <StatusPendingContainer>{status}</StatusPendingContainer>
              <div>
                {phone && (
                  <VisionCallingButton
                    onSubmit={() => setAckButtonConfirmModal(requestId)}
                  />
                )}
              </div>
            </div>
          );
        },
        Filter: (props) => {
          return <VisionAckFilter 
            {...props} 
            onChange={changeAckFitler} 
            currentFilter={ackFilter}
          />;
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Comments
          </div>
        ),
        width: 140,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: false,
        accessor: 'commentsCount',
        Cell: ({ value }) => (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >  
              <img
                  style={{
                    padding: '10px',
                    filter: 'none',
                  }}
                  src={Messages}
                  alt="messages"
              />
              {`${value} Comments`}
          </div>
        ),
      },
      ...(toggleActions
        ? [
            {
              Header: 'Actions',
              numeric: false,
              disablePadding: false,
              filterable: false,
              accessor: 'actions',
              width: 195,
              style: {
                position: 'sticky',
                right: 0,
                top: 0,
                background: 'white',
                textAlign: 'center',
                zIndex: 2,
                alignContent: 'center',
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
              },
              headerStyle: {
                overflow: 'visible',
                position: 'sticky',
                right: 0,
                background: 'white',
                textAlign: 'center',
                zIndex: 2,
              },
              Cell: (row) => (
                <div
                  style={{
                    position: 'sticky',
                    right: 0,
                    background: 'white',
                    textAlign: 'center',
                    zIndex: 2,
                  }}
                >
                  <div className="flex flex-row justify-center">
                    <VisionActions
                      requestId={row.original.requestId}
                      getVisionRequestData={getVisionRequestData}
                    />
                  </div>
                </div>
              ),
            },
          ]
        : []),
     
    ],
    [selectedDateRange, segregation, sponsorList, isOpen, openActionDropDown, allChecked, statusFilter, toggleActions, filterRequestId, ackFilter]
  );

  useEffect(() => {
    if (visionRequestId) {
      setNewRequestId(visionRequestId);
    }
  }, [visionRequestId]);

  return (
    <>
      <div
        style={{
          backgroundColor: '#F2F1F6',
          width: '100%',
        }}
      >
        <div>
          <NavBar />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            marginTop: '72px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px 20px',
              gap: '30px',
            }}
          >
            <div>
              <CreateOrder
                submit={handlePlaceRequestSubmit}
                onComplete={onAction}
              />
            </div>
            <div>
              <UploadDocumentsModal
                isOpen={showUploadModal}
                toggle={toggleUploadModal}
                requestId={newRequestId}
                onUploadComplete={handleUploadComplete}
              />
            </div>
            <div>
              <OrderDetailsModal 
                isOpen={showOrderModal}
                toggle={toggleOrderDetailsModal}
                onSubmit={handleOrderDetailsSubmit}
                requestId={newRequestId}
                ModalHeader={'Submit Order Details'}
              />
            </div>
            <div>
              <VisionCompletedModal 
                isOpen={showCompletedModal}
                toggle={handleCompletedModalClose}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flex: '1',
                border: '1px solid #CFC8FF',
                borderRadius: '8px',
                backgroundColor: '#FFFFFF',
                padding: '2px',
              }}
            >
              <img
                style={{
                  padding: '10px',
                }}
                src={SearchIcon}
                alt="searchicon"
              />
              <input
                style={{
                  flex: '1',
                  border: 'none',
                  boxShadow: 'none',
                  fontFamily: 'Inter, sans-serif',
                  padding: '11px',
                }}
                type="text"
                value={search}
                onChange={(e) => handleChange(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSearchClick();
                  }
                }}
                placeholder="Search by Phone Number, Name or Email"
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                backgroundColor: '#FFFFFF',
                borderRadius: '8px',
                border: '1px solid #CFC8FF',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',

                  fontFamily: '"Arial", sans-serif',
                }}
              >
                {(loading || countLoader) ?  <LoadingComponent/> :  <div
                  style={{
                    width: 'max-content',
                    margin: 'auto',
                    padding: '5px 10px 5px 10px',
                  }}
                >
                  <span
                    style={{
                      fontSize: '24px',
                      marginRight: '5px',
                      fontWeight: 'bold',
                      color: '#714FFF',
                    }}
                  >
                    {totalHits}
                  </span>
                  <span
                    style={{
                      color: '#A5A6BB',
                      fontSize: '14px',
                    }}
                  >
                    Total Cases
                  </span>
                </div> }
              </div>
            </div>
            {opsUserStatus && groupId && <button style={{
                background: '#714FFF',
                margin: '0px',
                borderRadius: '5px',
                border: '0px',
                color: 'white',
                padding: '12px 12px',
                fontSize: '14px',
                fontWeight : '600',
                fontFamily: 'Inter, sans-serif',
                justifyContent: 'flex-start',
              }} 
              onClick={()=> setCheckInModalOpen(true)}
              >
                {opsUserStatus && opsUserStatus.opdCheckIn ? 'Check Out' : 'Check In'}
            </button>}
            <div
              style={{
                display: 'flex',
              }}
            >
              <DownloadCsv 
                isCashlessVision 
                callback={() => getCashlessVisionRequests({
                                draw: totalHits,
                                search: search,
                  })} 
              />
            </div>
            <img
              onClick={toggleActionsColumn}
              src={toggleActions ? UnhideActions : ShowActions}
              alt={toggleActions ? 'Hide Actions' : 'Show Actions'}
              id='toggleActions'
            />
            <UncontrolledTooltip target={'toggleActions'}>
              {toggleActions ? 'Hide Actions' : 'Show Actions'}
            </UncontrolledTooltip> 
          </div>
          <div
            className="mt-4"
            style={{
              display: 'flex',
              padding: '0px 20px',
              justifyContent: 'space-between',
              width: '100%',
              gap: '5px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  cursor: 'pointer',
                  border: 'none',
                  backgroundColor: buttonActive === null ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === null ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px',
                  margin: '0px',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
                onClick={() => {
                  setSegregationFilter(null); 
                  setButtonActive(null); 
                  setStatusFilter(null);
                }}
                className="myButton"
              >
                All
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  cursor: 'pointer',
                  border: 'none',
                  backgroundColor: buttonActive === 'document-pending' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'document-pending' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px',
                  margin: '0px',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
                onClick={() => {
                  setSegregationFilter(0); 
                  setButtonActive('document-pending'); 
                  setStatusFilter('document-pending');
                }}
                className="myButton"
              >
                Document Pending
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  cursor: 'pointer',
                  border: 'none',
                  backgroundColor: buttonActive === 'document-uploaded' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'document-uploaded' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px',
                  margin: '0px',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
                onClick={() => {
                  setSegregationFilter(0); 
                  setButtonActive('document-uploaded'); 
                  setStatusFilter('document-uploaded');
                }}
                className="myButton"
              >
                Document Uploaded
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  cursor: 'pointer',
                  border: 'none',
                  backgroundColor: buttonActive === 'payment-pending' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'payment-pending' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px',
                  margin: '0px',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
                onClick={() => {
                  setSegregationFilter(0); 
                  setButtonActive('payment-pending'); 
                  setStatusFilter('payment-pending');
                }}
                className="myButton"
              >
                Payment Pending
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  border: 'none',
                  backgroundColor: buttonActive === 'payment-complete' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'payment-complete' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px 16px',
                  margin: '0px',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
                onClick={() => {
                  setSegregationFilter(1); 
                  setButtonActive('payment-complete'); 
                  setStatusFilter('payment-complete');
                }}
                className="myButton"
              >
                Payment Complete
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                  fontWeight: '600',
                  border: 'none',
                  backgroundColor: buttonActive === 'order-approved' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'order-approved' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px 16px',
                  margin: '0px',
                }}
                onClick={() => {
                  setSegregationFilter(2); 
                  setButtonActive('order-approved'); 
                  setStatusFilter('order-approved');
                }}
                className="myButton"
              >
                Order Approved
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                  fontWeight: '600',
                  border: 'none',
                  backgroundColor: buttonActive === 'order-rejected' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'order-rejected' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px 16px',
                  margin: '0px',
                }}
                onClick={() => {
                  setSegregationFilter(3); 
                  setButtonActive('order-rejected'); 
                  setStatusFilter('order-rejected');
                }}
                className="myButton"
              >
                Order Rejected
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                  fontWeight: '600',
                  border: 'none',
                  backgroundColor: buttonActive === 'completed' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'completed' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px 16px',
                  margin: '0px',
                }}
                onClick={() => {
                  setSegregationFilter(3); 
                  setButtonActive('completed'); 
                  setStatusFilter('completed');
                }}
                className="myButton"
              >
                Completed
              </button>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '8px',
                justifyContent: 'flex-end',
              }}
            >
              {isFilterApplied && (
                <div>
                  <button
                    style={{
                      borderRadius: '10px',
                      whiteSpace: 'nowrap',
                      fontFamily: 'Inter, sans-serif',
                      cursor: 'pointer',
                      border: '1px solid #FC6D3B',
                      backgroundColor: '#FFFFFF',
                      color: '#FC6D3B',
                      margin: '0px',
                      fontSize: '14px',
                      fontWeight: '600',
                      padding: '8px 16px',
                    }}
                    onClick={clearFilter}
                  >
                    Clear Filters
                  </button>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
          </div>
          <ReactTable
            columns={columns}
            manual
            data={dataSource}
            loading={loading}
            showPaginationTop={true}
            style={tableStyle}
            showPaginationBottom={false}
            pages={pages}
            page={page}
            filterable
            pageSize={pageSize}
            selectRow={selectRow}
            className="-striped -highlight revamp-table"
            onFetchData={(state, instance) => {
              filterData(state);
            }}
            pageSizeOptions={[5, 10, 20, 25, 50, 100, 200, 500, 700, 1000, 10000]}
            PaginationComponent={(props) => (
              <VisionPagination
                allChecked={allChecked}
                getCashlessVisionRequests={getCashlessVisionRequests}
                params={params}
                {...props}
                headerComponent={''}
              />
            )}
            SubComponent={(row) => (
              <VisionSubComponent
                requestId={row.original.requestId}
                userId={row.original.userId}
              />
            )}
          />
        </div>

        <div>
          <Modal
            isOpen={showRequestDetails}
            toggle={closeDetailModal}
            size="lg"
            className="rtsp-modal-big"
          >
            <ModalHeader
              toggle={closeDetailModal}
              className="revamp-modal-header"
            >
              {`Request Details (${requestId})`}{' '}
              <Button
                style={{
                  borderRadius: '8px',
                  border: `1px solid #714FFF`,
                  background: '#FFFFFF',
                  height: '25px',
                  width: '122px',
                  color: '#714FFF',
                  padding: '0px',
                  marginLeft: '800px',
                  fontFamily: 'Inter, sans-serif',
                  fontWeight: '600',
                }}
                onClick={copyDetails}
              >
                Copy Details
              </Button>
            </ModalHeader>
            <ModalBody>
              <RequestDetails
                onAction={onAction}
                sendDataToParent={handleDataFormChild}
                requestData={currentRequestDetails}
                getVisionRequestData={getVisionRequestData}
                closeDetailModal={closeDetailModal}
              />
            </ModalBody>
          </Modal>
        </div>
        <div>
          <ConfirmModal
            modalHeader={confirmModal.header}
            onClose={confirmModal.onClose}
            isOpen={confirmModal.isOpen}
            onSubmit={submitConfirmModalGen}
            loading={confirmModal.buttonLoading}
            isCallModalOpen={isCallModalOpen}
            callReasonOptions={callReasonOptions}
            handleChange={handleChangeForCallReason}
            selectedValue={selectedCallReason}
          >
            {confirmModal.body}
          </ConfirmModal>
        </div>
      </div>
    </>
  );
};

export default CashlessVision;
