//
import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';

export default class CallUserModal extends React.Component {
  submitModal() {
    const { onSubmit } = this.props;
    this.setState({ modal: false }, onSubmit);
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { modalHeader, children, onSubmit, isOpen, onClose, loading } =
      this.props;
    return (
      <Modal isOpen={isOpen} toggle={onClose} centered>
        <ModalHeader className="revamp-modal-header" toggle={onClose}>
          {modalHeader}
        </ModalHeader>
          <ModalBody>{children}</ModalBody>
        <ModalFooter
          style={{
            justifyContent: 'space-around',
          }}
        >
          <Button
            style={{
              backgroundColor: 'rgb(113, 79, 255)',
              width: '40%',
              borderColor: 'rgb(113, 79, 255)',
              height: '44px',
            }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Confirm'}
          </Button>
          {!loading && (
            <Button
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                width: '40%',
                borderColor: 'rgb(113, 79, 255)',
                color: 'rgb(113, 79, 255)',
                height: '44px',
              }}
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}
