import React, { useState } from "react";
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { FaTrashAlt } from "react-icons/fa";
import visionImage from '../../images/vision.svg';
import { uploadVisionDocuments } from "../../services/api/cashless-vision";

const UploadDocumentsModal = ({ isOpen, toggle, requestId, onUploadComplete }) => {
  const [visionCardFiles, setVisionCardFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const handleProceed = async () => {
    if (!requestId) {
      toast.error('Invalid request ID');
      return;
    }

    try {
      setIsLoading(true);

      const formData = new FormData();
      
      visionCardFiles.forEach(file => {
        formData.append('visionCard', file);
      });

      const response = await uploadVisionDocuments(requestId, formData);
      
      if (response.message === 'success') {
        onUploadComplete(visionCardFiles);
      } else {
        toast.error('Failed to upload documents');
      }
    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error.response?.data?.message || 'Failed to upload documents');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSkip = () => {
    onUploadComplete([]);
    toggle();
  };

  const handleClose = () => {
    setVisionCardFiles([]);
    setIsLoading(false);
    toggle();
  };

  const validateFileType = (file) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!allowedTypes.includes(file.type)) {
      toast.error(`Invalid file type: ${file.name}. Only JPEG, PNG, and PDF files are allowed.`);
      return false;
    }

    if (file.size > maxSize) {
      toast.error(`File too large: ${file.name}. Maximum size is 5MB.`);
      return false;
    }

    return true;
  };

  const handleFileChange = (e, setFiles) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter(validateFileType);
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleFileDelete = (fileToDelete, setFiles, files) => {
    setFiles(files.filter((file) => file !== fileToDelete));
  };

  const renderFilePreview = (file) => {
    const fileType = file.type.split("/")[0];
    if (fileType === "image") {
      return URL.createObjectURL(file); 
    }
    return null;
  };

  // Update the scrollContainerStyle object
  const scrollContainerStyle = {
    display: "flex",
    overflowX: "auto",
    gap: "10px",
    padding: "10px",
    scrollBehavior: "smooth",
    WebkitOverflowScrolling: "touch", // For smooth scrolling on iOS
    msOverflowStyle: "-ms-autohiding-scrollbar", // Hide scrollbar in IE/Edge
  };

  // Add a separate style tag in your component for the scrollbar styling
  const ScrollbarStyles = () => (
    <style>
      {`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      `}
    </style>
  );

  // Add scroll buttons functionality
  const scroll = (direction, containerId) => {
    const container = document.getElementById(containerId);
    const scrollAmount = 200; // Adjust scroll amount as needed
    if (container) {
      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} centered size="md">
      <ModalHeader
        style={{
          font: "Inter",
          fontSize: "16px",
          fontWeight: "600",
          color: "#714FFF",
          borderBottom: "none",
          padding: "15px 20px",
        }}
        toggle={handleClose}
      >
        <span style={{ 
          color: "#714FFF", 
          font: "Inter",
          fontSize: "16px",
          fontWeight: "600",
        }}>
          Upload Documents
        </span>
      </ModalHeader>
      <ModalBody
        style={{
          padding: "20px",
          backgroundColor: "#714FFF19",
          borderRadius: "8px",
          border: "1px dashed #D6CFFF",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          margin: "0 20px 20px 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <div style={{ flex: 1, maxWidth: "100%" }}>
            <div style={{ 
              height: "auto",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              border: "1px solid #D6CFFF",
              position: "relative",
            }}>
              {visionCardFiles.length === 0 ? (
                <>
                  <div
                    style={{
                      fontSize: "50px",
                      color: "#A89EFF",
                      marginBottom: "10px",
                      textAlign: "center",
                      padding: "20px",
                    }}
                  >
                    <img src={visionImage} alt="Vision Card" style={{ width: "50px", height: "50px" }}/>
                  </div>
                  <p
                    style={{
                      font: 'Inter',
                      textAlign: "center",
                      color: "#808080",
                      fontSize: "14px",
                      fontWeight: "700",
                      marginBottom: "20px",
                    }}
                  >
                    Please upload Vision Card
                    <br />
                    <span style={{ font: 'Inter', fontSize: "12px", color: "#808080", fontWeight: "400" }}>
                      Supported formats: JPEG, PNG, IMG, PDF
                    </span>
                  </p>
                </>
              ) : (
                <div style={{ position: "relative" }}>
                  {/* Left scroll button */}
                  <button
                    onClick={() => scroll('left', 'visionCardScroll')}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 2,
                      background: "#FFFFFF",
                      border: "1px solid #D6CFFF",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                  >
                    ←
                  </button>
                  
                  {/* Scroll container */}
                  <ScrollbarStyles />
                  <div
                    id="visionCardScroll"
                    className="hide-scrollbar"
                    style={{
                      ...scrollContainerStyle,
                      margin: "0 35px",
                      justifyContent: "center",
                      width: `${Math.min(visionCardFiles.length * 110, 300)}px`, 
                    }}
                  >
                    {visionCardFiles.map((file, index) => (
                      <div
                        key={index}
                        style={{
                          flex: "0 0 auto",
                          width: "100px",
                          height: "100px",
                          border: "1px solid #D6CFFF",
                          borderRadius: "8px",
                          overflow: "hidden",
                          backgroundColor: "#FFFFFF",
                          position: "relative",
                        }}
                      >
                        {renderFilePreview(file) ? (
                          <img
                            src={renderFilePreview(file)}
                            alt={file.name}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              padding: "10px",
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "20px",
                              backgroundColor: "#f8f9fa",
                            }}
                          >
                            <span style={{ color: "#714FFF", fontSize: "14px" }}>
                              {file.name}
                            </span>
                          </div>
                        )}
                        <div
                          onClick={() => handleFileDelete(file, setVisionCardFiles, visionCardFiles)}
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            background: "#FF4D4D",
                            borderRadius: "50%",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                          }}
                        >
                          <FaTrashAlt style={{ color: "#FFFFFF", fontSize: "12px" }} />
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Right scroll button */}
                  <button
                    onClick={() => scroll('right', 'visionCardScroll')}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 2,
                      background: "#FFFFFF",
                      border: "1px solid #D6CFFF",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                  >
                    →
                  </button>
                </div>
              )}
            </div>
            <label
              htmlFor="vision-card-upload"
              style={{
                cursor: "pointer",
                backgroundColor: "#FFFFFF",
                border: "1px solid #714FFF",
                color: "#714FFF",
                padding: "10px 20px",
                borderRadius: "8px",
                marginTop: "10px",
                fontWeight: "600",
                display: "block",
                textAlign: "center",
              }}
            >
              {visionCardFiles.length === 0 ? "Browse Files" : "Upload Another File"}
            </label>
            <input
              id="vision-card-upload"
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e, setVisionCardFiles)}
            />
          </div>
        </div>
      </ModalBody>
      <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", width: "100%" }}>
        <Button
          onClick={handleSkip}
          style={{
            backgroundColor: "#FFFFFF",
            color: "#714FFF",
            fontWeight: "600",
            padding: "10px 20px",
            borderRadius: "8px",
            width: "45%",
            border: "1px solid #714FFF",
          }}
        >
          Skip
        </Button>
        <Button
          onClick={handleProceed}
          disabled={isLoading || visionCardFiles.length === 0}
          style={{
            backgroundColor: "#714FFF",
            color: "#FFFFFF",
            fontWeight: "600",
            padding: "10px 20px",
            borderRadius: "8px",
            width: "45%",
          }}
        >
          {isLoading ? "Processing..." : "Proceed"}
        </Button>
      </div>
    </Modal>
  );
};

export default UploadDocumentsModal;
