import React from 'react';
import { Modal } from 'reactstrap';
import Completed from '../../images/Completed.svg';

const VisionCompletedModal = ({ isOpen, toggle }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      style={{
        maxWidth: '400px',
        borderRadius: '12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '32px',
          textAlign: 'center'
        }}
      >
        <img
          src={Completed}
          alt="Success"
          style={{
            width: '120px',
            height: '120px',
            marginBottom: '24px'
          }}
        />
        <div
          style={{
            fontSize: '24px',
            fontWeight: '600',
            color: '#0F0B28',
            marginBottom: '32px',
            fontFamily: 'Inter, sans-serif'
          }}
        >
          Order Successfully Created
        </div>
        <button
          onClick={toggle}
          style={{
            backgroundColor: '#714FFF',
            color: '#FFFFFF',
            border: 'none',
            borderRadius: '8px',
            padding: '12px 48px',
            fontSize: '16px',
            fontWeight: '600',
            fontFamily: 'Inter, sans-serif',
            cursor: 'pointer',
            width: '100%'
          }}
        >
          Okay!
        </button>
      </div>
    </Modal>
  );
};

export default VisionCompletedModal;
