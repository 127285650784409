import React from 'react';
import InactivePrevButton from '../../images/InactivePrevButton.svg';
import ActivePrevButton from '../../images/ActivePrevButton.svg';
import InactiveNextButton from '../../images/InactiveNextButton.svg';
import ActiveNextButton from '../../images/ActiveNextButton.svg';
import 'react-table/react-table.css';

const defaultButton = (props) => <span {...props}>{props.children}</span>;

export default class VisionPagination extends React.Component {
  constructor(props) {
    super();
    this.changePage = this.changePage.bind(this);
    this.state = {
      visiblePages: this.getVisiblePages(props.page, props.pages),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages),
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else if (Number.isNaN(total)) {
        return [];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages),
    });

    this.props.onPageChange(page - 1);
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;

    return (
      <>
        {this.props.headerComponent}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '2px solid #EBECF0',
            // alignItems: 'flex-end',
            padding: '10px 10px 5px 10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '10px',
              color: '#000000',
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            Select Rows:
            <select
              style={{
                backgroundColor: '#F2F1F6',
                margin: '5px',
                paddingLeft: '7px',
                paddingRight: '5px',
                background: '#fff',
                fontSize: 'inherit',
                border: '1px solid #cfd8e6',
                boxShadow: '0 2px 0 rgba(0, 0, 0, .05)',
                borderRadius: '4px',
                fontWeight: '400',
              }}
              value={this.props.pageSize}
              onChange={(e) => this.props.selectRow(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={500}>500</option>
              <option value={700}>700</option>
              <option value={1000}>1000</option>
            </select>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div className="Table__prevPageWrapper mr-20px">
              <img
                style={{
                  cursor: 'pointer',
                }}
                src={activePage === 1 ? InactivePrevButton : ActivePrevButton}
                alt="previous"
                className="cursor-pointer"
                onClick={() => {
                  if (activePage === 1) return;
                  this.changePage(activePage - 1);
                }}
              />
              {/* </PageButtonComponent> */}
            </div>
            <div className="Table__visiblePagesWrapper">
              {visiblePages.map((page, index, array) => {
                return (
                  <PageButtonComponent
                    key={page}
                    style={{
                      borderRadius: '3px',
                      fontWeight: '400',
                    }}
                    className={
                      activePage === page
                        ? 'Table__pageButton Table__pageButton--active'
                        : 'Table__pageButton'
                    }
                    onClick={this.changePage.bind(null, page)}
                  >
                    {array[index - 1] + 2 < page ? `...${page}` : page || ''}
                  </PageButtonComponent>
                );
              })}
            </div>
            <div className="Table__nextPageWrapper">
              <img
                style={{
                  cursor: 'pointer',
                }}
                src={
                  activePage === this.props.pages
                    ? InactiveNextButton
                    : ActiveNextButton
                }
                className="cursor-pointer"
                alt="next"
                onClick={() => {
                  if (activePage === this.props.pages) return;
                  this.changePage(activePage + 1);
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
