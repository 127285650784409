import React, { Component } from 'react';
import Select from 'react-select';
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { fetchUserDetailsByPhone } from '../../services/api/cashless-vision';
import { fetchUsersRelativeByphone } from '../../services/api/offline-consult';
import verifiedIcon from '../../images/verifiedIcon.svg';

class CreateOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      userId: '',
      phone: '',
      relativeResult: [],
      relativeLoading: false,
      showFormWarning: false,
      alertMessage: 'None',
      loading: false,
      name: '',
      email: '',
      voucherCode: '',
      walletBalance: 0,
      verified: false,
      selectedRelative: null,
    };
  }

  resetState = () => {
    this.setState({
      userId: '',
      phone: '',
      relativeResult: [],
      relativeLoading: false,
      showFormWarning: false,
      alertMessage: 'None',
      loading: false,
      name: '',
      email: '',
      voucherCode: '',
      walletBalance: 0,
      verified: false,
      selectedRelative: null,
    });
  };

  isValidPhoneNumber = (phone) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(phone);
  };

  handleInputChange = (field, value) => {
    if (field === 'phone' && value !== this.state.phone) {
      // Reset fields if phone number changes
      this.resetState();
    }
    this.setState({ [field]: value });
  };

  handleVerify = async () => {
    const { phone } = this.state;

    if (!this.isValidPhoneNumber(phone)) {
      this.setState({ 
        showFormWarning: true, 
        alertMessage: 'Please enter a valid Indian phone number'
      });
      return;
    }

    this.setState({
      relativeLoading: true
    });

    try {
      const userDetailsResponse = await fetchUserDetailsByPhone(phone);
      if (userDetailsResponse.message === 'success') {
        const { userId, userName, userEmail, voucherCode, walletBalance } = userDetailsResponse.data;
        this.setState({
          userId,
          name: userName,
          email: userEmail,
          voucherCode,
          walletBalance: walletBalance || 0,
          verified: true
        });
        toast.success('User details fetched successfully!');

        const relativesResponse = await fetchUsersRelativeByphone(phone);
        const relatives = relativesResponse.data.map((relative) => ({
          value: relative.relativeId,
          label: relative.relativeName,
        }));
        this.setState({ relativeResult: relatives });
      } else {
        toast.error(userDetailsResponse.errorMessage);
        this.resetState();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || 'Failed to fetch user details');
      this.resetState();
    } finally {
      this.setState({
        relativeLoading: false
      });
    }
  };

  handleRelativeChange = async (selectedOption) => {
    this.setState({ selectedRelative: selectedOption });

    try {
      const response = await fetchUserDetailsByPhone(this.state.phone, selectedOption?.value || null);
      const { walletBalance } = response.data;
      this.setState({
        walletBalance: walletBalance || 0
      });
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to fetch wallet details');
    }
  };

  placeRequest = async (status, rejectionReason = null) => {
    const { userId, selectedRelative, voucherCode, name, email, phone, walletBalance } = this.state;
    const relativeId = selectedRelative ? selectedRelative.value : null;

    this.setState({ loading: true });

    try {
      const result = await this.props.submit(userId, relativeId, voucherCode, status, rejectionReason);
      if (result) {
        this.resetState();
        this.setState({ modalVisible: false });
      }
    } catch {
      toast.error('Failed to create order');
    } finally {
      this.setState({ loading: false });
    }
  };

  toggleModal = () => {
    this.setState(prevState => {
      if (prevState.modalVisible) {
        this.resetState(); // Reset state when closing the modal
      }
      return { modalVisible: !prevState.modalVisible };
    });
  };

  render() {
    const {
      modalVisible,
      phone,
      name,
      email,
      voucherCode,
      walletBalance,
      relativeResult,
      loading,
      relativeLoading,
      showFormWarning,
      alertMessage,
      verified,
      selectedRelative
    } = this.state;

    return (
      <div>
        <button
          type="button"
          style={{
            background: '#714FFF',
            borderColor: '#714FFF',
            width: '106%',
            margin: '0px',
            borderRadius: '5px',
            border: '0px',
            color: 'white',
            padding: '12px 12px',
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Inter, sans-serif',
            justifyContent: 'flex-start',
          }}
          onClick={this.toggleModal}
        >
          Create Order
        </button>
        <Modal centered isOpen={modalVisible} toggle={this.toggleModal}>
          <ModalHeader 
            toggle={this.toggleModal}
            style={{
              borderBottom: '1px solid #E0E0E0'
            }}
          >
            <span style={{ 
              color: '#714FFF',
              fontSize: '16px',
              fontWeight: '600',
              fontFamily: 'Inter, sans-serif'
            }}>
              Create Order
            </span>
          </ModalHeader>
          <ModalBody>
            <form>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label style={{ fontFamily: 'Inter, sans-serif' }}>Number:</Label>
                  </Col>
                  <Col>
                    <div style={{ position: 'relative' }}>
                      <input
                        className="form-control"
                        value={phone}
                        onChange={(e) => this.handleInputChange('phone', e.target.value)}
                        style={{ 
                          borderColor: verified ? '#ced4da' : 'red',
                        }}
                      />
                      {verified ? (
                        <div
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            backgroundColor: '#86CA4B',
                            color: '#FFFFFF',
                            borderRadius: '12px',
                            fontSize: '12px',
                            fontWeight: '600',
                            padding: '2px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            zIndex: 1
                          }}
                        >
                          <img src={verifiedIcon} alt="Verified" style={{ width: '16px', height: '16px' }} />
                          Verified
                        </div>
                      ) : (
                        <Button
                          onClick={this.handleVerify}
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            backgroundColor: '#714FFF',
                            color: '#FFFFFF',
                            borderRadius: '12px',
                            fontSize: '12px',
                            fontWeight: '600',
                            padding: '2px 8px',
                            border: 'none',
                            margin: 0
                          }}
                        >
                          Verify
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label style={{ fontFamily: 'Inter, sans-serif' }}>Name:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      value={name}
                      readOnly
                      style={{ backgroundColor: 'transparent', borderColor: '#ced4da' }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label style={{ fontFamily: 'Inter, sans-serif' }}>Email ID:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      value={email}
                      readOnly
                      style={{ backgroundColor: 'transparent', borderColor: '#ced4da' }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label style={{ fontFamily: 'Inter, sans-serif' }}>Voucher Code:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      value={voucherCode}
                      readOnly
                      style={{ backgroundColor: 'transparent', borderColor: '#ced4da' }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label style={{ fontFamily: 'Inter, sans-serif' }}>Dependents:</Label>
                  </Col>
                  <Col>
                    <Select
                      isSearchable={false}
                      isClearable={true}
                      value={selectedRelative}
                      onChange={this.handleRelativeChange}
                      options={relativeResult}
                      isLoading={relativeLoading}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label style={{ fontFamily: 'Inter, sans-serif' }}>Wallet Balance:</Label>
                  </Col>
                  <Col>
                    <div
                      style={{
                        borderRadius: '20px',
                        padding: '8px 12px',
                        backgroundColor: 'rgba(113, 79, 255, 0.05)',
                        color: '#714FFF',
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      {`₹${walletBalance}`}
                    </div>
                  </Col>
                </Row>
              </FormGroup>
            </form>
            <Alert color="warning" isOpen={showFormWarning} toggle={() => this.setState({ showFormWarning: false })}>
              {alertMessage}
            </Alert>
          </ModalBody>
          <ModalFooter
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
              padding: '20px',
            }}
          >
            <div
              style={{
                backgroundColor: 'rgb(232, 248, 239)',
                color: '#585969',
                padding: '12px 16px',
                borderRadius: '8px',
                textAlign: 'center',
                font: 'Inter',
                fontSize: '14px',
                width: '100%',
                fontWeight: '500',
              }}
            >
              Tap on the button below to deduct the amount from the user's wallet and trigger a WhatsApp message to
              complete the payment.
            </div>

            <Button
              onClick={() => this.placeRequest('received')}
              disabled={!verified || loading}
              style={{
                backgroundColor: '#714FFF',
                border: 'none',
                width: '100%',
                padding: '12px',
                fontWeight: '600',
                borderRadius: '8px',
                margin: '0',
              }}
            >
              {loading ? 'Processing...' : 'Proceed'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default CreateOrder;